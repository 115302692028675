import { useTheme } from "definitions/styled-components";
import React from "react";
import { Radar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

export const RadarChart = ({ labels = [], dataValues = [] }) => {
  const { t } = useTranslation(["common"]);
  const { theme } = useTheme();

  const data = {
    labels: labels,
    datasets: [
      {
        lineTension: -0.15,
        label: t`common:kpi.number`,
        data: dataValues,
        backgroundColor: `${theme.colors.turquoise}8C`,
        borderWidth: 1,
        pointRadius: 5,
        pointBackgroundColor: theme.colors.turquoise,
        pointBorderColor: theme.colors.white,
        pointHoverBackgroundColor: theme.colors.white,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      r: {
        grid: {
          circular: true,
        },
        suggestedMin: 0,
        pointLabels: {
          color: theme.colors.darkBlue,
        },
      },
    },
  };

  return <Radar data={data} options={options} />;
};

export default RadarChart;

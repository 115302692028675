import * as React from "react";

function SvgFullScreenIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Group_1778"
      data-name="Group 1778"
      xmlns="http://www.w3.org/2000/svg"
      width="26.018"
      height="26.018"
      viewBox="0 0 26.018 26.018"
    >
      <path
        id="Path_761"
        data-name="Path 761"
        d="M16,28.244H28.244V16H16ZM17.53,17.53h9.183v9.183H17.53Z"
        transform="translate(-9.113 -9.113)"
        fill="#6d6d6d"
      />
      <path
        id="Path_762"
        data-name="Path 762"
        d="M8.53,8.53h7.652V7H7v9.183H8.53Z"
        transform="translate(-7 -7)"
        fill="#6d6d6d"
      />
      <path
        id="Path_763"
        data-name="Path 763"
        d="M36.652,36.652H29v1.53h9.183V29h-1.53Z"
        transform="translate(-12.165 -12.165)"
        fill="#6d6d6d"
      />
      <path
        id="Path_764"
        data-name="Path 764"
        d="M8.53,29H7v9.183h9.183v-1.53H8.53Z"
        transform="translate(-7 -12.165)"
        fill="#6d6d6d"
      />
      <path
        id="Path_765"
        data-name="Path 765"
        d="M29,7V8.53h7.652v7.652h1.53V7Z"
        transform="translate(-12.165 -7)"
        fill="#6d6d6d"
      />
    </svg>
  );
}

export default SvgFullScreenIcon;

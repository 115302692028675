import { createGlobalStyle } from "styled-components";

const ParametersStyle = createGlobalStyle`
.parameter-main-container {
  .main-content {
    background: none;
  }

  .parameter-card {
    box-shadow: var(--box-shadow);
  }

  .parameter-secret-key-container {
    padding: 0.5rem;
    border: ${({ theme }) => `1px solid ${theme.colors.shades.grey[45]}`};
  }

  .image-uploader-container {
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;

      .ant-avatar {
        border-radius: 0.5rem;

        img {
          object-fit: contain;
        }
      }

      .upload-image-buttons-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }
    }
  }

  .parameter-color-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;

    .tag-content {
      .ant-form-item-control-input-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 1rem;
      }
    }
  }

  .parameter-visual-footer {
    display: flex;
    justify-content: flex-end;
  }

  .ant-form-item-label > label {
    font-family: var(--font-glacial);
    font-size: 0.875rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkBlue};
  }
}

.display-vertical {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
`;

export default ParametersStyle;

import React, { useContext, useEffect, useState } from "react";
import { TextButton } from "../../components/";
import { Card, Checkbox, message, Row, Space } from "antd";
import { useHistory } from "react-router-dom";
import { adminLogin } from "services/user";
import { validateEmail } from "../../components/utils/validators";
import { Content } from "antd/lib/layout/layout";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useTheme } from "styled-components";
import SignInStyle from "../../styles/signInStyles";
import { GlobalContext } from "../../contexts/global";
import { FullNeoIcon } from "../../components/icons";

const SignIn = () => {
  const history = useHistory();
  const { t } = useTranslation(["common", "signin"]);
  const { colors } = useTheme();

  // const [authStateObj, setAuthState] = useRecoilState(authState);
  const [, setAuthState] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signInSuccess, setSignInSuccess] = useState(false);
  const [stayConnected, setStayConnected] = useState(false);
  const { setAvatar, setCurrentUser } = useContext(GlobalContext);

  const login = async () => {
    try {
      const user = await adminLogin(email, password);
      console.log("users :>> ", user);
      setSignInSuccess(true);
      setAuthState(user);
      setAvatar(user.loggedUser?.imageUrls);
      setCurrentUser(user?.loggedUser);
      history.push("/tableau-de-bord");
    } catch (error) {
      console.log("error :>> ", error);
      const ERROR_MSG_TIMEOUT_IN_SECONDS = 5;
      setSignInSuccess(false);
      message.error(
        t`signin:form.errors.signinError`,
        ERROR_MSG_TIMEOUT_IN_SECONDS
      );
    }
  };

  const handleStayConnectedClicked = () => {
    setStayConnected(!stayConnected);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (email) {
      if (!validateEmail(email)) {
        setEmailError(t`signin:form.errors.emailInvalid`);
      } else {
        setEmailError("");
      }
    }
  }, [email, t]);

  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  return (
    <>
      <SignInStyle />
      <Content className="content-container">
        <Card
          bodyStyle={{
            padding: "2.125rem 1rem",
          }}
          className="signin-content-container"
        >
          <Row justify="center" className="neosilver-logo-container">
            <FullNeoIcon />
          </Row>
          <Row justify="center" className="connect">
            <p className="sub-heading">{t`signin:form.connect`}</p>
          </Row>
          <Space
            direction="vertical"
            className="signin-fields-container"
            size={16}
          >
            <Row>
              <TextField
                label={t`signin:form.email`}
                type="email"
                placeholder={t`signin:form.enterEmail`}
                value={email}
                onChange={handleChangeEmail}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={!!emailError}
                helperText={!!emailError && emailError}
                fullWidth
              />
            </Row>
            <Row className="password-container">
              <TextField
                label={t`signin:form.password`}
                type={showPassword ? "text" : "password"}
                placeholder={t`signin:form.enterPassword`}
                value={password}
                onChange={handleChangePassword}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  "aria-label": "password",
                  fontSize: "1.9375rem",
                  fontFamily: "Glacial Indifference",
                  color: colors.shades.grey[20],
                }}
                fullWidth
              />
              <FormControl fullWidth>
                {!signInSuccess && (
                  <FormHelperText className="signin-error">
                    {t`signin:form.errors.loginError`}
                  </FormHelperText>
                )}
              </FormControl>
            </Row>
            <Row justify="space-between">
              <a
                className="form-footer-text forgot-pwd"
                href="/mot-de-passe-oublie"
              >{t`signin:form.forgotPassword`}</a>
              <Checkbox
                // width="1.47em"
                // height="1.47em"
                // border="0.12em solid #626262"
                // color="#626262"
                // fontSize="1.06rem"
                // fontWeight="100"
                className="form-footer-text"
                checked={stayConnected}
                onChange={handleStayConnectedClicked}
              >
                {t`signin:form.stayConnected`}
              </Checkbox>
            </Row>
            <Row justify="center" className="signin-btn-container">
              <TextButton
                rounded={true}
                variant="turquoise"
                onClick={login}
                disabled={!!emailError || !email || !password}
              >
                {t`signin:form.signMeIn`}
              </TextButton>
            </Row>
          </Space>
        </Card>
      </Content>
    </>
  );
};

// Login.propTypes = {
//   setToken: PropTypes.func.isRequired,
// };

export default SignIn;

import * as React from "react";

function SvgSignOutIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.308"
      height="14.036"
      viewBox="0 0 16.308 14.036"
    >
      <path
        id="Path_476"
        data-name="Path 476"
        d="M8,5.566V1.487A.673.673,0,0,1,8.2,1,.674.674,0,0,1,8.691.791h6.5A.673.673,0,0,1,15.681,1a.673.673,0,0,1,.208.488V12.349a.7.7,0,0,1-.7.7h-6.5a.691.691,0,0,1-.488-.2H8.2a.715.715,0,0,1-.2-.494V8.276A.354.354,0,0,0,7.6,7.9a.362.362,0,0,0-.4.378v4.073A1.48,1.48,0,0,0,7.642,13.4h0a1.48,1.48,0,0,0,1.049.438h6.5a1.494,1.494,0,0,0,1.487-1.487V1.487A1.48,1.48,0,0,0,16.243.438h0A1.48,1.48,0,0,0,15.193,0h-6.5A1.494,1.494,0,0,0,7.2,1.487V5.566a.372.372,0,0,0,.4.379A.363.363,0,0,0,8,5.566ZM4.062,3.316.741,6.638s-.168.135-.168.275S.741,7.2.741,7.2l3.321,3.327a.343.343,0,0,0,.561,0,.353.353,0,0,0,0-.561L1.975,7.316h9.987s.285-.011.291-.393-.291-.4-.291-.4H1.975L4.623,3.871a.373.373,0,0,0,0-.555A.37.37,0,0,0,4.062,3.316Z"
        transform="translate(-0.472 0.1)"
        fill="#999"
        stroke="#999"
        strokeWidth="0.2"
      />
    </svg>
  );
}

export default SvgSignOutIcon;

import * as React from "react";

function SvgNeoSilverIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.197"
      height="25.066"
      viewBox="0 0 25.197 25.066"
    >
      <g
        id="Group_1834"
        data-name="Group 1834"
        transform="translate(-491 -301.439)"
      >
        <path
          id="Path_528"
          data-name="Path 528"
          d="M493.437,309.961c-.022-2.238-.088-4.476-.049-6.712a1.686,1.686,0,0,1,1.869-1.784c2.2-.045,4.4-.016,6.606-.017,1.21,0,1.714.742,2.082,1.795,1.92,5.49,5.366,9.61,10.958,11.595,3.234,1.147,3.053.845,3.036,4.39-.009,1.852-.035,3.7-.053,5.557a4.86,4.86,0,0,1-3.354.709c-1.11-.036-2.224-.047-3.333,0a3.779,3.779,0,0,1-3.247-.934l-.513-1.345a2.08,2.08,0,0,0-.21-1.229c-2.423-5.4-6.782-8.614-12.155-10.613A2.3,2.3,0,0,1,493.437,309.961Z"
          transform="translate(-2.047 0)"
          fill="#013466"
        />
        <path
          id="Path_529"
          data-name="Path 529"
          d="M491.232,410.862a17.12,17.12,0,0,1-.088-5.049.982.982,0,0,1,1.442-.875,14.1,14.1,0,0,1,8.389,7.842,2.113,2.113,0,0,1,.2,1.354l-.168.229.009-.008a15.392,15.392,0,0,1-5.957.022A5.434,5.434,0,0,1,491.232,410.862Z"
          transform="translate(0 -89.098)"
          fill="#f6a58e"
        />
        <path
          id="Path_530"
          data-name="Path 530"
          d="M615.683,308.332c0,.7.018,1.392,0,2.086a1.086,1.086,0,0,1-1.467,1.126c-3.44-.7-7.35-5.013-7.729-8.483-.1-.92.2-1.419,1.173-1.413.928.006,1.857-.016,2.784,0a4.984,4.984,0,0,1,5.193,5.288c0,.464,0,.928,0,1.392Z"
          transform="translate(-99.493 -0.176)"
          fill="#feb83f"
        />
        <path
          id="Path_531"
          data-name="Path 531"
          d="M493.573,363.053a2.3,2.3,0,0,0,1.64,1.414c5.373,2,9.732,5.208,12.155,10.613a2.08,2.08,0,0,1,.21,1.229,20.754,20.754,0,0,0-12.657-11.021A1.83,1.83,0,0,1,493.573,363.053Z"
          transform="translate(-2.183 -53.092)"
          fill="#09223c"
        />
        <path
          id="Path_532"
          data-name="Path 532"
          d="M492.674,448.385a5.435,5.435,0,0,0,3.831,3.515,15.391,15.391,0,0,0,5.957-.022c.016.515.031,1.144-.648,1.132-2.1-.037-4.263.508-6.262-.49A4.677,4.677,0,0,1,492.674,448.385Z"
          transform="translate(-1.442 -126.621)"
          fill="#dd9282"
        />
        <path
          id="Path_533"
          data-name="Path 533"
          d="M608.647,320.719c1.162,2.324,1.618,3.269,3.227,4.384a15.392,15.392,0,0,0,5.473,2.352c-.19.479-.427,1.061-1.045.78-1.908-.869-4.112-1.232-5.548-2.944A6.935,6.935,0,0,1,608.647,320.719Z"
          transform="translate(-101.374 -16.613)"
          fill="#cca060"
        />
        <path
          id="Path_534"
          data-name="Path 534"
          d="M598.77,468.606a3.779,3.779,0,0,0,3.247.933c1.109-.049,2.223-.039,3.333,0a4.859,4.859,0,0,0,3.354-.709,1.626,1.626,0,0,1-1.8,1.652q-3.112.057-6.225,0C599.489,470.462,598.926,469.718,598.77,468.606Z"
          transform="translate(-92.863 -144.045)"
          fill="#09223c"
        />
        <path
          id="Path_535"
          data-name="Path 535"
          d="M563.375,472.268l.168-.229C563.549,472.161,563.553,472.282,563.375,472.268Z"
          transform="translate(-62.364 -147.003)"
          fill="#dd9282"
        />
      </g>
    </svg>
  );
}

export default SvgNeoSilverIcon;

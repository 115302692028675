import { get, post, postImages } from "./axios-service";

// const apiUrl = process.env.REACT_APP_API_URL;

export async function userEmailList() {
  const result = await get(`partner-members/email-list`);
  return result;
}

export async function createTokens(payload) {
  const result = await post("partner-gift-tokens", payload);
  return result;
}

export async function retrieveExcelFiles() {
  const result = await get(`partners/documents/databases`);
  return result;
}

export async function readUploadExcelFile(payload) {
  const result = await post("partner-gift-tokens/file", payload);
  return result;
}

export const uploadPartnerDocument = (queryString, payload) => {
  const qs = JSON.stringify(queryString);
  return postImages(`partner/upload-bdd-doc?filter=${qs}`, payload);
};

export async function partnerTokenUseHistory() {
  const result = await get("partner-gift-tokens/history");
  return result;
}

export async function exportPartnerTokenUseHistory() {
  const result = await get("partner-gift-tokens/history-export");
  return result;
}

export async function kpiTokenUse() {
  const result = await get("partners/kpi/token-use");
  return result;
}

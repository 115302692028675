import { createGlobalStyle } from "styled-components";

const AdminStyle = createGlobalStyle`
.administrators-main-container {
  .administrators-card {
    box-shadow: var(--box-shadow);

    h1 {
      font-family: var(--font-glacial);
      font-size: 1.25rem;
      color: ${({ theme }) => theme.colors.shades.grey[18]};
      font-weight: 600;
    }

    .administrators-actions-container {
      margin: 0 0 0.5rem 0;
      gap: 1rem;

      .search-field-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        p {
          font-size: 0.75rem;
          font-family: var(--font-glacial);
          font-weight: 400;
          color: ${({ theme }) => theme.colors.shades.grey[25]};
        }
      }

      button {
        border-radius: 0.5rem;
        border-color: ${({ theme }) => theme.colors.shades.grey[25]};

        span {
          font-family: var(--font-glacial);
          color: ${({ theme }) => theme.colors.shades.grey[25]};
        }
      }
    }
  }
}
`;

export default AdminStyle;

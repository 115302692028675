import * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SvgDoughnutSmallIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.171"
      height="21.188"
      viewBox="0 0 21.171 21.188"
    >
      <g
        id="Group_2011"
        data-name="Group 2011"
        transform="translate(-974 -144.983)"
      >
        <path
          id="Subtraction_47"
          data-name="Subtraction 47"
          d="M10.586,21.17A10.585,10.585,0,0,1,3.1,3.1a10.585,10.585,0,0,1,14.97,14.97A10.516,10.516,0,0,1,10.586,21.17Zm0-18.524a7.939,7.939,0,1,0,7.939,7.939A7.948,7.948,0,0,0,10.586,2.646Z"
          transform="translate(974 145)"
          fill="#dbdbdb"
        />
        <path
          id="Intersection_38"
          data-name="Intersection 38"
          d="M0,6.759A10.482,10.482,0,0,1,12.938.481L12.11,2.722A8.074,8.074,0,0,0,2.3,7.424Z"
          transform="translate(974.8 145.133)"
          fill="#535353"
          stroke="#dbdbdb"
          strokeWidth="0.3"
        />
      </g>
    </svg>
  );
}

export default SvgDoughnutSmallIcon;

/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { Card, Col, Progress, Row } from "antd";
import { Spacer } from "components";
import { KpiToggle } from "components/kpi-toggle";
import { newTransactionKpi, newUserKpi, userTokenUsedKpi } from "services/kpi";
import { useTranslation } from "react-i18next";

export const DashboardKpis = () => {
  const { t } = useTranslation(["common", "dashboard"]);
  const [currentFilter, setCurrentFilter] = useState("week");
  const [allNewUserKpis, setAllNewUserKpis] = useState(null);
  const [currentNewUserKpi, setCurrentNewUserKpi] = useState(null);
  const [allNewTransactionKpis, setAllNewTransactionKpis] = useState(null);
  const [currentNewTransactionKpi, setCurrentNewTransactionKpi] =
    useState(null);
  const [allTokenUseKpi, setAllTokenUseKpi] = useState(null);
  const [currentTokenUseKpi, setCurrentTokenUseKpi] = useState(null);

  useEffect(() => {
    retrieveNewUserKpis();
    retrieveNewTransactionKpis();
    retrieveTokenUseKpis();
  }, []);

  const retrieveNewUserKpis = () => {
    newUserKpi()
      .then((response) => {
        setAllNewUserKpis(response);
        if (currentFilter === "week") {
          setCurrentNewUserKpi(response?.week);
        }
      })
      .catch((e) => {
        console.log("e :>> ", e);
      });
  };

  const retrieveNewTransactionKpis = () => {
    newTransactionKpi()
      .then((response) => {
        setAllNewTransactionKpis(response);
        if (currentFilter === "week") {
          setCurrentNewTransactionKpi(response?.week);
        }
      })
      .catch((e) => {
        console.log("e :>> ", e);
      });
  };

  const retrieveTokenUseKpis = () => {
    userTokenUsedKpi()
      .then((response) => {
        setAllTokenUseKpi(response);
        if (currentFilter === "week") {
          setCurrentTokenUseKpi(response?.week);
        }
      })
      .catch((e) => {
        console.log("e :>> ", e);
      });
  };

  const handleChange = (e) => {
    setCurrentFilter(e.target.value);
    handleCurrentSelectionNewUserKpi(e.target.value);
  };

  const handleCurrentSelectionNewUserKpi = (toggleValue) => {
    if (toggleValue === "month") {
      setCurrentNewUserKpi(allNewUserKpis?.month);
      setCurrentNewTransactionKpi(allNewTransactionKpis?.month);
      setCurrentTokenUseKpi(allTokenUseKpi?.month);
    } else if (toggleValue === "year") {
      setCurrentNewUserKpi(allNewUserKpis?.year);
      setCurrentNewTransactionKpi(allNewTransactionKpis?.year);
      setCurrentTokenUseKpi(allTokenUseKpi?.year);
    } else {
      setCurrentNewUserKpi(allNewUserKpis?.week);
      setCurrentNewTransactionKpi(allNewTransactionKpis?.week);
      setCurrentTokenUseKpi(allTokenUseKpi?.week);
    }
  };

  const renderLocationProgressBar = (percentageUsed) => {
    const percent = +Number(percentageUsed ?? 0).toFixed(0);
    const config = {
      height: 16,
      percent,
      strokeColor: "#0000FF",
    };

    return (
      <Col key={`loc-ad`} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
        <div>
          <Progress {...config} />
        </div>
      </Col>
    );
  };

  return (
    <Card className={"dashboard-stats kpi-card"}>
      <Row
        gutter={[8, 8]}
        style={{
          marginBottom: 10,
        }}
      >
        <Col xs={24}>
          <div className={"d-flex flex-row justify-content-between"}>
            <h4>{t`dashboard:kpi.title`}</h4>
            <div>
              <KpiToggle
                opts={["week", "month", "year"]}
                value={currentFilter}
                handleChange={handleChange}
                color="turquoise"
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
          <Row gutter={[8, 8]}>
            <Col xs={24}>
              <Card className="kpi-card">
                <div>
                  <p className="percentage">{`${
                    currentNewUserKpi?.diff > 0 ? "+" : ""
                  } 
                  ${Number(currentNewUserKpi?.diff ?? 0).toFixed(0) ?? 0}%`}</p>
                  <h1 className="yellow-text">
                    {currentNewUserKpi?.count ?? 0}
                  </h1>
                </div>
                <h3 className="blue-text">
                  {t(`dashboard:kpi.newUser`, {
                    count: +currentNewUserKpi?.count ?? 0,
                  })}
                </h3>
              </Card>
            </Col>
            <Col xs={24}>
              <Card className="kpi-card">
                <div>
                  <p className="percentage">{`${
                    currentNewTransactionKpi?.diff > 0 ? "+" : ""
                  } 
                  ${
                    Number(currentNewTransactionKpi?.diff ?? 0).toFixed(0) ?? 0
                  }%`}</p>
                  <h1 className="yellow-text">
                    {currentNewTransactionKpi?.count ?? 0}
                  </h1>
                </div>
                <h3 className="blue-text">
                  {t(`dashboard:kpi.newTransaction`, {
                    count: +currentNewTransactionKpi?.count ?? 0,
                  })}
                </h3>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={16} xl={16} xxl={16}>
          <Card className="kpi-card">
            <div>
              <p className="percentage">{`${
                currentTokenUseKpi?.diff > 0 ? "+" : ""
              } 
                  ${
                    Number(currentTokenUseKpi?.diff ?? 0).toFixed(0) ?? 0
                  }%`}</p>
              <h1 className="yellow-text">{currentTokenUseKpi?.count ?? 0}</h1>
            </div>
            <h3 className="blue-text">
              {t(`dashboard:kpi.tokenUsed`, {
                count: +currentTokenUseKpi?.count ?? 0,
              })}
            </h3>
            <div className="kpi-additional-text-container">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <p className="dashboard-kpi-total-text">{t`dashboard:kpi.percentageUsed`}</p>
                  <p className="dashboard-kpi-count-text">
                    {t("dashboard:kpi.senior", {
                      count: currentTokenUseKpi?.totalUsers ?? 0,
                    })}
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <p className="kpi-progress-style">
                    {renderLocationProgressBar(
                      currentTokenUseKpi?.percentageUsed
                    )}
                  </p>
                </Col>
              </Row>
            </div>
            <div className="kpi-additional-text-container">
              <p className="dashboard-kpi-total-text">{`${t(
                "dashboard:kpi.forTotal"
              )}: ${Number(
                currentTokenUseKpi?.totalAmountGenerated ?? 0
              ).toFixed(0)}€`}</p>
              <p className="dashboard-kpi-count-text">
                {t("dashboard:kpi.forPeople", {
                  amount: currentTokenUseKpi?.totalUserGenerated ?? 0,
                })}
              </p>
            </div>
            <div className="link-position">
              <a
                href="/kpis"
                className="link-style"
              >{t`dashboard:kpi.viewAllKpiLink`}</a>
            </div>
          </Card>
        </Col>
      </Row>
      <Spacer space={20} />
    </Card>
  );
};

export default DashboardKpis;

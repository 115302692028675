import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import MainLayout from "layouts/main";
import { useTranslation } from "react-i18next";
import { Card, Col, Row, Input, Avatar, Form, message } from "antd";
import { TextButton } from "components";
import ParametersStyle from "styles/parametersStyles";
import { Formik } from "formik";
import * as yup from "yup";
import FormItem from "antd/lib/form/FormItem";
import { FileUploader } from "components/file-uploader";
import { FileImageOutlined, UserOutlined } from "@ant-design/icons";
import { CustomColorPicker } from "components/custom-color-picker";
import {
  deletePartnerLogo,
  getPartnerInfo,
  updatePartner,
  uploadPartnerLogo,
} from "services/parameter";
import { fullImageUrl } from "components/utils";
import { GlobalContext } from "contexts/global";

const MAX_AVATAR_FILE_SIZE = 5;

const Parameters = () => {
  const { t } = useTranslation(["common", "parameter"]);
  const uploadImageRef = useRef(null);
  const [partner, setPartner] = useState(null);
  const [partnerColors, setPartnerColors] = useState(null);
  const [partnerLogo, setPartnerLogo] = useState(null);
  const [isFetchingParameters, setIsFetchingParameters] = useState(false);
  const { currentUser, setCurrentUser } = useContext(GlobalContext);

  const initialValues = {
    logo: partner?.logo || "",
    yellow: partnerColors?.yellow?.newColor || "",
    green: partnerColors?.green?.newColor || "",
    blue: partnerColors?.blue?.newColor || "",
    salmon: partnerColors?.salmon?.newColor || "",
    signOutUrl: partner?.signOutUrl || "",
  };

  const partnerValidationSchema = yup.object().shape({
    yellow: yup.string(),
    green: yup.string(),
    blue: yup.string(),
    salmon: yup.string(),
    signOutUrl: yup.string().url(t`common:errors.incorrectUrl`),
  });

  useEffect(() => {
    setIsFetchingParameters(true);
    getPartnerInfo()
      .then((resp) => {
        console.log("resp :>> ", resp);
        if (resp?.partnerThemes) {
          const colors = resp.partnerThemes.map((item) => ({
            [item.originalColor]: {
              id: item.id,
              newColor: item.newColor,
            },
          }));

          setPartnerColors(Object.assign({}, ...colors));
        }
        setPartner(resp);
        setPartnerLogo(fullImageUrl(resp?.logo));
        setIsFetchingParameters(false);
      })
      .catch((e) => {
        console.log("e :>> ", e?.response?.data);
      });
  }, []);

  const handleSubmitParameter = (values, { setSubmitting }) => {
    setSubmitting(true);

    const payload = {
      partnerThemes: [
        {
          id: partnerColors?.yellow.id,
          originalColor: "yellow",
          newColor: values.yellow,
        },
        {
          id: partnerColors?.green.id,
          originalColor: "green",
          newColor: values.green,
        },
        {
          id: partnerColors?.blue.id,
          originalColor: "blue",
          newColor: values.blue,
        },
        {
          id: partnerColors?.salmon.id,
          originalColor: "salmon",
          newColor: values.salmon,
        },
      ],
      signOutUrl: values.signOutUrl,
    };
    console.log("payload :>> ", payload);
    updatePartner(payload)
      .then((resp) => {
        console.log("resp :>> ", resp);
        message.success(t`parameter:success.updatePartner`, 5);
      })
      .catch((e) => {
        console.log("e :>> ", e);
        message.error(t`parameter:error.updatePartner`, 5);
      })
      .finally(() => setSubmitting(false));
  };

  const handleUploadImageRef = () => uploadImageRef?.current?.click();

  const handleDeleteUploadedImage = () => {
    deletePartnerLogo()
      .then((resp) => {
        console.log("resp :>> ", resp);
        message.success(t`parameter:success.deleteImage`);
        setPartnerLogo(null);
        setCurrentUser({ ...currentUser, partnerLogo: null });
      })
      .catch((e) => {
        console.log("e :>> ", e);
        message.error(t`parameter:error.deleteImage`);
      });
  };

  const handleUploadImage = ({ blob, base64, file }) => {
    setPartnerLogo(base64);

    if (base64) {
      const fileName = file.name;
      const fileType = file.type;
      const headers = {
        "Content-Type": "multipart/form-data",
        "Mime-Type": fileType,
      };
      const payload = new FormData();
      payload.append("avatar", blob, fileName);

      uploadPartnerLogo(payload, headers)
        .then((resp) => {
          console.log("resp :>> ", resp);
          setCurrentUser({ ...currentUser, partnerLogo: resp?.imageUrls });
          message.success(t`parameter:success.uploadImage`);
        })
        .catch((e) => {
          console.log("##### CATCH e :>> ", e?.response?.data?.error);
          message.error(e?.response?.data?.error?.message, 5);
        });
    }
  };

  return (
    <Fragment>
      <ParametersStyle />
      <MainLayout className="parameter-main-container">
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <Card className="parameter-card">
                  <h3 className="card-label">{t`parameter:apiKey.title`}</h3>
                  <div className="input-group">
                    <label className="blue">{t`parameter:apiKey.content`}</label>
                    <p className="parameter-secret-key-container">
                      {partner?.apiKey}
                    </p>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <Row gutter={[8, 8]}>
              <Col xs={24}>
                <Card className="parameter-card">
                  <h3 className="card-label">{t`parameter:visual.title`}</h3>
                  <FormItem
                    label={t`parameter:visual.import`}
                    className="image-uploader-container display-vertical"
                  >
                    <FileUploader
                      uploadRef={uploadImageRef}
                      acceptedFileExts={[".png", ".jpeg", ".jpg", ".gif"]}
                      maxFileSize={MAX_AVATAR_FILE_SIZE * 1024 * 1024}
                      // includeCropper={true}
                      showUploadList={false}
                      cropProps={{
                        shape: "rect",
                      }}
                      uploadFile={handleUploadImage}
                    >
                      <Avatar
                        src={partnerLogo}
                        shape="square"
                        icon={<FileImageOutlined />}
                        size={200}
                      />
                    </FileUploader>
                    <div className="upload-image-buttons-container">
                      <TextButton
                        variant="turquoise"
                        rounded
                        width="12rem"
                        onClick={handleUploadImageRef}
                        type="button"
                      >{t`common:buttons.chooseFile`}</TextButton>
                      <TextButton
                        variant="red"
                        rounded
                        width="12rem"
                        disabled={!partnerLogo}
                        onClick={handleDeleteUploadedImage}
                        type="button"
                      >{t`common:buttons.delete`}</TextButton>
                    </div>
                  </FormItem>
                  {!isFetchingParameters && (
                    <Formik
                      initialValues={initialValues}
                      validateOnChange
                      validationSchema={partnerValidationSchema}
                      onSubmit={handleSubmitParameter}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        isSubmitting,
                        handleSubmit,
                        dirty,
                        isValid,
                        setFieldTouched,
                        setFieldValue,
                      }) => {
                        const retrieveErrors = (field) =>
                          (touched[field] && errors[field]) || "";

                        const handleChangeField = (e) => {
                          e?.target?.name &&
                            !touched[e.target.name] &&
                            setFieldTouched(e.target.name);
                          handleChange(e);
                        };

                        const handleChangeFieldByName = (name) => (value) => {
                          setFieldTouched(name);
                          setFieldValue(name, value);
                        };

                        return (
                          <Form layout="vertical" onFinish={handleSubmit}>
                            <div className="parameter-color-container">
                              <FormItem
                                label={t("parameter:visual.blue")}
                                className="tag-content"
                              >
                                <CustomColorPicker
                                  initialColor={values.blue || "#003466"}
                                  onChange={handleChangeFieldByName("blue")}
                                />
                                <Input name="blue" value={values.blue} />
                              </FormItem>
                              <FormItem
                                label={t("parameter:visual.salmon")}
                                className="tag-content"
                              >
                                <CustomColorPicker
                                  initialColor={values.salmon || "#F7A58D"}
                                  onChange={handleChangeFieldByName("salmon")}
                                />
                                <Input name="salmon" value={values.salmon} />
                              </FormItem>
                              <FormItem
                                label={t("parameter:visual.yellow")}
                                className="tag-content"
                              >
                                <CustomColorPicker
                                  initialColor={values.yellow || "#FFB93E"}
                                  onChange={handleChangeFieldByName("yellow")}
                                />
                                <Input name="yellow" value={values.yellow} />
                              </FormItem>
                              <FormItem
                                label={t("parameter:visual.green")}
                                className="tag-content"
                              >
                                <CustomColorPicker
                                  initialColor={values.green || "#3E9363"}
                                  onChange={handleChangeFieldByName("green")}
                                />
                                <Input name="green" value={values.green} />
                              </FormItem>
                            </div>
                            <div>
                              <h3 className="card-label">{t`parameter:disconnect.title`}</h3>
                              <div className="input-group">
                                <FormItem
                                  label={t`parameter:disconnect.label`}
                                  help={retrieveErrors("signOutUrl")}
                                  validateStatus={
                                    retrieveErrors("signOutUrl") && "error"
                                  }
                                  className="image-uploader-container"
                                >
                                  <Input
                                    name="signOutUrl"
                                    value={values.signOutUrl}
                                    onChange={handleChangeField}
                                  />
                                </FormItem>
                              </div>
                            </div>
                            <div className="parameter-visual-footer">
                              <TextButton
                                variant="turquoise"
                                type="outlined"
                                rounded
                                htmlType="submit"
                                disabled={isSubmitting || !isValid || !dirty}
                                width="14rem"
                              >{t`common:buttons.save`}</TextButton>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  )}
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </MainLayout>
    </Fragment>
  );
};

export default Parameters;

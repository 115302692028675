import * as React from "react";

function SvgAdministratorIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.898"
      height="24.331"
      viewBox="0 0 23.898 24.331"
    >
      <g id="Group_2712" data-name="Group 2712" transform="translate(-2 -1.29)">
        <path
          id="Path_1829"
          data-name="Path 1829"
          d="M12.993,11.437a5.073,5.073,0,1,1,5.073-5.066A5.073,5.073,0,0,1,12.993,11.437Zm0-8.638a3.572,3.572,0,1,0,3.572,3.572A3.572,3.572,0,0,0,12.993,2.8Z"
          transform="translate(-1.478)"
          fill="#b0b0b0"
        />
        <path
          id="Path_1830"
          data-name="Path 1830"
          d="M12.822,28.146a1.606,1.606,0,0,1-.465-1.261H3.5V22.547a11.115,11.115,0,0,1,8.323-3.512h.54a1.651,1.651,0,0,1,.465-1.388l.09-.083c-.353,0-.75-.045-1.1-.045A12.361,12.361,0,0,0,2.15,21.827a.75.75,0,0,0-.15.45v4.608a1.5,1.5,0,0,0,1.5,1.5h9.531Z"
          transform="translate(0 -4.049)"
          fill="#b0b0b0"
        />
        <path
          id="Path_1832"
          data-name="Path 1832"
          d="M29.2,21.4l-1.5-.458a5.411,5.411,0,0,0-.435-1.058l.75-1.4a.285.285,0,0,0-.075-.33l-1.088-1.088a.27.27,0,0,0-.33-.053l-1.381.75a5.366,5.366,0,0,0-1.073-.458l-.458-1.5a.27.27,0,0,0-.27-.18H21.8a.27.27,0,0,0-.263.2l-.458,1.5a5.254,5.254,0,0,0-1.081.45l-1.366-.75a.263.263,0,0,0-.323.053L17.2,18.159a.285.285,0,0,0-.045.33l.75,1.366a5.081,5.081,0,0,0-.473,1.073l-1.5.45a.27.27,0,0,0-.2.263V23.18a.263.263,0,0,0,.2.233l1.5.458a5.253,5.253,0,0,0,.45,1.058l-.75,1.433a.27.27,0,0,0,.045.323l1.088,1.088a.285.285,0,0,0,.33.053l1.4-.75a5.321,5.321,0,0,0,1.051.428L21.5,29a.285.285,0,0,0,.263.2H23.3a.278.278,0,0,0,.263-.2l.458-1.539a5.193,5.193,0,0,0,1.036-.428l1.418.75a.27.27,0,0,0,.323-.053l1.141-1.021a.263.263,0,0,0,0-.3L27.192,25a5.253,5.253,0,0,0,.435-1.043l1.5-.458a.27.27,0,0,0,.2-.263V21.664a.27.27,0,0,0-.12-.263Zm-6.627,3.512a2.507,2.507,0,1,1,2.5-2.5,2.507,2.507,0,0,1-2.5,2.5Z"
          transform="translate(-3.429 -3.578)"
          fill="#b0b0b0"
        />
      </g>
    </svg>
  );
}

export default SvgAdministratorIcon;

import { del, get, patch, post } from "./axios-service";

export const getPartnerInfo = () => {
  return get(`partners`);
};

export const updatePartner = (payload) => {
  return patch(`partners`, payload);
};

export const deletePartnerLogo = () => {
  return del(`partners/delete-logo`);
};

export const uploadPartnerLogo = (payload, headers) => {
  return post(`partners/upload-logo`, payload, headers);
};

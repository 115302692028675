import * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SvgRadialSmallIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g
        id="Group_2007"
        data-name="Group 2007"
        transform="translate(-1961.898 -319.747)"
      >
        <g
          id="Path_1209"
          data-name="Path 1209"
          transform="translate(1962.796 320.79)"
          fill="none"
        >
          <path
            d="M11.078,0A11.078,11.078,0,1,1,0,11.078,11.078,11.078,0,0,1,11.078,0Z"
            stroke="none"
          />
          <path
            d="M 11.07797908782959 0.09999847412109375 C 8.145648956298828 0.09999847412109375 5.388839721679688 1.241909027099609 3.315378189086914 3.315378189086914 C 1.241909027099609 5.388839721679688 0.09999847412109375 8.145648956298828 0.09999847412109375 11.07797908782959 C 0.09999847412109375 14.01030921936035 1.241909027099609 16.76711845397949 3.315378189086914 18.84057998657227 C 5.388839721679688 20.91404914855957 8.145648956298828 22.05595970153809 11.07797908782959 22.05595970153809 C 14.01030921936035 22.05595970153809 16.76711845397949 20.91404914855957 18.84057998657227 18.84057998657227 C 20.91404914855957 16.76711845397949 22.05595970153809 14.01030921936035 22.05595970153809 11.07797908782959 C 22.05595970153809 8.145648956298828 20.91404914855957 5.388839721679688 18.84057998657227 3.315378189086914 C 16.76711845397949 1.241909027099609 14.01030921936035 0.09999847412109375 11.07797908782959 0.09999847412109375 M 11.07797908782959 0 C 17.1961784362793 0 22.15595817565918 4.959779739379883 22.15595817565918 11.07797908782959 C 22.15595817565918 17.1961784362793 17.1961784362793 22.15595817565918 11.07797908782959 22.15595817565918 C 4.959779739379883 22.15595817565918 0 17.1961784362793 0 11.07797908782959 C 0 4.959779739379883 4.959779739379883 0 11.07797908782959 0 Z"
            stroke="none"
            fill="#707070"
          />
        </g>
        <g
          id="Ellipse_407"
          data-name="Ellipse 407"
          transform="translate(1964.898 322.747)"
          fill="none"
          stroke="#707070"
          strokeWidth="0.1"
        >
          <circle cx="9" cy="9" r="9" stroke="none" />
          <circle cx="9" cy="9" r="8.95" fill="none" />
        </g>
        <g
          id="Ellipse_405"
          data-name="Ellipse 405"
          transform="translate(1967.898 325.747)"
          fill="none"
          stroke="#707070"
          strokeWidth="0.1"
        >
          <circle cx="6" cy="6" r="6" stroke="none" />
          <circle cx="6" cy="6" r="5.95" fill="none" />
        </g>
        <g
          id="Ellipse_404"
          data-name="Ellipse 404"
          transform="translate(1969.898 327.747)"
          fill="none"
          stroke="#707070"
          strokeWidth="0.3"
        >
          <circle cx="4" cy="4" r="4" stroke="none" />
          <circle cx="4" cy="4" r="3.85" fill="none" />
        </g>
        <g
          id="Group_2006"
          data-name="Group 2006"
          transform="translate(1963.231 321.362)"
          opacity="0.22"
        >
          <path
            id="Path_1205"
            data-name="Path 1205"
            d="M2230.224-518.524V-497.3"
            transform="translate(-2219.611 518.524)"
            fill="none"
            stroke="#707070"
            strokeWidth="0.3"
          />
          <path
            id="Path_1206"
            data-name="Path 1206"
            d="M0,0V21.227"
            transform="translate(21.227 10.613) rotate(90)"
            fill="none"
            stroke="#707070"
            strokeWidth="0.3"
          />
          <path
            id="Path_1207"
            data-name="Path 1207"
            d="M0,0V21.227"
            transform="translate(18.118 18.118) rotate(135)"
            fill="none"
            stroke="#707070"
            strokeWidth="0.3"
          />
          <path
            id="Path_1208"
            data-name="Path 1208"
            d="M0,0V21.227"
            transform="translate(18.118 3.109) rotate(45)"
            fill="none"
            stroke="#707070"
            strokeWidth="0.3"
          />
        </g>
        <g
          id="Ellipse_406"
          data-name="Ellipse 406"
          transform="translate(1971.898 329.747)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="0.3"
        >
          <circle cx="2" cy="2" r="2" stroke="none" />
          <circle cx="2" cy="2" r="1.85" fill="none" />
        </g>
        <g
          id="Ellipse_408"
          data-name="Ellipse 408"
          transform="translate(1972.898 319.747)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="0.3"
        >
          <circle cx="1" cy="1" r="1" stroke="none" />
          <circle cx="1" cy="1" r="0.85" fill="none" />
        </g>
        <g
          id="Ellipse_409"
          data-name="Ellipse 409"
          transform="translate(1972.898 341.747)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="0.3"
        >
          <circle cx="1" cy="1" r="1" stroke="none" />
          <circle cx="1" cy="1" r="0.85" fill="none" />
        </g>
        <g
          id="Ellipse_410"
          data-name="Ellipse 410"
          transform="translate(1983.898 330.747)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="0.3"
        >
          <circle cx="1" cy="1" r="1" stroke="none" />
          <circle cx="1" cy="1" r="0.85" fill="none" />
        </g>
        <g
          id="Ellipse_412"
          data-name="Ellipse 412"
          transform="translate(1980.898 338.747)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="0.3"
        >
          <circle cx="1" cy="1" r="1" stroke="none" />
          <circle cx="1" cy="1" r="0.85" fill="none" />
        </g>
        <g
          id="Ellipse_413"
          data-name="Ellipse 413"
          transform="translate(1980.898 322.747)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="0.3"
        >
          <circle cx="1" cy="1" r="1" stroke="none" />
          <circle cx="1" cy="1" r="0.85" fill="none" />
        </g>
        <g
          id="Ellipse_414"
          data-name="Ellipse 414"
          transform="translate(1964.898 322.747)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="0.3"
        >
          <circle cx="1" cy="1" r="1" stroke="none" />
          <circle cx="1" cy="1" r="0.85" fill="none" />
        </g>
        <g
          id="Ellipse_415"
          data-name="Ellipse 415"
          transform="translate(1964.898 338.747)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="0.3"
        >
          <circle cx="1" cy="1" r="1" stroke="none" />
          <circle cx="1" cy="1" r="0.85" fill="none" />
        </g>
        <g
          id="Ellipse_411"
          data-name="Ellipse 411"
          transform="translate(1961.898 330.747)"
          fill="#fff"
          stroke="#707070"
          strokeWidth="0.3"
        >
          <circle cx="1" cy="1" r="1" stroke="none" />
          <circle cx="1" cy="1" r="0.85" fill="none" />
        </g>
        <path
          id="Path_1210"
          data-name="Path 1210"
          d="M1984.945,343.549l5.683,1.816,4.716-.762,3.369,4.6-5.272,2.695-2.812,2.461-4.57-.615.762-4.54Z"
          transform="translate(-16.825 -17.273)"
          fill="#b3b3b3"
          opacity="0.7"
        />
      </g>
    </svg>
  );
}

export default SvgRadialSmallIcon;

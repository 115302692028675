import * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SvgRadialBigIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
    >
      <g
        id="Group_2007"
        data-name="Group 2007"
        transform="translate(-1960.898 -318.733)"
      >
        <g
          id="Path_1209"
          data-name="Path 1209"
          transform="translate(1962.908 320.92)"
          fill="none"
        >
          <path
            d="M12.456,0A12.456,12.456,0,1,1,0,12.456,12.456,12.456,0,0,1,12.456,0Z"
            stroke="none"
          />
          <path
            d="M 12.45607757568359 0.09999847412109375 C 9.155647277832031 0.09999847412109375 6.052766799926758 1.385257720947266 3.71900749206543 3.71900749206543 C 1.385257720947266 6.052766799926758 0.09999847412109375 9.155647277832031 0.09999847412109375 12.45607757568359 C 0.09999847412109375 15.75650787353516 1.385257720947266 18.8593864440918 3.71900749206543 21.19314765930176 C 6.052766799926758 23.52689743041992 9.155647277832031 24.81215667724609 12.45607757568359 24.81215667724609 C 15.75650787353516 24.81215667724609 18.8593864440918 23.52689743041992 21.19314765930176 21.19314765930176 C 23.52689743041992 18.8593864440918 24.81215667724609 15.75650787353516 24.81215667724609 12.45607757568359 C 24.81215667724609 9.155647277832031 23.52689743041992 6.052766799926758 21.19314765930176 3.71900749206543 C 18.8593864440918 1.385257720947266 15.75650787353516 0.09999847412109375 12.45607757568359 0.09999847412109375 M 12.45607757568359 -1.9073486328125e-06 C 19.33537673950195 -1.9073486328125e-06 24.91215705871582 5.576778411865234 24.91215705871582 12.45607757568359 C 24.91215705871582 19.33537673950195 19.33537673950195 24.91215705871582 12.45607757568359 24.91215705871582 C 5.576778411865234 24.91215705871582 -1.9073486328125e-06 19.33537673950195 -1.9073486328125e-06 12.45607757568359 C -1.9073486328125e-06 5.576778411865234 5.576778411865234 -1.9073486328125e-06 12.45607757568359 -1.9073486328125e-06 Z"
            stroke="none"
            fill="#013466"
          />
        </g>
        <g
          id="Ellipse_407"
          data-name="Ellipse 407"
          transform="translate(1965.898 323.733)"
          fill="none"
          stroke="#013466"
          strokeWidth="0.1"
        >
          <circle cx="9.5" cy="9.5" r="9.5" stroke="none" />
          <circle cx="9.5" cy="9.5" r="9.45" fill="none" />
        </g>
        <g
          id="Ellipse_405"
          data-name="Ellipse 405"
          transform="translate(1968.898 326.733)"
          fill="none"
          stroke="#013466"
          strokeWidth="0.1"
        >
          <circle cx="6.5" cy="6.5" r="6.5" stroke="none" />
          <circle cx="6.5" cy="6.5" r="6.45" fill="none" />
        </g>
        <g
          id="Ellipse_404"
          data-name="Ellipse 404"
          transform="translate(1970.898 328.733)"
          fill="none"
          stroke="#013466"
          strokeWidth="0.3"
        >
          <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
          <circle cx="4.5" cy="4.5" r="4.35" fill="none" />
        </g>
        <g
          id="Group_2006"
          data-name="Group 2006"
          transform="translate(1963.397 321.563)"
        >
          <path
            id="Path_1205"
            data-name="Path 1205"
            d="M2230.224-518.524v23.867"
            transform="translate(-2218.291 518.524)"
            fill="none"
            stroke="#013466"
            strokeWidth="0.3"
          />
          <path
            id="Path_1206"
            data-name="Path 1206"
            d="M0,0V23.867"
            transform="translate(23.867 11.934) rotate(90)"
            fill="none"
            stroke="#013466"
            strokeWidth="0.3"
          />
          <path
            id="Path_1207"
            data-name="Path 1207"
            d="M0,0V23.867"
            transform="translate(20.372 20.372) rotate(135)"
            fill="none"
            stroke="#013466"
            strokeWidth="0.3"
          />
          <path
            id="Path_1208"
            data-name="Path 1208"
            d="M0,0V23.867"
            transform="translate(20.372 3.495) rotate(45)"
            fill="none"
            stroke="#013466"
            strokeWidth="0.3"
          />
        </g>
        <g
          id="Ellipse_406"
          data-name="Ellipse 406"
          transform="translate(1972.898 330.733)"
          fill="#fff"
          stroke="#013466"
          strokeWidth="0.3"
        >
          <circle cx="2.5" cy="2.5" r="2.5" stroke="none" />
          <circle cx="2.5" cy="2.5" r="2.35" fill="none" />
        </g>
        <g
          id="Ellipse_408"
          data-name="Ellipse 408"
          transform="translate(1973.898 318.733)"
          fill="#fff"
          stroke="#013466"
          strokeWidth="0.3"
        >
          <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
          <circle cx="1.5" cy="1.5" r="1.35" fill="none" />
        </g>
        <g
          id="Ellipse_409"
          data-name="Ellipse 409"
          transform="translate(1973.898 344.733)"
          fill="#fff"
          stroke="#013466"
          strokeWidth="0.3"
        >
          <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
          <circle cx="1.5" cy="1.5" r="1.35" fill="none" />
        </g>
        <g
          id="Ellipse_410"
          data-name="Ellipse 410"
          transform="translate(1986.898 331.733)"
          fill="#fff"
          stroke="#013466"
          strokeWidth="0.3"
        >
          <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
          <circle cx="1.5" cy="1.5" r="1.35" fill="none" />
        </g>
        <g
          id="Ellipse_412"
          data-name="Ellipse 412"
          transform="translate(1982.898 340.733)"
          fill="#fff"
          stroke="#013466"
          strokeWidth="0.3"
        >
          <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
          <circle cx="1.5" cy="1.5" r="1.35" fill="none" />
        </g>
        <g
          id="Ellipse_413"
          data-name="Ellipse 413"
          transform="translate(1982.898 322.733)"
          fill="#fff"
          stroke="#013466"
          strokeWidth="0.3"
        >
          <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
          <circle cx="1.5" cy="1.5" r="1.35" fill="none" />
        </g>
        <g
          id="Ellipse_414"
          data-name="Ellipse 414"
          transform="translate(1964.898 322.733)"
          fill="#fff"
          stroke="#013466"
          strokeWidth="0.3"
        >
          <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
          <circle cx="1.5" cy="1.5" r="1.35" fill="none" />
        </g>
        <g
          id="Ellipse_415"
          data-name="Ellipse 415"
          transform="translate(1964.898 340.733)"
          fill="#fff"
          stroke="#013466"
          strokeWidth="0.3"
        >
          <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
          <circle cx="1.5" cy="1.5" r="1.35" fill="none" />
        </g>
        <g
          id="Ellipse_411"
          data-name="Ellipse 411"
          transform="translate(1960.898 331.733)"
          fill="#fff"
          stroke="#013466"
          strokeWidth="0.3"
        >
          <circle cx="1.5" cy="1.5" r="1.5" stroke="none" />
          <circle cx="1.5" cy="1.5" r="1.35" fill="none" />
        </g>
        <path
          id="Path_1210"
          data-name="Path 1210"
          d="M1984.945,343.549l6.39,2.042,5.3-.856,3.787,5.171-5.928,3.03-3.162,2.767-5.138-.692.856-5.105Z"
          transform="translate(-16.051 -16.461)"
          fill="#74d2e7"
          opacity="0.7"
        />
      </g>
    </svg>
  );
}

export default SvgRadialBigIcon;

import { useContext, useState } from "react";
import { Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Sider from "antd/lib/layout/Sider";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { Row } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import {
  AnnoncesIcon,
  DashboardIcon,
  MailIcon,
  MessagerieIcon,
  ParameterIcon,
  PhoneIcon,
  SignOut,
  TransactionIcon,
  SubventionIcon,
  UsersIcon,
  NeoSilverIcon,
  AdministratorIcon,
} from "../icons/";
import { useTranslation } from "react-i18next";
import { fullImageUrl } from "../utils/";
import { GlobalContext } from "../../contexts/global";

const SiderBar = styled(({ children, ...rest }) => {
  const { t } = useTranslation(["common"]);
  const { avatar, clearAvatar, signOutUser, siderMenuOpen } =
    useContext(GlobalContext);

  const menuItems = [
    {
      key: "dashboard",
      label: t`common:dashboard`,
      link: "/tableau-de-bord",
      icon: DashboardIcon,
    },
    {
      key: "users",
      label: t`common:users`,
      link: "/utilisateurs",
      icon: UsersIcon,
    },
    {
      key: "kpis",
      label: t`common:kpis`,
      link: "/kpis",
      icon: AnnoncesIcon,
    },
    {
      key: "subventions",
      label: t`common:subventions`,
      link: "/mes-subventions",
      icon: SubventionIcon,
    },
    {
      key: "transaction",
      label: t`common:transactions`,
      link: "/transactions",
      icon: TransactionIcon,
    },
    {
      key: "settings",
      label: t`common:settings`,
      link: "/parametres",
      icon: ParameterIcon,
    },
    {
      key: "documents",
      label: t`common:myDocuments`,
      link: "/mes-documents",
      icon: MessagerieIcon,
    },
    {
      key: "administrators",
      label: t`common:administrators`,
      link: "/administrateurs",
      icon: AdministratorIcon,
    },
  ];

  const history = useHistory();
  const location = useLocation();
  let menuItemIndex = menuItems.findIndex(
    (menuItem) => menuItem.link === location.pathname
  );
  if (menuItemIndex >= 0) {
    sessionStorage.setItem("selectedItem", menuItemIndex.toString());
  } else {
    menuItemIndex = parseInt(sessionStorage.getItem("selectedItem"));
  }

  const currentSelectedMenuItemKey =
    menuItems[menuItemIndex >= 0 ? menuItemIndex : 0]?.key || "dashboard";
  const [collapsed, setCollapsed] = useState(siderMenuOpen);
  const [selectedMenuItemKey, setSelectedMenuItemKey] = useState(
    currentSelectedMenuItemKey
  );

  const onCollapse = (collapsed, event) => {
    if (event === true) sessionStorage.setItem("collapsed", collapsed);
    setCollapsed(collapsed);
  };

  const onLogout = (event) => {
    event.preventDefault();
    localStorage.removeItem("authKey");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("userfirstname");
    localStorage.removeItem("userlastname");
    sessionStorage.removeItem("token");
    clearAvatar();
    signOutUser();
    sessionStorage.clear();
    history.push("/connexion");
  };

  const firstname = localStorage.getItem("userfirstname");
  const lastname = localStorage.getItem("userlastname");

  const handleSelectedMenuItemClicked = ({ key }) => {
    setSelectedMenuItemKey(key);
  };

  const renderMenuItem = (item) => (
    <Menu.Item
      key={item.key}
      icon={<item.icon className="icon-style" />}
      className="side-menu-item"
    >
      {collapsed ? (
        <NavLink to={item.link}></NavLink>
      ) : (
        <NavLink to={item.link}>
          <div className="menu-item-text-holder">
            {!collapsed && <span className="menu-item-text">{item.label}</span>}
          </div>
        </NavLink>
      )}
    </Menu.Item>
  );

  const renderMenuItems = (items) => items.map(renderMenuItem);

  return (
    <Sider
      {...rest}
      collapsible
      collapsed={collapsed}
      breakpoint="md"
      collapsedWidth="70"
      onCollapse={() => onCollapse(collapsed, false)}
      onBreakpoint={(broken) => {
        //console.log(broken);
      }}
      style={{ height: "100%" }}
      trigger={null}
      width={250}
    >
      <Row
        style={{
          paddingTop: "1.25rem",
          paddingRight: "1rem",
          paddingLeft: "1rem",
          borderRight: collapsed ? "1px solid #f0f0f0" : "",
          display: "flex",
          justifyContent: collapsed ? "center" : "flex-end",
        }}
      >
        {!collapsed ? (
          <LeftOutlined
            onClick={() => onCollapse(!collapsed, true)}
            style={{ fontSize: "24px", color: "#707070" }}
          />
        ) : (
          <span onClick={() => onCollapse(!collapsed, true)}>
            <NeoSilverIcon />
          </span>
        )}
      </Row>
      <Row
        style={{
          paddingTop: "1.25rem",
          paddingRight: "1rem",
          paddingLeft: "1rem",
          paddingBottom: 10,
          display: "flex",
          borderRight: collapsed ? "1px solid #f0f0f0" : "",
          justifyContent: "center",
        }}
      >
        {!collapsed && (
          <Avatar
            size={120}
            icon={<UserOutlined />}
            src={fullImageUrl(avatar, "md")}
            style={{
              border: "5px solid #001AFF",
            }}
          />
        )}
      </Row>
      {!collapsed && (
        <Row
          style={{
            paddingTop: "1.25rem",
            paddingRight: "1rem",
            paddingLeft: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="text-font-color user-name">
            {`${firstname} ${lastname}`}
          </span>
        </Row>
      )}
      {!collapsed && (
        <Row
          style={{
            paddingTop: 2,
            paddingRight: "1rem",
            paddingLeft: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
          }}
        >
          <span className="text-font-color user-role">{"Admin"}</span>
        </Row>
      )}

      {!collapsed && (
        <Row className="side-menu-icons-container">
          <span>
            <PhoneIcon />
          </span>
          <span>
            <MailIcon />
          </span>
          <span onClick={onLogout}>
            <SignOut />
          </span>
        </Row>
      )}

      <Menu
        theme="light"
        defaultSelectedKeys={[selectedMenuItemKey]}
        onClick={handleSelectedMenuItemClicked}
        mode="inline"
      >
        {renderMenuItems(menuItems)}
      </Menu>
    </Sider>
  );
})`
  box-shadow: var(--box-shadow);
  z-index: 11;
  overflow: auto;
  height: calc(100vh - var(--header-height));
  position: fixed;
  left: 0;
  background: ${({ theme }) => theme.colors.white};

  .icon-style {
    font-size: 24px !important;
    // padding-right: 20;
    // padding-left: 0;
    // margin-top: 5;
    // color: #828282;
    color: red;
  }

  .menu-item-text-holder {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .text-font-color {
    color: #828282;
  }

  .menu-item-text {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    color: #828282;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: ${({ theme }) => `${theme.colors.lightBlue}1F`};
  }

  .ant-menu-item .menu-item-text {
    font-family: var(--font-glacial);
    font-size: 1rem;
  }

  .ant-menu-item-selected > svg path {
    fill: ${({ theme }) => theme.colors.purple};
  }

  .ant-menu-item-selected .menu-item-text {
    font-weight: 800;
    color: ${({ theme }) => theme.colors.purple};
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: ${({ theme }) => `0.5rem solid ${theme.colors.purple}`};
    right: unset;
    left: 0;
  }

  // .ant-menu-item {
  //   background-color: yellow;
  // }

  // .ant-menu-item + span {
  //   background-color: red;
  // }

  // .ant-menu-submenu-title + span {
  //   background-color: "red";
  //   width: 100%;
  // }

  .user-name {
    font-family: var(--font-gilroy);
    font-weight: 800;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.shades.grey[13]};
    text-align: center;

    &::before {
      --status-circle-width: 0.75rem;
      content: "";
      width: var(--status-circle-width);
      height: var(--status-circle-width);
      border-radius: calc(var(--status-circle-width) / 2);
      background: ${({ theme }) => theme.colors.lightGreen};
      display: inline-block;
      margin-right: 0.3rem;
    }
  }

  .user-role {
    font-family: var(--font-gilroy);
    font-weight: 200;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.colors.shades.grey[32]};
  }

  .side-menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .side-menu-icons-container {
    margin: 0.5rem 0 1.5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }
`;

export default SiderBar;

import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Row } from "antd";
import { fullImageUrl } from "components/utils";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export const TokenHistory = ({ token }) => {
  const { t } = useTranslation(["common", "token"]);

  return (
    <section className="token-history">
      <Row gutter={[8, 8]}>
        <Col lg={4}>
          <div className="token-avatar-container">
            <Avatar
              src={fullImageUrl(token.imageUrls, "sm")}
              icon={<UserOutlined />}
              size={90}
              className="token-avatar"
            />
          </div>
        </Col>
        <Col lg={20}>
          <p className="token-history-header">
            {t("token:tokenHistory.userLabel", {
              username: token.username,
              tokenNumber: token.tokenUsed,
            })}
          </p>

          <p className="token-history-label-amount">
            {t("token:tokenHistory.amountUsedLabel", {
              amount: Number(token.amount).toFixed(0),
            })}
          </p>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <p className="token-time">
            {token?.createdAt
              ? format(new Date(token?.createdAt), "dd/MM/yyyy HH:mm")
              : ""}
          </p>
        </Col>
      </Row>
    </section>
  );
};

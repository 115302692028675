import MainLayout from "layouts/main";
import { Card, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import DashboardStyle from "styles/dashboardStyles";
import { HandShake } from "../../components/icons/";
import CreateToken from "components/tokens/create";
import { DashboardKpis } from "components";

const Dashboard = () => {
  const { t } = useTranslation(["common", "dashboard"]);

  const firstname = localStorage.getItem("userfirstname");

  return (
    <MainLayout>
      <DashboardStyle />
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={12} lg={14} xl={16} xxl={16}>
          <Card className="dashboard-header">
            <div className="dashboard-greeting">
              <div className="dashboard-greeting-text">
                <h3>{t("dashboard:welcome.hello", { username: firstname })}</h3>
                <p>{t`dashboard:welcome.content`}</p>
              </div>
              <div className="handshake">
                <HandShake />
              </div>
            </div>
          </Card>
          <DashboardKpis />
        </Col>
        <Col xs={24} sm={24} md={12} lg={10} xl={8} xxl={8}>
          <CreateToken showCalendar />
        </Col>
      </Row>
    </MainLayout>
  );
};

export default Dashboard;

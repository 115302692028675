import * as React from "react";

function SvgToken(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61.86"
      height="61.86"
      viewBox="0 0 61.86 61.86"
      {...props}
    >
      <g
        id="noun_Poker_Chip_1799970"
        data-name="noun_Poker Chip_1799970"
        transform="translate(-2 -2)"
      >
        <g
          id="Group_1771"
          data-name="Group 1771"
          transform="translate(2.5 2.5)"
        >
          <path
            id="Path_758"
            data-name="Path 758"
            d="M32.93,2.5A30.43,30.43,0,1,0,63.36,32.93,30.458,30.458,0,0,0,32.93,2.5ZM52.021,13.839a26.692,26.692,0,0,1,5.83,8.713L52.534,24.73a21.129,21.129,0,0,0-4.613-6.919ZM32.93,5.959a27.418,27.418,0,0,1,10.314,2.05l-2.178,5.317a21.42,21.42,0,0,0-8.136-1.6h0ZM22.616,8.009l2.178,5.317a21.82,21.82,0,0,0-6.919,4.613l-4.1-4.036A27.072,27.072,0,0,1,22.616,8.009ZM8.009,22.616l5.317,2.178a21.42,21.42,0,0,0-1.6,8.136H5.959A26.228,26.228,0,0,1,8.009,22.616Zm5.83,29.405a26.3,26.3,0,0,1-5.83-8.777l5.317-2.178a21.129,21.129,0,0,0,4.613,6.919ZM32.93,59.9a26.46,26.46,0,0,1-10.314-2.05l2.178-5.317a21.42,21.42,0,0,0,8.136,1.6V59.9ZM15.121,32.93A17.81,17.81,0,1,1,32.93,50.74,17.854,17.854,0,0,1,15.121,32.93ZM43.244,57.851l-2.178-5.317a20.859,20.859,0,0,0,6.855-4.613l4.1,4.036A25.538,25.538,0,0,1,43.244,57.851ZM52.6,41.066a21.42,21.42,0,0,0,1.6-8.136h5.766a27.418,27.418,0,0,1-2.05,10.314Z"
            transform="translate(-2.5 -2.5)"
            fill={props.fill ? props.fill : "#003466"}
            stroke="#fff"
            strokeWidth="1"
          />
          <path
            id="Path_759"
            data-name="Path 759"
            d="M54.265,38.743,48.05,37.525a1.14,1.14,0,0,1-.7-.513L44.271,31.5a.972.972,0,0,0-1.73,0l-3.075,5.509a.992.992,0,0,1-.7.513l-6.214,1.217a.98.98,0,0,0-.513,1.666l4.292,4.613a.947.947,0,0,1,.256.833l-.7,6.278a1.009,1.009,0,0,0,1.409,1.025l5.7-2.691a.95.95,0,0,1,.833,0l5.7,2.691a1,1,0,0,0,1.409-1.025l-.769-6.278a1.009,1.009,0,0,1,.256-.833l4.292-4.613A.953.953,0,0,0,54.265,38.743Z"
            transform="translate(-13.008 -12.733)"
            fill={props.fill ? props.fill : "#003466"}
            stroke={props.fill ? null : "#fff"}
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgToken;

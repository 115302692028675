import { get } from "./axios-service";

export const getDatabasesDocuments = () => {
  return get("partners/documents/databases");
};

export const getContractsDocuments = () => {
  return get("partners/documents/contracts");
};

export const getInvoicesDocuments = () => {
  return get("partners/documents/invoices");
};

export const getOtherDocuments = () => {
  return get("partners/documents/other");
};

import { createGlobalStyle } from "styled-components/macro";

const GlobalStyle = createGlobalStyle`
  :root {
    --header-height: 5rem;
    --box-shadow: ${({ theme }) =>
      `0rem 0.1875rem 0.375rem ${theme.colors.shadow}`};
    --font-glacial: "Glacial Indifference";
    --font-gilroy: "Gilroy";
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  html {
    font-size: 16px;
  }
  
  body {
    margin: 0;
    padding: 0;
    font-family: "Gilroy","Glacial Indifference",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  }

  h1 {
    font-family: var(--font-glacial);
    font-weight: 400;
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.shades.grey[4]};
  }

  h2 {
    font-family: var(--font-gilroy);
    font-weight: 800;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.shades.grey[18]};
  }

  h2 {
    font-family: var(--font-gilroy);
    font-weight: 800;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  p {
    margin: 0;
  }

  .ant-table-thead > tr > th {
    font-family: var(--font-glacial);
    font-weight: 800;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.shades.grey[25]};
  }

  td.ant-table-cell {
    font-family: var(--font-glacial);
    font-weight: 200;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.colors.shades.grey[25]};
  }

  .ant-table-content {
    overflow-y: scroll;
  }

  .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
    font-family: var(--font-glacial);
  }

  .MuiPickersToolbar-toolbar {
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }
  
  .MuiPickersDay-daySelected {
    background-color: ${({ theme }) => theme.colors.darkBlue};
  }
  
  .MuiButton-textPrimary {
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  .ReactCrop.avatar-react-crop {
    margin: 0 auto;
    width: 500px;
    overflow: scroll;

    div {
      width: fit-content;

      .ReactCrop__image {
        max-width: 100%;
      }
    }
  }

  /* CALENDAR - START */
  .rbc-overlay {
    .delete-activity-btn {
      display: none;
    }
  }

  .custom-calendar-schedule-modal {
    width: auto !important;

    .ant-form-item-explain,
    .ant-form-item-explain-error {
      min-height: 0;
    }

    .ant-modal-content {
      border-radius: 1rem;

      form {
        width: 100%;
      }

      .custom-calendar-activity-title-select-form-item {
        max-width: 100%;
      }
    }
  }

  .custom-calendar-date-picker,
  .custom-calendar-time-picker {
    width: 100%;
    border-radius: 0.5rem;
  }

  .ant-form-item {
    .ant-form-item-control-input-content {
      .custom-calendar-repeat-select,
      .custom-calendar-activity-title-select {
        width: 100%;
    
        .ant-select-selector {
          border-radius: 0.5rem;
        }
      }
    }
  }

  .custom-calendar-basic-repeat-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin: 0.5rem 0 0 0;

    .ant-input {
      width: 7rem;
      border-radius: 0.5rem;
    }

    .ant-select {
      width: 100%;

      .ant-select-selector {
        border-radius: 0.5rem;
      }
    }
  }

  .custom-calendar-week-days-repeat-chkbx {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    .ant-checkbox-wrapper {
      --circle-size: 2rem;
      margin: 0;
      width: var(--circle-size);
      height: var(--circle-size);
      border-radius: calc(var(--circle-size) / 2);
      border: ${({ theme }) => `0.1rem solid ${theme.colors.shades.grey[60]}`};
      color: ${({ theme }) => theme.colors.black};
      background: ${({ theme }) => theme.colors.white};
      display: flex;
      justify-content: center;
      align-items: center;
      transition: color ease 200ms;
      transition: background ease 200ms;

      &.ant-checkbox-wrapper-checked {
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.darkBlue};

        &.ant-checkbox-wrapper-disabled {
          background: ${({ theme }) => theme.colors.shades.grey[60]};
        }
      }

      .ant-checkbox {
        display: none;
      }
    }
  }

  .custom-calendar-repeat-end-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem !important;

    .ant-form-item-label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .ant-form-item-control {
      .ant-form-item-control-input {
        .ant-radio-group {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;

          .ant-radio-wrapper.custom-calendar-radio {
            margin: 0;

            span:not(:first-of-type) {
              padding-right: 0;

              .custom-calendar-date-picker {
                width: 100%;

                .ant-picker-clear {
                  width: auto;
                }
              }
            }

            &.custom-calendar-repeat-end-date-picker {
              span:not(:first-of-type) {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .custom-calendar-repeat-month-select {
    width: 12rem;

    .ant-select-selector {
      border-radius: 0.5rem !important;
    }
  }

  .custom-calendar-radio {
    .ant-radio-checked {
      .ant-radio-inner {
        border-color: ${({ theme }) => theme.colors.darkBlue};

        &:after {
          background-color: ${({ theme }) => theme.colors.darkBlue};
        }
      }
    }

    .ant-radio-checked.ant-radio-disabled {
      .ant-radio-inner {
        border-color: ${({ theme }) => theme.colors.shades.grey[60]};

        &:after {
          background-color: ${({ theme }) => theme.colors.shades.grey[60]};
        }
      }
    }
  }

  .custom-calendar-schedule-modal-submit-btn {
    margin: 1rem 0 0 0;
  }

  .delete-activity-popover-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;

    .delete-activity-link-btn {
      font-family: var(----font-gilroy);
      color: ${({ theme }) => theme.colors.black};
      text-align: center;
      line-height: 1.3;
    }

    .delete-activity-delete-link-btn {
      color: ${({ theme }) => theme.colors.darkBlue};
    }

    .delete-activity-delete-all-link-btn {
      color: ${({ theme }) => theme.colors.shades.red[0]};
    }
  }

  .event-delete-pop-confirm-ok-btn {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.darkBlue};
  }

  .event-delete-pop-confirm-cancel-btn {
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.white};
  }

  .rbc-calendar {
    font-family: var(--font-glacial);

    /* TOOLBAR - START */
    .rbc-toolbar {
      .rbc-btn-group {
        button {
          border-color: ${({ theme }) => theme.colors.darkBlue};
          color: ${({ theme }) => theme.colors.darkBlue};
          transition: background ease 200ms;

          &.rbc-active {
            color: ${({ theme }) => theme.colors.white};
            background: ${({ theme }) => theme.colors.darkBlue};
          }

          &:hover, &:focus {
            &:not(.rbc-active) {
              background: ${({ theme }) => `${theme.colors.darkBlue}1F`};
            }
          }
        }
      }

      .rbc-toolbar-label {
        font-size: 1.2rem;
        text-transform: capitalize;
      }
    }
    /* TOOLBAR - END */

    /* BODY - START */
    .rbc-today {
      background: ${({ theme }) => `${theme.colors.shades.grey[40]}22`};
    }

    .rbc-month-view {
      min-height: 62vh;
    }

    .rbc-month-view, .rbc-time-view {
      border-radius: 0.5rem;
      border: none;
      box-shadow: var(--box-shadow);

      .rbc-event, .rbc-events-container > div {
        background: ${({ theme }) => theme.colors.darkBlue};
        border: none;
        border-radius: 0.5rem;
      }

      .new-event-container > div {
        background: ${({ theme }) => theme.colors.green};
      }

      .rbc-addons-dnd-dragged-event {
        opacity: 0.1 !important;
      }

      .rbc-header {
        font-size: 1rem;
        text-transform: uppercase;
        color: ${({ theme }) => theme.colors.shades.grey[32]};
        padding: 1rem 0;
      }
    }

    .rbc-time-view {
      .rbc-time-header {
        border-right: none;

        .rbc-time-header-content {
          .rbc-row.rbc-time-header-cell {
            min-height: auto;
          }

          .rbc-allday-cell {
            .rbc-row-content {
              .rbc-addons-dnd-row-body {
                .rbc-row {
                  min-height: unset;
                }
              }
            }
          }
        }
      }

      .rbc-time-content {
        max-height: 39vw;

        .rbc-time-gutter.rbc-time-column {
          .rbc-timeslot-group {
            justify-content: center;
            align-items: center;
            color: ${({ theme }) => theme.colors.shades.grey[15]};
            font-size: 0.9rem;
            font-weight: 400;
            border-bottom: none;

            .rbc-time-slot {
              flex: unset;
            }
          }
        }

        .rbc-day-slot.rbc-time-column {
          div {
            .rbc-events-container {
              margin: 0 0.1rem;

              .rbc-event, .rbc-addons-dnd-resizable {
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;
              }

              .rbc-event-label {
                text-align: center;
                font-family: var(--font-gacial);
                font-size: 0.75rem;
                font-weight: 100;
              }

              .rbc-event-content {
                text-align: center;
                text-transform: uppercase;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                height: fit-content;
                flex: unset;
                font-family: var(--font-gacial);
                font-size: 0.875rem;
                font-weight: 800;
              }
            }
          }
        }

        .rbc-day-slot.rbc-time-column {
          .rbc-timeslot-group {
            border-bottom: none;

            .rbc-time-slot {
              border-top: none;
            }
          }

          .rbc-current-time-indicator {
            background-color: unset;
          }
        }
      }
    }
    /* BODY - END */
  }
  /* CALENDAR - END */
  .ant-modal-confirm-btns {
    & > .ant-btn {
      border-radius: 0.25rem;

      &.ant-btn-primary {
        border-color: ${({ theme }) => theme.colors.darkBlue};
        background: ${({ theme }) => theme.colors.darkBlue};
      }

      &:hover,
      &:focus {
        &:not(.ant-btn-primary) {
          border-color: ${({ theme }) => theme.colors.darkBlue};
          color: ${({ theme }) => theme.colors.darkBlue};
        }
      }
    }
  }

  .booking-status-pill {
    padding: 0.25rem 1rem;
    border-radius: 2rem;
    white-space: nowrap;
  }

  .booking-status-created {
    color: inherit;
    background: ${({ theme }) => theme.colors.shades.grey[60]}
  }

  .booking-status-paid {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.green};
  }

  .booking-status-confirmed {
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.lightGreen};
  }

  .booking-status-refused {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.red}
  }

  .booking-status-payment_failed {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.shades.red[8]}
  }

  .booking-status-abandonned {
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.salmon}
  }

  .booking-status-waiting_for_confirmation {
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.lightBlue}
  }

  .booking-status-validated {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.brightBlue}
  }

  .booking-status-cancelled {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.lightPurple}
  }

  .show-table-full {
    /* height: calc(100vh - 10rem); */
    transition: all ease 300ms;
  }

  .show-table-half-screen {
    max-height: 20rem;
    overflow: auto;
    transition: all ease 300ms;
  }
`;

export default GlobalStyle;

import React from "react";
import { Banner } from "components";
import { GrantParameterIcon, TokenIcon } from "components/icons";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const SubventionBanner = styled(({ activeMenu, ...rest }) => {
  const { t } = useTranslation(["common", "grant"]);

  return (
    <div {...rest}>
      <Banner />
      <div className="grey-background">
        <div className="params d-flex flex-row">
          <div
            className={`subvention-menu ${
              activeMenu === 1 ? "active" : "inactive"
            }`}
          >
            <a href="/mes-subventions">
              <div className="subvention-wrapper">
                <div className="rounded">
                  <div className="small-circle">
                    <GrantParameterIcon />
                  </div>
                </div>
                <h3>{t`grant:parameters.title`}</h3>
              </div>
            </a>
          </div>
          <div
            className={`subvention-menu ${
              activeMenu === 2 ? "active" : "inactive"
            }`}
          >
            <a href="/generer-des-tokens">
              <div className="subvention-wrapper">
                <div className="rounded">
                  <div className="small-circle">
                    <TokenIcon fill={activeMenu === 2 ? "white" : null} />
                  </div>
                </div>
                <h3>{t`grant:tokens`}</h3>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
})`
  .grey-background {
    border-top: ${({ theme }) => `0.375rem solid ${theme.colors.darkBlue}`};
    margin-bottom: 20px;
  }

  .params {
    position: relative;
    top: -55px;
    justify-content: space-evenly;
    width: 100%;
    text-align: center;
  }

  .subvention-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
      margin-top: 30px;
    }
  }

  .subvention-menu {
    .rounded {
      height: 100px;
      width: 100px;
      border-radius: 50px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: var(--box-shadow);
    }

    .small-circle {
      height: 85px;
      width: 85px;
      border-radius: 50px;
      border: ${({ theme }) => `1px solid ${theme.colors.darkBlue}`};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.active {
      .small-circle {
        border: 1px solid rgb(207, 200, 200);
      }
      .rounded {
        background-color: ${({ theme }) => theme.colors.darkBlue};
      }

      h3 {
        color: ${({ theme }) => theme.colors.darkBlue};
      }
    }
    h3 {
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`;

import { get, patch, post } from "./axios-service";

export const changeAdminPassword = (payload) => {
  return post(`partner/change-password`, payload);
};

export const resetPassword = (payload) => {
  return post(`partner/reset-password`, payload);
};

export const forgetPassword = (payload) => {
  return post(`partner/forget-password`, payload);
};

export const getAdminActivities = (queryString) => {
  return get(`partner-gift-tokens/admin-activities?filter=${queryString}`);
};

export const getExportAdminActivities = () => {
  return get("partner-gift-tokens/admin-activities/export");
};

import { createGlobalStyle } from "styled-components";

const TransactionStyle = createGlobalStyle`
.transaction-main-container {
  .transaction-card {
    box-shadow: var(--box-shadow);

    h1 {
      font-family: var(--font-glacial);
      font-size: 1.25rem;
      color: ${({ theme }) => theme.colors.shades.grey[18]};
      font-weight: 600;
    }
  }

  .transaction-stat-1-container {
    .ant-card-body {
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
  }

  .transaction-status-tag {
    border-radius: 1.125rem;
    padding: 0.5rem 1rem;
    width: 6rem;
    text-align: center;

    .ant-tag {
      margin: 0;
    }
  }

  .transaction-actions-container {
    margin: 0 0 0.5rem 0;

    .transaction-right-col {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: end;

      .search-field-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;

        p {
          font-size: 0.75rem;
          font-family: var(--font-glacial);
          font-weight: 400;
          color: ${({ theme }) => theme.colors.shades.grey[25]};
        }
      }

      button {
        border-radius: 0%.5rem;

        span {
          font-family: var(--font-glacial);
          color: ${({ theme }) => theme.colors.shades.grey[25]};
        }
      }
    }

    

    .filter-dropdown {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.shades.grey[25]};
      font-family: var(--font-glacial);
      font-weight: 200;
      font-size: 0.75rem;
    }
  }

  .transaction-stats-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
    margin: 0 0 0.5rem 0;
    
    h4 {
      margin: 0;
    }
  }

  .transaction-graph-card1-header {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .transaction-division-stats {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 0.5rem;
  }
}

.ant-popover .ant-popover-content .ant-popover-inner {
  padding: 1rem 0.5rem;

  .transaction-pop-over-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label {
      font-family: var(--font-glacial);
      font-size: 1.125rem;
      color: ${({ theme }) => theme.colors.darkBlue};
      font-weight: 600;
    }

    p {
      font-family: var(--font-glacial);
      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.shades.grey[35]};
    }
  }

  .transaction-pop-over-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      label {
        font-family: var(--font-glacial);
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors.darkBlue};
        font-weight: 600;
      }

      p {
        font-family: var(--font-glacial);
        font-size: 0.875rem;
        color: ${({ theme }) => theme.colors.shades.grey[35]};
      }
    }
  }
}

.filter-container {
  padding: 1rem;
  background: none;
  backdrop-filter: blur(30px);

  .filter-menu-title {
    color: ${({ theme }) => theme.colors.shades.grey[2]};
    font-family: var(--font-glacial);
    font-weight: 200;
    font-size: 0.9375rem;
  }

  .MuiButtonBase-root.filter-menu-item {
    border: ${({ theme }) => `0.05rem solid ${theme.colors.darkBlue}`};
    border-radius: 0.5rem;
    margin: 0.1rem 0;
    height: 2.75rem;
    background-color: ${({ theme }) => `${theme.colors.white}80`};
  }
}

.transaction-graph-card1-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.transaction-align-headers {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ant-btn-icon-only.ant-btn-sm {
  width: 28px;
  height: 28px;
}

.transaction-graph-card2-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

`;

export default TransactionStyle;

import { get } from "./axios-service";

export const getPartnerTransactions = (queryString) => {
  return get(`partner/transactions?filter=${queryString}`);
};

export const getPartnerTransactionsExport = () => {
  return get(`partner/transactions/export`);
};

export const getPartnerTransactionKpiByCity = () => {
  return get(`partners/kpi/sales-history-by-city`);
};

export const getPartnerTransactionKpiByCategory = () => {
  return get(`partners/kpi/sales-by-main-category`);
};

export const getPartnerTransactionTrendKpi = () => {
  return get("partners/kpi/sales-history-trend");
};

/* eslint-disable no-console */
import { Card, Col, message, Row } from "antd";
import { DownloadIconButton, KpiProgress, KpiToggle } from "components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPartnerTransactionKpiByCity } from "services/transaction";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import TransactionStyle from "styles/transactionStyle";

export const CityKpiProgress = ({ displayExport }) => {
  const { t } = useTranslation(["common", "transaction"]);
  const [cityFilterChoice, setCityFilterChoice] = useState("today");
  const [transactionCityKpis, setTransactionCityKpis] = useState(null);
  const [currentTransactionCityKpis, setCurrentTransactionCityKpis] =
    useState(null);

  useEffect(() => {
    retrieveTransactionByCityKpis();
  }, []);

  const PROGRESS_BAR_COLORS = [
    "#74D2E7",
    "#FFC764",
    "#64A882",
    "#335C84",
    "#282eff",
    "#707070",
    "#ffc764",
    "#f8b7a2",
    "#64a882",
    "#26a6b8",
    "#335c84",
    "#ff0268",
    "#d6532c",
    "#02dcff",
  ];

  const handleCityFilterChoice = (event) => {
    setCityFilterChoice(event.target.value);
    handleActiveTransactionByCityKpi(event.target.value);
  };

  const handleActiveTransactionByCityKpi = (toggleValue) => {
    if (toggleValue === "month") {
      setCurrentTransactionCityKpis(transactionCityKpis?.month);
    } else if (toggleValue === "year") {
      setCurrentTransactionCityKpis(transactionCityKpis?.year);
    } else if (toggleValue === "week") {
      setCurrentTransactionCityKpis(transactionCityKpis?.week);
    } else {
      setCurrentTransactionCityKpis(transactionCityKpis?.today);
    }
  };

  const retrieveTransactionByCityKpis = () => {
    getPartnerTransactionKpiByCity()
      .then((response) => {
        setTransactionCityKpis(response);
        if (cityFilterChoice === "today") {
          setCurrentTransactionCityKpis(response?.today);
        }
      })
      .catch((e) => {
        console.log("e :>> ", e);
      });
  };

  const renderActiveUsersKpiProgressBar = (ad, index) => {
    const progressColor =
      PROGRESS_BAR_COLORS[index % PROGRESS_BAR_COLORS.length];

    return (
      <Col
        key={`cat-ad-${index}`}
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
      >
        <KpiProgress
          ad={ad}
          progressColor={progressColor}
          city={ad.city}
          recordCount={ad.transactionCount}
          textLabel={t`transaction:stats.labelProgress`}
        />
      </Col>
    );
  };

  const renderActiveUsersByCity = () => (
    <Row gutter={[16, 16]}>
      {currentTransactionCityKpis?.map(renderActiveUsersKpiProgressBar)}
    </Row>
  );

  const handleTransactionByCityKpiDownload = () => {
    if (currentTransactionCityKpis.length > 0) {
      exportToCSV(currentTransactionCityKpis, "export_transaction_region_kpi");
      message.success(t`transaction:success.export`, 5);
    } else message.error(t`transaction:error.noData`, 5);
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <>
      <TransactionStyle />
      <Card className="transaction-card">
        <Row gutter={[8, 8]}>
          <Col xs={24}>
            <div className="transaction-stats-header">
              {displayExport && (
                <DownloadIconButton
                  onClick={handleTransactionByCityKpiDownload}
                />
              )}
              <h4>{t`transaction:stats.location`}</h4>
            </div>
          </Col>
          <Col xs={24}>
            <div>
              <KpiToggle
                opts={["today", "week", "month", "year"]}
                value={cityFilterChoice}
                handleChange={handleCityFilterChoice}
                color="turquoise"
                hasToday={true}
              />
            </div>
          </Col>
          <Col xs={24}>{renderActiveUsersByCity()}</Col>
        </Row>
      </Card>
    </>
  );
};

export default CityKpiProgress;

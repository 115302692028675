import * as React from "react";

function SvgPdfIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56.53"
      height="68.451"
      viewBox="0 0 56.53 68.451"
    >
      <g id="Group_2714" data-name="Group 2714" transform="translate(-0.001 0)">
        <g
          id="Group_1646"
          data-name="Group 1646"
          transform="translate(17.844 51.572)"
        >
          <path
            id="Path_573"
            data-name="Path 573"
            d="M36.4,67.654a.885.885,0,0,1,.954-.954h2.422a2.825,2.825,0,0,1,3.009,2.936,2.735,2.735,0,0,1-2.936,2.936H38.381v1.835a.946.946,0,0,1-.954,1.027.909.909,0,0,1-.954-1.027V67.654Zm1.981,3.156h1.027a1.244,1.244,0,0,0,1.321-1.174c0-.807-.514-1.174-1.321-1.174H38.381Z"
            transform="translate(-36.4 -66.7)"
            fill="#707070"
          />
          <path
            id="Path_574"
            data-name="Path 574"
            d="M46.5,67.654a.885.885,0,0,1,.954-.954H49.8a4.027,4.027,0,0,1,4.036,4.257c0,2.862-1.908,4.4-4.036,4.4H47.454a.925.925,0,0,1-.954-.954Zm1.981,5.871h1.027c1.541,0,2.275-1.027,2.275-2.569,0-1.468-.734-2.422-2.2-2.422h-1.1Z"
            transform="translate(-39.088 -66.7)"
            fill="#707070"
          />
          <path
            id="Path_575"
            data-name="Path 575"
            d="M58.1,67.654a.885.885,0,0,1,.954-.954h3.009a.869.869,0,0,1,.954.881c0,.587-.367.881-.954.881H60.008V70h1.761a.869.869,0,0,1,.954.881c0,.587-.367.881-.954.881H60.008v2.5a.946.946,0,0,1-.954,1.027.909.909,0,0,1-.954-1.027Z"
            transform="translate(-42.175 -66.7)"
            fill="#707070"
          />
        </g>
        <g id="Group_2713" data-name="Group 2713">
          <path
            id="Path_572"
            data-name="Path 572"
            d="M36.508,55.492a2.47,2.47,0,0,1-1.981-1.065,3.174,3.174,0,0,1-.073-4.014,9.276,9.276,0,0,1,4.256-2.785,20.182,20.182,0,0,1,2.348-.737,35.4,35.4,0,0,0,1.468-3.441c.44-1.147.807-2.212,1.1-3.277-1.174-2.212-2.275-4.834-2.2-6.882a2.924,2.924,0,0,1,2.5-3.2h0a2.539,2.539,0,0,1,2.862,2.048c.587,1.966,0,5-.66,7.619a26,26,0,0,0,2.055,3.2c.66.9,1.248,1.72,1.908,2.54,2.569,0,4.99.246,6.458,1.147a3.021,3.021,0,0,1,1.468,3.523,2.648,2.648,0,0,1-3.082,2.212c-1.908-.246-4.11-2.294-5.8-4.26a33.34,33.34,0,0,0-3.816.41,28.444,28.444,0,0,0-2.789.573,16.2,16.2,0,0,1-.954,1.72A13.423,13.423,0,0,1,38.049,55,2.847,2.847,0,0,1,36.508,55.492Zm2.862-5.407a7.787,7.787,0,0,0-3.229,1.966c-.22.246-.22.41.073.819.147.164.22.328.807,0A10.487,10.487,0,0,0,39.37,50.085ZM52.433,48.2A5.78,5.78,0,0,0,55.222,50c.294.082.44.082.66-.492.073-.164.073-.246-.367-.573A8.439,8.439,0,0,0,52.433,48.2Zm-7.192-5.325c-.22.655-.44,1.229-.587,1.638-.22.655-.514,1.229-.734,1.8a5.11,5.11,0,0,1,1.1-.164,20.107,20.107,0,0,1,2.2-.246c-.367-.492-.66-.9-.807-1.147C46.122,44.268,45.682,43.613,45.241,42.875ZM44.214,32.635c-.294.082-.514.082-.514.737a9.377,9.377,0,0,0,.881,3.359,9.266,9.266,0,0,0,.147-3.687c-.147-.41-.22-.41-.514-.41Z"
            transform="translate(-17.857 -14.141)"
            fill="#eb1616"
            stroke="#fff"
            strokeWidth="1"
          />
          <path
            id="Path_576"
            data-name="Path 576"
            d="M77.106,28.464a1.459,1.459,0,0,0-1.522,1.3V73.473a2.956,2.956,0,0,1-3.146,2.678H28.391a2.956,2.956,0,0,1-3.146-2.678V15.765a2.956,2.956,0,0,1,3.146-2.678H60.462v8.034c0,2.592,2.537,4.751,5.582,4.751H77a1.625,1.625,0,0,0,1.421-.778,1.365,1.365,0,0,0-.3-1.469L63.1,10.841a1.557,1.557,0,0,0-1.319-.346H28.492c-3.451,0-6.292,2.419-6.292,5.356V73.559c0,2.937,2.842,5.356,6.292,5.356H72.438c3.451,0,6.292-2.419,6.292-5.356V29.76A1.565,1.565,0,0,0,77.106,28.464Zm-11.062-5.27a2.348,2.348,0,0,1-2.537-2.16v-6.22l9.845,8.38Z"
            transform="translate(-22.199 -10.464)"
            fill="#eb1616"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgPdfIcon;

import { useEffect, useState } from "react";
import { Row, Col, Card, Table, message } from "antd";
import MainLayout from "layouts/main";
import { useTranslation } from "react-i18next";
import { SubventionBanner, TokenHistory } from "components";
import CreateToken from "components/tokens/create";
import { TextButton } from "components";
import {
  exportPartnerTokenUseHistory,
  partnerTokenUseHistory,
} from "services/token";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Subventions = () => {
  const { t } = useTranslation(["common", "token"]);
  const [tokenHistoryList, setTokenHistoryList] = useState([]);
  const [recordCount, setRecordCount] = useState(0);

  const columns = [
    {
      key: "id",
      dataIndex: "id",
      render: (key, record) => {
        return <TokenHistory key={key} token={record} />;
      },
    },
  ];

  useEffect(() => {
    async function fetchTokenHistory() {
      const response = await partnerTokenUseHistory();
      const users = response.tokenHistory;
      const usersWithKey = users.map((e, i) => ({ ...e, key: i }));
      setTokenHistoryList(usersWithKey);
      setRecordCount(response.recordCount);
    }
    fetchTokenHistory();
  }, []);

  const handleDownload = () => {
    exportPartnerTokenUseHistory()
      .then((resp) => {
        if (resp) {
          exportToCSV(resp, "export_token_utiliser");
          message.success(t`token:message.success.export`, 5);
        } else {
          message.error(t`token:message.error.noData`, 5);
        }
      })
      .catch(() => {
        message.error(t`token:message.error.export`, 5);
      });
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <MainLayout>
      <SubventionBanner activeMenu={2} />
      <Row gutter={[8, 8]}>
        <Col xs={24} md={8} lg={8}>
          <CreateToken showCalendar={false} />
        </Col>
        <Col xs={24} md={16} lg={16}>
          <Card>
            <Row gutter={[8, 8]}>
              <Col xs={24} md={24} lg={24}>
                <div
                  className={
                    "d-flex justify-content-between align-items-center token-history-list-header-spacing"
                  }
                >
                  <p className="token-history-list-header">{t`token:tokenHistory.title`}</p>
                  <TextButton
                    rounded={true}
                    variant="darkBlue"
                    width="12rem"
                    onClick={handleDownload}
                  >
                    {t`token:tokenHistory.excelExportBtn`}
                  </TextButton>
                </div>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <Table
                  showHeader={false}
                  columns={columns}
                  dataSource={tokenHistoryList}
                  pagination={{
                    pageSize: 10,
                    defaultPageSize: 10,
                    total: recordCount,
                    showSizeChanger: false,
                  }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default Subventions;

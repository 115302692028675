import { createGlobalStyle } from "styled-components";

const UserStyle = createGlobalStyle`
.user-title {
  margin-bottom: 0;
  font-family: var(--font-glacial);
  font-weight: 600;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.shades.grey[18]};
}

.user-card {
  box-shadow: var(--box-shadow);
}

.search-text-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  .search-field-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.1rem;

    .search-text {
      margin-bottom: 0;
      font-family: var(--font-glacial);
      font-weight: 200;
      font-size: 0.75rem;
      color: ${({ theme }) => theme.colors.shades.grey[25]};
    }
  }
}

.filter-dropdown {
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.shades.grey[25]};
  font-family: var(--font-glacial);
  font-weight: 200;
  font-size: 0.75rem;
}

.filter-container {
  padding: 1rem;
  background: none;
  backdrop-filter: blur(30px);

  .filter-menu-title {
    color: ${({ theme }) => theme.colors.shades.grey[2]};
    font-family: var(--font-glacial);
    font-weight: 200;
    font-size: 0.9375rem;
  }
}

.MuiButtonBase-root.filter-menu-item {
  border: ${({ theme }) => `0.05rem solid ${theme.colors.darkBlue}`};
  border-radius: 0.5rem;
  margin: 0.1rem 0;
  height: 2.75rem;
  background-color: ${({ theme }) => `${theme.colors.white}80`};
}

.ant-dropdown-trigger > .anticon.anticon-down, .ant-dropdown-link > .anticon.anticon-down, .ant-dropdown-button > .anticon.anticon-down {
  font-size: 12px;
  vertical-align: sub;
}

.users-table-full {
  /* height: calc(100vh - 10rem); */
  transition: all ease 300ms;
}

.users-table-half-screen {
  max-height: 18.3125rem;
  overflow: auto;
  transition: all ease 300ms;
}

.full-height {
  height: 100%;
}

.user-card-table-container {
  padding: 0 1rem;
}

.user-latest-ads-activity-title {
  color: inherit;
  text-decoration: underline;
}

.user-latest-ads-online {
  padding: 0.25rem 1rem;
  border-radius: 2rem;
  white-space: nowrap;

  &.user-latest-ads-online-true {
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.green}
  }

  &.user-latest-ads-online-false {
    color: inherit;
    background: ${({ theme }) => theme.colors.shades.grey[60]}
  }
}

.users-others-form {
  width: 100%;

  .user-account-btns-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin: 0 0 2rem 0;
    padding: 0 1rem;
  }

  .next-level-content-container {
    .next-level-btn-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 0 1rem 1rem 1rem;
    }

    & > .ant-card {
      margin: 0;
    }
  }

  .user-docs-container {
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    & > .user-doc-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      background: transparent;
      border: none;
      padding: 0.5rem;
      transition: all ease 300ms;

      &:hover,
      &:focus {
        background: ${({ theme }) => theme.colors.shades.grey[70]}
      }

      & > .user-doc-icon {
        font-size: 5rem;
        color: ${({ theme }) => theme.colors.shades.grey[35]};
      }

      & > .user-doc-created-at {
        font-family: var(--font-glacial);
        font-weight: 400;
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.shades.grey[32]};
        text-align: center;
      }
    }

    & > .no-doc {
      font-family: var(--font-glacial);
      font-weight: 400;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.shades.grey[50]};
      text-align: center;
    }
  }

  .user-settings-form-fields-container {
    padding: 0 1rem 1rem 1rem;
  }
}
`;

export default UserStyle;

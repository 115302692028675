export const CURRENT_USER_COOKIE = "loggedInUser";

export const TRANSACTION_STATUS = {
  WAITING_FOR_CONFIRMATION: "WAITING_FOR_CONFIRMATION",
  CONFIRMED: "CONFIRMED",
  REFUSED: "REFUSED",
  REFUND: "REFUND",
  PAYMENT_FAILED: "PAYMENT_FAILED",
  ABANDONNED: "ABANDONNED",
  PAID: "PAID",
  CREATED: "CREATED",
};

export const FILE_TYPES = {
  PDF: "PDF",
  EXCEL: "EXCEL",
};

export const FILE_STATUSES = {
  REMOVED: "removed",
};

import * as React from "react";

function SvgSubventionIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.3"
      height="28.397"
      viewBox="0 0 30.3 28.397"
    >
      <g
        id="Group_1828"
        data-name="Group 1828"
        transform="translate(0.15 0.247)"
      >
        <g id="Group_1447" data-name="Group 1447" transform="translate(0 0)">
          <path
            id="Path_462"
            data-name="Path 462"
            d="M31.256,29.5a1.719,1.719,0,0,0,1.569-1.626V14.17a2.364,2.364,0,0,0-.9-1.817L27.94,9.118V5.33A1.457,1.457,0,0,0,26.41,3.964H21.593a.483.483,0,0,0-.054-.04L19.11,1.954a2.134,2.134,0,0,0-2.564,0l-2.479,2.01H9.241A1.457,1.457,0,0,0,7.71,5.33V9.122L3.722,12.354a2.363,2.363,0,0,0-.9,1.816v13.7A1.72,1.72,0,0,0,4.407,29.5a.973.973,0,0,0,.167.024H31.08A.977.977,0,0,0,31.256,29.5ZM17.2,2.6a1.043,1.043,0,0,1,1.252,0L20,3.854H15.659ZM3.8,27.875V14.17c0-.018.005-.035.006-.052l11.106,6.929L4.05,28.074a.847.847,0,0,0-.157.14A.7.7,0,0,1,3.8,27.875Zm28.044-13.7v13.7a.7.7,0,0,1-.089.336.85.85,0,0,0-.154-.137L20.74,21.047l11.1-6.926C31.842,14.138,31.847,14.154,31.847,14.17ZM4.83,28.65l10.935-7.073c2.711,1.686,1.54,1.472,4.125,0L30.826,28.65h-26ZM31.267,13a1.674,1.674,0,0,1,.251.255l-3.456,2.155V10.4ZM9.241,4.621h4.833a.517.517,0,0,0,.316.109h6.885a.515.515,0,0,0,.315-.109h4.82a.758.758,0,0,1,.8.709v3.8a.409.409,0,0,0-.122.283c-.1,6.451.226,6.461-.286,6.788-8.523,5.127-8.615,5.783-9.342,5.364-9.165-5.717-8.891-5.375-8.891-5.86V9.415a.41.41,0,0,0-.122-.283V5.33A.758.758,0,0,1,9.241,4.621ZM7.588,10.4v5.006L4.134,13.253A1.663,1.663,0,0,1,4.381,13Z"
            transform="translate(-2.825 -1.526)"
            fill="#b0b0b0"
            stroke="#b0b0b0"
            strokeWidth="0.3"
          />
        </g>
        <g
          id="Group_1459"
          data-name="Group 1459"
          transform="translate(8.047 5.134)"
        >
          <g
            id="Group_1449"
            data-name="Group 1449"
            transform="translate(7.321 1.219)"
          >
            <path
              id="Path_464"
              data-name="Path 464"
              d="M36.264,16.324h3.605a.269.269,0,0,0,0-.538H36.264a.269.269,0,0,0,0,.538Z"
              transform="translate(-35.995 -15.786)"
              fill="#b0b0b0"
            />
          </g>
          <g
            id="Group_1450"
            data-name="Group 1450"
            transform="translate(5.977 1.219)"
          >
            <path
              id="Path_465"
              data-name="Path 465"
              d="M32.514,16.324h.575a.269.269,0,1,0,0-.538h-.575a.269.269,0,0,0,0,.538Z"
              transform="translate(-32.245 -15.786)"
              fill="#b0b0b0"
            />
          </g>
          <g
            id="Group_1451"
            data-name="Group 1451"
            transform="translate(10.042 2.837)"
          >
            <path
              id="Path_466"
              data-name="Path 466"
              d="M44.734,20.3H43.85a.269.269,0,0,0,0,.538h.884a.269.269,0,1,0,0-.538Z"
              transform="translate(-43.581 -20.299)"
              fill="#b0b0b0"
            />
          </g>
          <g
            id="Group_1452"
            data-name="Group 1452"
            transform="translate(5.977 2.837)"
          >
            <path
              id="Path_467"
              data-name="Path 467"
              d="M32.514,20.837H35.7a.269.269,0,0,0,0-.538H32.514a.269.269,0,0,0,0,.538Z"
              transform="translate(-32.245 -20.299)"
              fill="#b0b0b0"
            />
          </g>
          <g
            id="Group_1453"
            data-name="Group 1453"
            transform="translate(8.64 4.456)"
          >
            <path
              id="Path_468"
              data-name="Path 468"
              d="M42.227,24.811H39.941a.269.269,0,0,0,0,.538h2.287a.269.269,0,1,0,0-.538Z"
              transform="translate(-39.672 -24.811)"
              fill="#b0b0b0"
            />
          </g>
          <g
            id="Group_1454"
            data-name="Group 1454"
            transform="translate(5.977 4.456)"
          >
            <path
              id="Path_469"
              data-name="Path 469"
              d="M32.514,25.349H34.2a.269.269,0,1,0,0-.538H32.514a.269.269,0,0,0,0,.538Z"
              transform="translate(-32.245 -24.811)"
              fill="#b0b0b0"
            />
          </g>
          <g
            id="Group_1455"
            data-name="Group 1455"
            transform="translate(5.977 6.074)"
          >
            <path
              id="Path_470"
              data-name="Path 470"
              d="M32.514,29.861h2.832a.269.269,0,1,0,0-.538H32.514a.269.269,0,0,0,0,.538Z"
              transform="translate(-32.245 -29.323)"
              fill="#b0b0b0"
            />
          </g>
          <text
            id="_"
            data-name="€"
            transform="translate(2 6)"
            fill="#b0b0b0"
            fontSize="6"
            fontFamily="Helvetica"
          >
            <tspan x="-2.232" y="0">
              €
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default SvgSubventionIcon;

import { Line } from "react-chartjs-2";

const LineChart = ({
  xSeries = [],
  ySeries = [],
  title,
  zSeries = null,
  seriesOneLabel = "",
  seriesTwoLabel = "",
}) => {
  const data = {
    labels: xSeries,
    datasets: [
      {
        label: seriesOneLabel,
        lineTension: 0.6,
        data: ySeries,
        fill: true,
        backgroundColor: "rgb(116, 210, 231, 0.5)",
        borderColor: "rgba(116, 210, 231, 1)",
      },
      {
        label: seriesTwoLabel,
        lineTension: 0.3,
        data: zSeries,
        fill: false,
        // backgroundColor: "rgb(0, 25, 255, 0.5)",
        borderColor: "#742774",
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <>
      <Line data={data} options={options} />
      <p
        className="text-center"
        style={{
          textAlign: "center",
          margin: 10,
        }}
      >
        <u>{title}</u>
      </p>
    </>
  );
};

export default LineChart;

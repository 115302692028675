/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Modal,
  Select,
  Button,
  message,
  Slider,
  Divider,
  Row,
  Col,
  Spin,
  Radio,
} from "antd";
import MainLayout from "layouts/main";
import { Trans, useTranslation } from "react-i18next";
import { SubventionBanner, TextButton } from "components";
import SubventionStyle from "styles/SubventionStyles";
import * as yup from "yup";
import { Formik } from "formik";
import FormItem from "antd/lib/form/FormItem";
import { InputAdornment, TextField } from "@material-ui/core";
import { getDatabasesDocuments } from "services/document";
import { CaretDownOutlined, UploadOutlined } from "@ant-design/icons";
import { FileUploader } from "components/file-uploader";
import { GreenTickIcon } from "components/icons";
import {
  createPartnerFundings,
  getCategoriesList,
  getPartners,
  readDocumentContent,
  uploadDocument,
} from "services/subvention";
import { localizeCurrency } from "components/utils";
import Decimal from "decimal.js";
import { FILE_STATUSES } from "../../constants";

const { Option } = Select;
const MAX_FILE_SIZE = 5;

const Subventions = () => {
  const { t, i18n } = useTranslation(["common", "grant"]);
  const [showModal, setShowModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [listFiles, setListFiles] = useState(null);
  const [categories, setCategories] = useState(null);
  const [creditBalance, setCreditBalance] = useState(new Decimal("0"));
  const [listEmails, setListEmails] = useState(null);
  const quantity = useRef(new Decimal("0"));
  const selectedFile = useRef(null);
  const fileRef = useRef(null);
  const blobRef = useRef(null);
  const amountRef = useRef(null);
  const totalAmountRef = useRef(null);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const AMOUNTS = [
    {
      key: 20,
      label: "20 €/pers",
    },
    {
      key: 40,
      label: "40 €/pers",
    },
    {
      key: 60,
      label: "60 €/pers",
    },
    {
      key: 80,
      label: "80 €/pers",
    },
    {
      key: 100,
      label: "100 €/pers",
    },
  ];
  const marks = {
    0: "0",
    100: "100%",
  };
  const initialValues = {
    caseName: "",
    totalAmount: "",
    amount: "",
    fileName: "",
    customAmount: 0,
    groupName: "",
    uploadedFile: "",
    totalBeneficiaries: "",
    beneficiaryType: 1,
    categories: categories,
  };
  const validationSchema = yup.object().shape({
    caseName: yup.string().required(t`common:errors.fieldIsRequired`),
    totalAmount: yup.string(),
    amount: yup.string(),
    fileName: yup.string(),
    uploadedFile: yup.string(),
    customAmount: yup.number().when("amount", {
      is: (amount) => amount === "other",
      then: yup
        .number()
        .min(21, t("common:errors.minNumber", { count: 21 }))
        .typeError(t`common:errors.numberOnly`)
        .required(t`common:errors.fieldIsRequired`)
        .integer(),
    }),
    groupName: yup.string().when("uploadedFile", {
      is: (uploadedFile) => !!uploadedFile,
      then: yup.string().required(t`common:errors.fieldIsRequired`),
    }),
    totalBeneficiaries: yup.string(),
  });

  useEffect(() => {
    setIsLoading(true);
    Promise.allSettled([
      getCategoriesList()
        .then((resp) => {
          console.log("CATEGORIES resp :>> ", resp);
          const newCat = resp?.reduce((acc, item) => {
            acc[item.id] = { ...item, amount: 0 };
            return acc;
          }, {});
          setCategories(newCat);
        })
        .catch((e) => {
          console.log("e :>> ", e);
        }),
      getPartners()
        .then((resp) => {
          console.log("PARTNERS resp :>> ", resp);
          setCreditBalance(new Decimal(resp?.creditBalance || "0"));
        })
        .catch((e) => {
          console.log("e :>> ", e);
        }),
    ]).finally(() => setIsLoading(false));
  }, []);

  const handleSubmitSubvention = (values, { setSubmitting, resetForm }) => {
    let iscat = false;
    Object.values(categories).forEach((s: any) => {
      if (s.amount > 0) {
        iscat = true;
      } else {
        //do nothing
      }
    });

    if (amountRef?.current?.toNumber() === 0) {
      message.error(t`grant:error.selectAmount`, 5);
      setSubmitting(false);
    } else if (values.totalBeneficiaries === null && listEmails === null) {
      message.error(t`grant:error.benefCount`, 5);
      setSubmitting(false);
    } else if (
      values.beneficiaryType === 1 &&
      (values.totalBeneficiaries === null ||
        Number(values.totalBeneficiaries) === 0)
    ) {
      message.error(t`grant:error.benefCount`, 5);
      setSubmitting(false);
    } else if (
      values.beneficiaryType === 2 &&
      (listEmails === null || listEmails.length === 0)
    ) {
      message.error(t`grant:error.file`, 5);
      setSubmitting(false);
    } else if (!iscat) {
      message.error(t`grant:error.selectCategories`, 5);
      setSubmitting(false);
    } else if (Number(creditBalance) < Number(totalAmountRef?.current)) {
      message.error(t`grant:error.notEnoughFund`, 5);
      setSubmitting(false);
    } else {
      setSubmitting(true);

      const payload = {
        fileId: selectedFile?.current?.id,
        groupName: values.caseName,
        amount: amountRef?.current?.toNumber(),
        emails: listEmails,
        categories: Object.values(categories),
        numberOfUsers: values.totalBeneficiaries,
      };
      console.log("payload :>> ", payload);
      createPartnerFundings(payload)
        .then((resp) => {
          console.log("resp :>> ", resp);
          setCreditBalance(new Decimal(resp?.newCreditBalance || "0"));
          message.success(t`grant:success.created`, 5);
          setIsLoading(true);
          const resetCat = Object.assign(
            {},
            ...Object.entries(categories)?.map(([key, value]: any) => ({
              [key]: {
                ...value,
                amount: 0,
              },
            }))
          );
          setCategories(resetCat);
          resetForm();
        })
        .catch((e) => {
          console.log("e :>> ", e);
          message.error(t`grant:error.created`, 5);
        })
        .finally(() => {
          setSubmitting(false);
          setIsLoading(false);
        });
    }
  };

  const handleShowList = () => {
    setShowModal(true);

    getDatabasesDocuments()
      .then((resp) => {
        console.log("resp :>> ", resp);
        setListFiles(resp);
      })
      .catch((e) => {
        console.log("e :>> ", e);
      });
  };

  const handleCancel = () => setIsVisible(false);

  const renderForm = () => (
    <Formik
      initialValues={initialValues}
      validateOnChange
      validationSchema={validationSchema}
      onSubmit={handleSubmitSubvention}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        isSubmitting,
        handleSubmit,
        dirty,
        isValid,
        setFieldTouched,
        setFieldValue,
      }) => {
        const retrieveErrors = (field) =>
          (touched[field] && errors[field]) || "";

        const handleChangeField = (e) => {
          e?.target?.name &&
            !touched[e.target.name] &&
            setFieldTouched(e.target.name);
          handleChange(e);
        };

        const handleChangeFieldByName = (name) => (value) => {
          setFieldTouched(name);

          if (name === "fileName") {
            selectedFile.current = listFiles.filter(
              (item) => item?.id === value
            )[0];
            setFieldValue(name, selectedFile?.current?.filename?.split(".")[0]);
          } else {
            setFieldValue(name, value);
          }
        };

        const handleOnSelect = (value) => {
          if (value === "other") {
            setIsVisible(true);
          }
        };

        const handleDocumentUpload = ({ blob, base64, file }) => {
          if (file?.status !== FILE_STATUSES.REMOVED) {
            setFieldValue("uploadedFile", base64);
            fileRef.current = file;
            blobRef.current = blob;
          }
        };

        const handleRemoveUploadedFile = (file) => {
          if (file) {
            setFieldValue("uploadedFile", "");
            fileRef.current = null;
            blobRef.current = null;
          }
        };

        const handleSubmitFile = (values) => () => {
          const requestBody = {
            type: selectedFile?.current?.fileType,
            path: selectedFile?.current?.url,
          };

          if (values.uploadedFile && values.groupName) {
            const fileName = fileRef?.current?.name;
            const fileType = fileRef?.current?.type;
            const headers = {
              "Content-Type": "multipart/form-data",
              "Mime-Type": fileType,
            };
            const payload = new FormData();
            payload.append("doc", blobRef?.current, fileName);

            const queryString = JSON.stringify({
              where: {
                groupName: values.groupName,
              },
            });

            uploadDocument(queryString, payload, headers)
              .then((resp) => {
                console.log("resp :>> ", resp);
                setFieldValue(
                  "fileName",
                  resp?.partnerDoc?.filename?.split(".")[0]
                );
                selectedFile.current = resp?.partnerDoc;
                requestBody.type = resp?.partnerDoc?.fileType;
                requestBody.path = resp?.partnerDoc?.url;
                getDocumentContent(requestBody);
                setShowModal(false);
                message.success(t`grant:success.uplaoded`);
              })
              .catch((e) => {
                console.log("##### CATCH e :>> ", e?.response?.data?.error);
                message.error(t`grant:success.uplaoded`, 5);
              });
          } else {
            getDocumentContent(requestBody);
          }
        };

        const getDocumentContent = (requestBody) => {
          readDocumentContent(requestBody)
            .then((resp) => {
              quantity.current = new Decimal(resp?.count || "0");
              setListEmails(resp?.emails);
              setShowModal(false);
            })
            .catch((e) => {
              console.log(
                "partner-gift-tokens::: e :>> ",
                e?.response?.data?.error?.message
              );
              if (e?.response?.data?.error?.message === "no_emails_supplied") {
                message.error(
                  "Le fichier ne contient pas des données dans le bon format."
                );
              } else if (e?.response?.data?.error?.message === "invalid_file") {
                message.error(t`grant:error.invalidFile`, 5);
              }
            });
        };

        const fundingAmount = useCallback(() => {
          let amt = new Decimal("0");

          if (values.amount === "other") {
            amt = new Decimal(values.customAmount || "0");
          } else {
            amt = new Decimal(values.amount || "0");
          }
          amountRef.current = amt;
          return amt;
        }, [values.amount, values.customAmount]);

        const totalAmount = useCallback(() => {
          let finalTotal = new Decimal(0);
          if (Number(values.totalBeneficiaries) > 0) {
            finalTotal = fundingAmount().mul(
              new Decimal(values.totalBeneficiaries)
            );
          } else finalTotal = fundingAmount().mul(quantity?.current);

          totalAmountRef.current = finalTotal;
          return finalTotal;
        }, [
          values.amount,
          fundingAmount(),
          quantity,
          values.totalBeneficiaries,
        ]);

        const handleOnChange = (categoryId) => (value) => {
          // const newSelectedcategories = { ...selectedCategories };
          // if (newSelectedcategories[categoryId]) {
          //   newSelectedcategories[categoryId] = {
          //     ...newSelectedcategories[categoryId],
          //     amount: value,
          //   };
          // } else {
          //   newSelectedcategories[categoryId] = {
          //     categoryId,
          //     amount: value,
          //   };
          // }
          // setSelectedCategories(newSelectedcategories);
          const newSelectedcategories = { ...categories };
          if (value >= 0) {
            newSelectedcategories[categoryId] = {
              ...newSelectedcategories[categoryId],
              amount: value,
            };
          }
          setCategories(newSelectedcategories);
        };

        return (
          <Form layout="vertical" onFinish={handleSubmit}>
            <FormItem label={t`grant:parameters.section1.name`}>
              <TextField
                name="caseName"
                InputLabelProps={{
                  shrink: true,
                }}
                value={values.caseName}
                onChange={handleChangeField}
                InputProps={{
                  endAdornment: values.caseName && (
                    <InputAdornment position="end">
                      <GreenTickIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </FormItem>
            <FormItem label={t`grant:parameters.section1.total`}>
              <Input
                name="totalAmount"
                value={localizeCurrency(
                  totalAmount().toNumber(),
                  i18n.language
                )}
                readOnly
                suffix="€"
              />
            </FormItem>
            <div>
              <Radio.Group
                name="beneficiaryType"
                onChange={handleChangeField}
                value={values.beneficiaryType}
              >
                <Radio value={1}>
                  {t("grant:parameters.section1.enterBenefAmount")}
                </Radio>
                <Radio value={2}>
                  {t("grant:parameters.section1.selectAFile")}
                </Radio>
              </Radio.Group>
              {values.beneficiaryType === 1 && (
                <FormItem
                  label={t("grant:parameters.section1.numberOfBeneficiary")}
                >
                  <Input
                    name="totalBeneficiaries"
                    type="number"
                    min={0}
                    max={9999999999}
                    placeholder={t(
                      "grant:parameters.section1.numberOfBeneficiary"
                    )}
                    style={{ width: "200px" }}
                    value={values.totalBeneficiaries}
                    onChange={handleChangeField}
                  />
                </FormItem>
              )}
              {values.beneficiaryType === 2 && (
                <FormItem
                  label={t("grant:parameters.section1.beneficiary", {
                    quantity: quantity?.current,
                  })}
                >
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                      "aria-readonly": true,
                    }}
                    value={values.fileName?.split(".")[0]}
                  />
                  <a
                    className="beneficiary-file-options"
                    onClick={handleShowList}
                  >{t`grant:customise`}</a>
                </FormItem>
              )}
            </div>
            <FormItem label={t`grant:parameters.section1.upto`}>
              <Select
                suffixIcon={<CaretDownOutlined />}
                onChange={handleChangeFieldByName("amount")}
                onSelect={handleOnSelect}
                value={values.amount}
              >
                {AMOUNTS?.map((item) => (
                  <Option key={`${item.key}`} value={item.key}>
                    {item?.label}
                  </Option>
                ))}
                <Option value="other">{t`grant:amountOther`}</Option>
              </Select>
            </FormItem>
            {/* <FormItem label={t`grant:parameters.section1.categories`}> */}
            <label className="category-label">{t`grant:parameters.section1.categories`}</label>
            {/* {categories?.map((item) => ( */}
            {values.categories &&
              Object.values(values.categories)?.map((item: any) => (
                <div key={item?.id} className="category-slider-container">
                  <label>{item?.name}</label>
                  <Slider
                    marks={marks}
                    step={1}
                    key={item?.id}
                    // value={item?.amount}
                    onAfterChange={handleOnChange(item?.id)}
                    tooltipVisible
                  />
                </div>
              ))}
            {/* </FormItem> */}
            <Modal
              visible={isVisible}
              title={t`grant:amountModal.title`}
              footer={null}
              closable={true}
              onCancel={handleCancel}
              centered
              maskClosable
              className="customise-amount-modal"
            >
              <section className="customise-amount-form">
                <div>
                  <label>{t`grant:amountModal.label`}</label>
                  <Select
                    placeholder={t`grant:amountModal.other`}
                    suffixIcon={<CaretDownOutlined />}
                    disabled
                  ></Select>
                </div>
                <FormItem
                  help={retrieveErrors("customAmount")}
                  validateStatus={retrieveErrors("customAmount") && "error"}
                >
                  <Input
                    name="customAmount"
                    min={20}
                    onChange={handleChangeField}
                    value={values.customAmount}
                    type="number"
                    suffix="€"
                  />
                </FormItem>
              </section>
              <div className="customise-amount-footer">
                <TextButton
                  rounded={true}
                  variant="darkBlue"
                  type="outlined"
                  width="11rem"
                  onClick={handleCancel}
                >{t`common:cancel`}</TextButton>
                <TextButton
                  rounded={true}
                  variant="darkBlue"
                  disabled={
                    !values.customAmount || retrieveErrors("customAmount")
                  }
                  width="11rem"
                  onClick={handleCancel}
                >{t`common:buttons.confirm`}</TextButton>
              </div>
            </Modal>
            <Modal
              title={t`grant:modal.title`}
              visible={showModal}
              centered
              maskClosable
              onCancel={() => setShowModal(false)}
              okText={t`common:buttons.save`}
              onOk={handleSubmitFile(values)}
              cancelText={t`common:cancel`}
              destroyOnClose={true}
              okButtonProps={{
                disabled:
                  (values.fileName &&
                    ((!values.groupName && !!values.uploadedFile) ||
                      (values.groupName && !values.uploadedFile))) ||
                  (!values.fileName &&
                    !values.groupName &&
                    !values.uploadedFile) ||
                  (!values.groupName && !!values.uploadedFile) ||
                  (values.fileName &&
                    (!!values.uploadedFile || !!values.groupName)),
              }}
              className="beneficiary-file-modal"
            >
              {values.fileName && (values.uploadedFile || values.groupName) && (
                <p className="file-upload-error-msg">{t`grant:modal.error`}</p>
              )}
              <FormItem
                label={t`grant:modal.selectFile`}
                help={retrieveErrors("fileName")}
                validateStatus={retrieveErrors("fileName") && "error"}
              >
                <Select
                  suffixIcon={<CaretDownOutlined />}
                  onChange={handleChangeFieldByName("fileName")}
                  value={values.fileName}
                >
                  <Option key="none" value="">
                    --{t`common:none`}--
                  </Option>
                  {listFiles?.map((item) => (
                    <Option key={`${item.id}`} value={item?.id}>
                      {item?.filename.split(".")[0]}
                    </Option>
                  ))}
                </Select>
              </FormItem>
              <Divider>{t`common:or`}</Divider>
              <div>
                <FormItem label={t`grant:modal.uploadFile`}>
                  <FileUploader
                    acceptedFileExts={[".xlsx", ".xls"]}
                    maxFileSize={MAX_FILE_SIZE * 1024 * 1024}
                    uploadFile={handleDocumentUpload}
                    maxCount={1}
                    listType="text"
                    showUploadList={true}
                    onRemove={handleRemoveUploadedFile}
                  >
                    <Button
                      icon={<UploadOutlined />}
                    >{t`common:upload`}</Button>
                  </FileUploader>
                </FormItem>
                <FormItem
                  label={t`grant:modal.groupName`}
                  help={retrieveErrors("groupName")}
                  validateStatus={retrieveErrors("groupName") && "error"}
                >
                  <Input
                    placeholder={t`grant:modal.groupName`}
                    onChange={handleChangeField}
                    value={values.groupName}
                    name="groupName"
                  />
                </FormItem>
                <div className="modal-file-upload-information">
                  <label>{t`common:databaseFilesCriteria.title`}</label>
                  <p>
                    <Trans
                      i18nKey="common:databaseFilesCriteria.content"
                      components={{
                        break: <br />,
                      }}
                    />
                  </p>
                </div>
              </div>
            </Modal>
            <TextButton
              variant="darkBlue"
              rounded
              htmlType="submit"
              disabled={!isValid || isSubmitting || !dirty}
            >{t`common:buttons.save`}</TextButton>
          </Form>
        );
      }}
    </Formik>
  );

  return (
    <Fragment>
      <SubventionStyle />
      <MainLayout className="subvention-main-container">
        <SubventionBanner activeMenu={1} />
        <Row className="subvention-parameters-container">
          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}> */}
          <Col className="subvention-parameters-form">
            <h2>{t`grant:parameters.section1.title`}</h2>
            {isLoading ? <Spin /> : renderForm()}
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}> */}
          <Col>
            <h2>
              {t("grant:parameters.section2.title", {
                amount: localizeCurrency(
                  creditBalance.toNumber(),
                  i18n.language
                ),
              })}
            </h2>
          </Col>
        </Row>
      </MainLayout>
    </Fragment>
  );
};

export default Subventions;

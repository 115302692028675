/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { useEffect, useState } from "react";
import MainLayout from "layouts/main";
import { useTranslation } from "react-i18next";
import TransactionStyle from "styles/transactionStyle";
import {
  Button,
  Card,
  Col,
  Dropdown,
  message,
  Popover,
  Radio,
  Row,
  Table,
  Tag,
} from "antd";
import {
  getPartnerTransactionKpiByCategory,
  getPartnerTransactions,
  getPartnerTransactionsExport,
  getPartnerTransactionTrendKpi,
} from "services/transaction";
import { localizeCurrency, smartConcat } from "components/utils";
import { TRANSACTION_STATUS } from "../../constants";
import { useTheme } from "styled-components";
import { format, addYears } from "date-fns";
import { IconButton, MenuItem } from "@material-ui/core";
import {
  CollapseScreenIcon,
  DoughnutBigIcon,
  DoughnutSmallIcon,
  FullScreenIcon,
  RadialBigIcon,
  RadialSmallIcon,
} from "components/icons";
import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import { TextField } from "components/text-field";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  CityKpiProgress,
  DoughnutChart,
  DownloadIconButton,
  KpiToggle,
  RadarChart,
} from "components";
import { hex2rgba } from "components/utils/validators";
import uuid from "react-uuid";
import LineChart from "components/users/chart";

const Transactions = () => {
  const { t, i18n } = useTranslation(["common", "transaction"]);
  // const { theme } = useTheme();
  const [limit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(true);
  const [searchTerm, setSearchTerm] = useState(null);
  const [filterChoice, setFilterChoice] = useState(null);
  const { colors } = useTheme();
  const [categoriesKpi, setCategoriesKpi] = useState(null);
  const [currentCategoriesKpi, setCurrentCategoriesKpi] = useState(null);
  const [categoryFilterChoice, setCategoryFilterChoice] = useState("week");
  const [transactionTrendKpi, setTransactionTrendKpi] = useState(null);
  const [currentTransactionTrendKpi, setCurrentTransactionTrendKpi] =
    useState(null);
  const [trendXaxis, setTrendXaxis] = useState(null);
  const [trendYaxis, setTrendYaxis] = useState(null);
  const [trendZaxis, setTrendZaxis] = useState(null);
  const [trendExport, setTrendExport] = useState(null);
  const [toggleRadialChart, setToggleRadialChart] = useState(false);

  const PROGRESS_BAR_COLORS = [
    "#74D2E7",
    "#FFC764",
    "#64A882",
    "#335C84",
    "#282eff",
    "#707070",
    "#ffc764",
    "#f8b7a2",
    "#64a882",
    "#26a6b8",
    "#335c84",
    "#ff0268",
    "#d6532c",
    "#02dcff",
  ];

  const FILTER_CHOICE = [
    {
      label: t("transaction:filters.all"),
      value: "ALL",
    },
    {
      label: t("transaction:filters.created"),
      value: TRANSACTION_STATUS.WAITING_FOR_CONFIRMATION,
    },
    {
      label: t("transaction:filters.paid"),
      value: TRANSACTION_STATUS.PAID,
    },
    {
      label: t("transaction:filters.confirmed"),
      value: TRANSACTION_STATUS.CONFIRMED,
    },
    {
      label: t("transaction:filters.refused"),
      value: TRANSACTION_STATUS.REFUSED,
    },
    {
      label: t("transaction:filters.abandonned"),
      value: TRANSACTION_STATUS.ABANDONNED,
    },
    {
      label: t("transaction:filters.paymentFailed"),
      value: TRANSACTION_STATUS.PAYMENT_FAILED,
    },
    {
      label: t("transaction:filters.refund"),
      value: TRANSACTION_STATUS.REFUND,
    },
  ];

  const title = (bookingReference) => (
    <div className="transaction-pop-over-title-container">
      <img src="/icons/transaction-icon.png" alt="transaction" />
      <label>{t`transaction:orderInfo.title`}</label>
      <p>
        {t("transaction:orderInfo.orderNumber", {
          bookingRef: bookingReference,
        })}
      </p>
    </div>
  );

  const content = (amountPaid, amountReceived, credits, user) => (
    <div className="transaction-pop-over-content-container">
      <div>
        <label>{t(`transaction:orderInfo.amountBy`, { buyer: user })}</label>
        <p>{`${localizeCurrency(amountPaid, i18n.language)}€`}</p>
      </div>
      <div>
        <label>{t`transaction:orderInfo.neoReceives`}</label>
        <p>{`${localizeCurrency(amountReceived, i18n.language)}€`}</p>
      </div>
      <div>
        <label>{t`transaction:orderInfo.creditsUsed`}</label>
        <p>{`${localizeCurrency(credits, i18n.language)}€`}</p>
      </div>
    </div>
  );

  const columns = [
    {
      title: t`transaction:columns.order`,
      dataIndex: "bookingReference",
      render: (bookingRef, record) => {
        return (
          <Popover
            content={content(
              record?.totalAmount,
              record?.commission,
              record?.discountAmount,
              record?.client
            )}
            title={title(record?.bookingReference)}
            placement="right"
            trigger="hover"
          >
            {bookingRef}
          </Popover>
        );
      },
    },
    {
      title: t`transaction:columns.client`,
      dataIndex: "client",
      sorter: (a, b) => (a.client > b.client ? 1 : -1),
    },
    {
      title: t`transaction:columns.seller`,
      dataIndex: "vendor",
      sorter: (a, b) => (a.vendor > b.vendor ? 1 : -1),
    },
    {
      title: t`transaction:columns.total`,
      dataIndex: "totalAmount",
      render: (total) => `${localizeCurrency(total, i18n.language)}€`,
    },
    {
      title: t`transaction:columns.subsidised`,
      dataIndex: "discountAmount",
      render: (discount) => `${localizeCurrency(discount, i18n.language)}€`,
      align: "center" as const,
    },
    {
      title: t`transaction:columns.status`,
      dataIndex: "status",
      render: (status) => (
        <Tag className="transaction-status-tag" color={STATUS_COLOR[status]}>
          {t(`transaction:status.${status}`)}
        </Tag>
      ),
      align: "center" as const,
    },
    {
      title: t`transaction:columns.activity`,
      dataIndex: "activityName",
      sorter: (a, b) => (a.activityName > b.activityName ? 1 : -1),
    },
    {
      title: t`transaction:columns.paymentMode`,
      dataIndex: "paymentMode",
      render: (paymentMode) => paymentMode && smartConcat(paymentMode, " / "),
    },
    {
      title: t`transaction:columns.orderDate`,
      dataIndex: "orderDate",
      render: (date) =>
        date
          ? format(new Date(date), `dd/MM/yyyy ${t("common:at")} HH:mm`)
          : "",
      sorter: (a, b) => (a.orderDate > b.orderDate ? 1 : -1),
    },
  ];

  const STATUS_COLOR = {
    [TRANSACTION_STATUS.WAITING_FOR_CONFIRMATION]: colors.darkBlue,
    [TRANSACTION_STATUS.CONFIRMED]: colors.green,
    [TRANSACTION_STATUS.REFUSED]: colors.turquoise,
  };

  useEffect(() => {
    fetchData();
    retrieveTransactionByCategoryKpis();
    retrieveTransactionTrendKpis();
    renderDoughnutChart();
  }, []);

  const fetchData = (params = {}) => {
    let transactions = [];
    let totalRecords = null;
    getPartnerTransactions(JSON.stringify(params))
      .then((resp) => {
        console.log("PARTNER TRANSACTIONS resp :>> ", resp);
        if (params["where"]?.filterBy && params["where"]?.filterBy !== "ALL") {
          transactions = resp?.transactions.filter(
            (transaction) => transaction.status === params["where"]?.filterBy
          );
          totalRecords = transactions?.length;
        } else {
          transactions = resp?.transactions;
          totalRecords = resp?.totalRecords;
        }
        setTransactions(transactions);
        setTotalRecords(totalRecords);
      })
      .catch((e) => {
        console.log("e :>> ", e);
      });
  };

  const onTableChange = (pagination, b, sort) => {
    let params = null;
    if (searchTerm && searchTerm.length > 0) {
      params = {
        where: {
          search: searchTerm,
        },
      };
    }
    fetchData(params);
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    const searchParams = {
      where: {
        searchBy: searchTerm,
      },
    };

    fetchData(searchParams);
  };

  const handleDownload = () => {
    getPartnerTransactionsExport()
      .then((resp) => {
        console.log("resp :>> ", resp);
        if (resp) {
          exportToCSV(resp, "export_transactions");
          message.success(t`transaction:success.export`, 5);
        } else {
          message.error(t`transaction:error.noData`, 5);
        }
      })
      .catch((e) => {
        console.log("e :>> ", e?.response?.data);
        message.success(t`transaction:error.export`, 5);
      });
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const retrieveTransactionByCategoryKpis = () => {
    getPartnerTransactionKpiByCategory()
      .then((response) => {
        setCategoriesKpi(response);
        if (categoryFilterChoice === "week") {
          setCurrentCategoriesKpi(response?.week);
        }
      })
      .catch((e) => {
        console.log("e :>> ", e);
      });
  };

  const retrieveTransactionTrendKpis = () => {
    getPartnerTransactionTrendKpi()
      .then((response) => {
        setTransactionTrendKpi(response);
        if (categoryFilterChoice === "week") {
          setCurrentTransactionTrendKpi(response?.week);
          setTrendXaxis(response?.week?.arr?.arrX);
          setTrendYaxis(response?.week?.arr?.arrY);
          setTrendZaxis(response?.week?.arrPy?.arrY);
          setTrendExport(response?.week?.export);
        }
      })
      .catch((e) => {
        console.log("e :>> ", e);
      });
  };

  const handleDownloadSales = () => {
    if (trendExport.length > 0) {
      exportToCSV(trendExport, "export_transaction_trend_kpi");
      message.success(t`transaction:success.export`, 5);
    } else message.error(t`transaction:error.noData`, 5);
  };

  //handleCategoryFilterChoice
  const handleCategoryFilterChoice = (event) => {
    setCategoryFilterChoice(event.target.value);
    handleCategoryFilterChoiceChangesKpi(event.target.value);
  };

  const handleCategoryFilterChoiceChangesKpi = (toggleValue) => {
    if (toggleValue === "month") {
      setCurrentCategoriesKpi(categoriesKpi?.month);
      setCurrentTransactionTrendKpi(transactionTrendKpi?.month);
      setTrendXaxis(transactionTrendKpi?.month?.arrMonth?.arrX);
      setTrendYaxis(transactionTrendKpi?.month?.arrMonth?.arrY);
      setTrendZaxis(transactionTrendKpi?.month?.arrMonthPy?.arrY);
      setTrendExport(transactionTrendKpi?.month?.export);
    } else if (toggleValue === "year") {
      setCurrentCategoriesKpi(categoriesKpi?.year);
      setCurrentTransactionTrendKpi(transactionTrendKpi?.year);
      setTrendXaxis(transactionTrendKpi?.year?.arrYear?.arrX);
      setTrendYaxis(transactionTrendKpi?.year?.arrYear?.arrY);
      setTrendZaxis(transactionTrendKpi?.year?.arrYearPy?.arrY);
      setTrendExport(transactionTrendKpi?.year?.export);
    } else {
      setCurrentCategoriesKpi(categoriesKpi?.week);
      setCurrentTransactionTrendKpi(transactionTrendKpi?.week);
      setTrendXaxis(transactionTrendKpi?.week?.arr?.arrX);
      setTrendYaxis(transactionTrendKpi?.week?.arr?.arrY);
      setTrendZaxis(transactionTrendKpi?.week?.arrPy?.arrY);
      setTrendExport(transactionTrendKpi?.week?.export);
    }
  };

  const renderDoughnutChart = () => {
    const colorsList = [];
    const labels = [];
    const dataValues = [];
    currentCategoriesKpi?.forEach((c: any, index: number) => {
      const selectedColor =
        PROGRESS_BAR_COLORS[index % PROGRESS_BAR_COLORS.length];
      const rgb = hex2rgba(selectedColor);
      colorsList.push(rgb);
      labels.push(c.category);
      dataValues.push(c.activityCount);
    });

    return (
      <Col
        key={`cat-ad3-${uuid()}`}
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
      >
        <DoughnutChart
          labels={labels}
          dataValues={dataValues}
          colors={colorsList}
        />
      </Col>
    );
  };

  const renderRadialChart = () => {
    const colorsList = [];
    const labels = [];
    const dataValues = [];
    currentCategoriesKpi?.forEach((c: any, index: number) => {
      const selectedColor =
        PROGRESS_BAR_COLORS[index % PROGRESS_BAR_COLORS.length];
      const rgb = hex2rgba(selectedColor);
      colorsList.push(rgb);
      labels.push(c.category);
      dataValues.push(c.activityCount);
    });

    return (
      <Col
        key={`cat-ad3-${uuid()}`}
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
      >
        <RadarChart labels={labels} dataValues={dataValues} />
      </Col>
    );
  };

  const handleDownloadSalesByCategory = () => {
    if (currentCategoriesKpi.length > 0) {
      exportToCSV(currentCategoriesKpi, "export_transaction_categories_kpi");
      message.success(t`transaction:success.export`, 5);
    } else message.error(t`transaction:error.noData`, 5);
  };

  const toggleIconsAndCharts = (show) => {
    setToggleRadialChart(show);
  };

  const renderTransactionStats = () => (
    <>
      <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
        <Card className="transaction-card">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <div>
                <KpiToggle
                  opts={["week", "month", "year"]}
                  value={categoryFilterChoice}
                  handleChange={handleCategoryFilterChoice}
                  color="turquoise"
                  hasToday={true}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <div className="transaction-graph-card2-header">
                <h1
                  style={{ paddingRight: "10px" }}
                >{t`transaction:stats.sales`}</h1>
                <DownloadIconButton onClick={handleDownloadSales} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="transaction-graph-card1-header">
                <div className="transaction-align-headers">
                  <h1
                    style={{ paddingRight: "10px" }}
                  >{t`transaction:stats.allocation`}</h1>
                  <DownloadIconButton onClick={handleDownloadSalesByCategory} />
                </div>
                <div>
                  <Button
                    icon={
                      toggleRadialChart ? (
                        <DoughnutSmallIcon />
                      ) : (
                        <DoughnutBigIcon />
                      )
                    }
                    size="small"
                    style={{
                      color: colors.shades.grey[25],
                      border: "none",
                      fontSize: "12pt",
                      paddingRight: "10px",
                    }}
                    onClick={() => toggleIconsAndCharts(false)}
                  ></Button>
                  <Button
                    icon={
                      toggleRadialChart ? (
                        <RadialBigIcon />
                      ) : (
                        <RadialSmallIcon />
                      )
                    }
                    size="small"
                    style={{
                      color: colors.shades.grey[25],
                      border: "none",
                      fontSize: "12pt",
                    }}
                    onClick={() => toggleIconsAndCharts(true)}
                  ></Button>
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
              <div>
                <LineChart
                  xSeries={trendXaxis}
                  ySeries={trendYaxis}
                  zSeries={trendZaxis}
                  seriesOneLabel={format(new Date(), "yyyy")}
                  seriesTwoLabel={format(addYears(new Date(), -1), "yyyy")}
                  title={t`transaction:stats.trendLabel`}
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <div className="test">
                <div>
                  {toggleRadialChart
                    ? renderRadialChart()
                    : renderDoughnutChart()}
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
        <CityKpiProgress displayExport={true} />
      </Col>
    </>
  );

  const onChangeFilter = (event) => {
    setFilterChoice(event.target.value);
    const filterParams = {
      where: {
        filterBy: event.target.value,
      },
    };
    fetchData(filterParams);
  };

  const showMenu = (
    <div className="filter-container">
      <p className="filter-menu-title">{t("transaction:filters.title")}</p>
      <Radio.Group value={filterChoice} onChange={onChangeFilter}>
        {FILTER_CHOICE.map((item, index) => (
          <MenuItem key={index} className="filter-menu-item">
            <Radio value={item.value}>{item.label}</Radio>
          </MenuItem>
        ))}
      </Radio.Group>
    </div>
  );

  return (
    <MainLayout>
      <TransactionStyle />
      <Row gutter={[8, 8]} className="transaction-main-container">
        {!isFullScreen && renderTransactionStats()}
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card className="transaction-card">
            <h1>{t`transaction:title`}</h1>
            <Row
              justify="space-between"
              className="transaction-actions-container"
            >
              <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                <Dropdown overlay={showMenu} placement="bottomLeft" arrow>
                  <a className="filter-dropdown">
                    {t("transaction:filter")}
                    {"   "}
                    <DownOutlined />
                  </a>
                </Dropdown>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={14}
                xl={14}
                xxl={14}
                className="transaction-right-col"
              >
                {isFullScreen && (
                  <>
                    <div className="search-field-container">
                      <p>{t("common:search")}:</p>
                      <TextField
                        radius="0.8125rem"
                        onPressEnter={handleSearch}
                      />
                    </div>
                    <Button
                      icon={<DownloadOutlined />}
                      size="large"
                      onClick={handleDownload}
                    >
                      {t`transaction:export`}
                    </Button>
                  </>
                )}
                <IconButton onClick={() => setIsFullScreen(!isFullScreen)}>
                  {isFullScreen ? <CollapseScreenIcon /> : <FullScreenIcon />}
                </IconButton>
              </Col>
            </Row>
            <Table
              className={
                isFullScreen ? "show-table-full" : "show-table-half-screen"
              }
              columns={columns}
              rowKey={(record) => record?.id}
              dataSource={transactions}
              locale={{ emptyText: t`common:table.emptyText` }}
              onChange={onTableChange}
              pagination={{
                pageSize: limit,
                defaultPageSize: limit,
                total: totalRecords,
                showSizeChanger: false,
              }}
            />
          </Card>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default Transactions;

import React from "react";
import "antd/dist/antd.css";
import { StyledThemeProvider } from "definitions/styled-components";
// import { RecoilRoot } from "recoil";
import "./i18n";
import SignIn from "pages/signin";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import {
  Dashboard,
  Kpis,
  Users,
  Transactions,
  Parameters,
  Subventions,
  Documents,
  ActivateAccount,
  ForgetPassword,
  GenerateTokens,
  Administrators,
} from "pages";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import { Space, Spin } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import { GlobalContextProvider } from "./contexts/global/";

import "./services/axios-service.ts";
import "./fonts/style.css";
import "./styles/main.scss";
import ResetPassword from "pages/resetPassword";

function App(): JSX.Element {
  const requireLogin = (to, from, next) => {
    if (to.meta.auth) {
      if (getIsLoggedIn()) {
        next();
      }
      next.redirect("/connexion");
    } else {
      next();
    }
  };

  const getIsLoggedIn = () => {
    return sessionStorage.getItem("token");
    // return localStorage.getItem("authKey");
  };

  const waitOneSecond = (to, from, next) => {
    setTimeout(next, 1000);
  };

  const GLOBAL_GUARDS = [requireLogin, waitOneSecond];

  const renderLoader = () => {
    const antIcon = (
      <SyncOutlined style={{ fontSize: 64, color: "#003466" }} spin />
    );
    return (
      <Space
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          zIndex: 1,
          top: 300,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin
          size="large"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: "#003466",
          }}
          indicator={antIcon}
        />
      </Space>
    );
  };

  return (
    <StyledThemeProvider>
      <GlobalContextProvider>
        <Router>
          <GuardProvider guards={GLOBAL_GUARDS} loading={() => renderLoader()}>
            <Switch>
              <GuardedRoute path="/connexion" exact component={SignIn} />
              <GuardedRoute
                path="/"
                exact
                component={Dashboard}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/tableau-de-bord"
                exact
                component={Dashboard}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/kpis"
                exact
                component={Kpis}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/utilisateurs"
                exact
                component={Users}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/mes-documents"
                exact
                component={Documents}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/transactions"
                exact
                component={Transactions}
                meta={{ auth: true }}
              />{" "}
              <GuardedRoute
                path="/parametres"
                exact
                component={Parameters}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/mes-subventions"
                exact
                component={Subventions}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/generer-des-tokens"
                exact
                component={GenerateTokens}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/activation-compte"
                exact
                component={ActivateAccount}
                meta={{ auth: false }}
              />
              <GuardedRoute
                path="/mot-de-passe-oublie"
                exact
                component={ForgetPassword}
                meta={{ auth: false }}
              />
              <GuardedRoute
                path="/administrateurs"
                exact
                component={Administrators}
                meta={{ auth: true }}
              />
              <GuardedRoute
                path="/reinitialisation-mot-de-passe"
                exact
                component={ResetPassword}
                meta={{ auth: false }}
              />
            </Switch>
          </GuardProvider>
        </Router>
      </GlobalContextProvider>
    </StyledThemeProvider>
  );
}

export default App;

import * as React from "react";

function SvgLargerExcelIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70.566"
      height="68.29"
      viewBox="0 0 70.566 68.29"
    >
      <path
        id="Path_1434"
        data-name="Path 1434"
        d="M29.382,20V35.934H18V76.908H29.382V88.29H88.566V20Zm0,27.316h6.032l3.073,4.553,3.073-4.553h6.032L41.56,56.421l6.032,9.105H41.56l-3.073-4.553-3.073,4.553H29.382l6.032-9.105ZM84.014,83.737H33.934V76.908h25.04V63.25H77.185V58.7H58.974V54.145H77.185V49.592H58.974V45.04H77.185V40.487H58.974V35.934H33.934V24.553H84.014Z"
        transform="translate(-18 -20)"
        fill="#0d8542"
      />
    </svg>
  );
}

export default SvgLargerExcelIcon;

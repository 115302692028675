import { Badge, Progress, Radio } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const KpiProgressBar = styled(
  ({
    label = "Lyon",
    color = "#ff0268",
    percentage = 0,
    description = 0,
    showParticipation = false,
    showBadge = false,
    showInfo = true,
    count = 0,
    ...rest
  }) => {
    const { t } = useTranslation();

    const config = {
      height: 16,
      percent: percentage,
      strokeColor: color,
      showInfo: showInfo,
    };

    return (
      <div {...rest}>
        <div className="kpi-progress-bar-content-container">
          {showBadge && <Badge color={color} />}
          <div className="kpi-progress-bar-content">
            <label>{label}</label>
            {showParticipation && (
              <p>{t("common:kpi.participation", { count: +description })}</p>
            )}
          </div>
        </div>
        <Progress {...config} />
        {!showInfo && (
          <label className="person-count-label">{`${count} per`}</label>
        )}
      </div>
    );
  }
)`
  display: flex;
  flex-direction: row;
  width: 100%;

  .kpi-progress-bar-content-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;

    .kpi-progress-bar-content {
      font-family: var(--font-glacial);
      display: flex;
      flex-direction: column;

      label {
        font-weight: 600;
        color: ${({ theme }) => theme.colors.shades.grey[27]};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.75rem;
      }

      p {
        font-weight: 400;
        color: ${({ theme }) => theme.colors.shades.grey[25]};
        font-size: 0.625rem;
      }
    }
  }

  .ant-progress {
    flex: 1.5;

    .ant-progress-outer .ant-progress-inner {
      background: ${({ theme }) => theme.colors.shades.grey[62]};
    }

    .ant-progress-text {
      font-weight: 600;
      font-family: var(--font-glacial);
      color: ${({ theme }) => theme.colors.shades.grey[25]};
    }
  }

  .person-count-label {
    font-size: 0.625rem;
    font-family: var(--font-glacial);
    font-weight: 600;
    color: ${({ theme }) => theme.colors.shades.grey[25]};
  }
`;

import * as React from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SvgDoughnutBigIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
    >
      <g
        id="Group_2012"
        data-name="Group 2012"
        transform="translate(-973.873 -144.873)"
      >
        <path
          id="Subtraction_47"
          data-name="Subtraction 47"
          d="M13.5,27A13.5,13.5,0,0,1,3.954,3.954,13.5,13.5,0,0,1,23.046,23.046,13.41,13.41,0,0,1,13.5,27Zm0-23.625A10.125,10.125,0,1,0,23.625,13.5,10.137,10.137,0,0,0,13.5,3.375Z"
          transform="translate(973.873 144.873)"
          fill="#74d2e7"
        />
        <path
          id="Intersection_38"
          data-name="Intersection 38"
          d="M0,8.579A13.3,13.3,0,0,1,16.423.611L15.371,3.455A10.249,10.249,0,0,0,2.924,9.424Z"
          transform="translate(975.016 145.168)"
          fill="#013466"
          stroke="#74d2e7"
          strokeWidth="0.3"
        />
      </g>
    </svg>
  );
}

export default SvgDoughnutBigIcon;

import { CURRENT_USER_COOKIE } from "../../constants";
import { destroyCookie, parseCookies, setCookie } from "nookies";
import { useState, createContext } from "react";

export const GlobalContext = createContext(null);

export const GlobalContextProvider = ({ children }) => {
  const [avatar, setAvatar] = useState(
    localStorage.getItem("avatar")
      ? JSON.parse(localStorage.getItem("avatar"))
      : "{}"
  );
  const cookies = parseCookies();
  const loggedInUser = cookies.loggedInUser
    ? JSON.parse(cookies.loggedInUser)
    : null;

  const [currentUser, setCurrentUser] = useState({
    id: loggedInUser?.id ?? null,
    firstname: loggedInUser?.firstname ?? "",
    name: loggedInUser?.name ?? "",
    imageUrls: loggedInUser?.imageUrls ?? null,
    partnerLogo: loggedInUser?.partnerLogo ?? null,
  });
  const [siderMenuOpen, setSiderMenuOpen] = useState(
    localStorage.getItem("collapsed")
      ? JSON.parse(localStorage.getItem("collapsed"))
      : "{}"
  );

  const handleSetCurrentUser = (user) => {
    const { id, firstname, name, imageUrls, partnerLogo } = user;
    const updatedUser = { id, firstname, name, imageUrls, partnerLogo };

    setCookie(null, CURRENT_USER_COOKIE, JSON.stringify(updatedUser), {
      path: "/",
    });

    setCurrentUser(updatedUser);
  };

  const signOutUser = () => {
    handleSetCurrentUser({
      id: null,
      firstname: "",
      name: "",
      fullname: "",
      imageUrls: null,
      partnerLogo: null,
    });

    destroyCookie(null, CURRENT_USER_COOKIE, {
      path: "/",
    });
    localStorage.removeItem("collapsed");
  };

  const clearAvatar = () => {
    setAvatar(null);
    localStorage.removeItem("avatar");
  };

  const handleSetAvatar = (params) => {
    setAvatar(params || null);
    localStorage.setItem("avatar", params ? JSON.stringify(params) : "{}");
  };

  const contextValue = {
    avatar,
    setAvatar: handleSetAvatar,
    clearAvatar,
    signOutUser,
    currentUser,
    siderMenuOpen,
    setCurrentUser: handleSetCurrentUser,
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

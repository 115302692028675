import { useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import { Col, Row, Card } from "antd";
import { activatePartneraccount } from "services/user";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AccountActivationStyle from "styles/accountActivationStyles";
import { Header } from "components";

const ActivateAccount = () => {
  const [isActivationSuccess, setActivationStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation(["common", "activation"]);
  const history = useHistory();
  useEffect(() => {
    if (window.location.search) {
      const activationCode = new URLSearchParams(window.location.search).get(
        "activation_code"
      );
      activatePartneraccount(activationCode).then(
        (res) => {
          setActivationStatus(true);
        },
        (err) => {
          setActivationStatus(false);
        }
      );
      setIsLoading(false);
    } else {
      history.push("/connexion");
    }
  }, []);

  return (
    <>
      <Header />
      <Content className="content-container">
        <AccountActivationStyle />

        <Row className="page-description-container">
          <h1 className="page-description-title">{t("activation:title")}</h1>
          {isActivationSuccess && (
            <p className="page-description-subtitle">
              {t("activation:accountActivated.description")}
            </p>
          )}
        </Row>
        <Card>
          <Row justify="space-between" className="content-paragraph-container">
            {isLoading && (
              <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                <p className="content-title">{t("common:loadingInProgress")}</p>
                <p className="content-title">{"..."}</p>
              </Col>
            )}
            {!isLoading && isActivationSuccess && (
              <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                <p className="content-title">
                  {t("activation:accountActivated.content.title")}
                </p>
                <br />
                <p className="content-paragraph">
                  <Trans
                    i18nKey="activation:accountActivated.content.content"
                    components={{
                      anchor: <a className="anchor-text" href="/connexion" />,
                    }}
                  />
                </p>
              </Col>
            )}
            {!isLoading && !isActivationSuccess && (
              <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                <p className="content-title">
                  {t("activation:accountActivated.errors.title")}
                </p>
                <br />
                <p className="content-paragraph">
                  <Trans
                    i18nKey="activation:accountActivated.errors.content"
                    components={{
                      anchor: <a className="anchor-text" />,
                    }}
                  />
                </p>
              </Col>
            )}
            <Col
              xs={24}
              sm={24}
              md={10}
              lg={10}
              xl={10}
              xxl={10}
              className="svg-container"
            >
              <img
                alt=""
                src="/images/account-confirmation.svg"
                className="confirm-email-svg"
              />
            </Col>
          </Row>
        </Card>
      </Content>
    </>
  );
};

export default ActivateAccount;

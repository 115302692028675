import React from "react";
import { Row, Col } from "antd";
import banner from "../../images/banner.png";
import styled from "styled-components";

export const Banner = styled(({ ...rest }) => (
  <Row {...rest}>
    <Col xs={24}>
      <img src={banner} className="banner" alt="banner" />
    </Col>
  </Row>
))`
  img {
    width: 100%;
  }
`;

import * as React from "react";

function SvgSubventionParameterIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="59.966"
      height="58.518"
      viewBox="0 0 59.966 58.518"
    >
      <g id="Group" transform="translate(0.5 0.5)">
        <path
          id="Compound_Path"
          data-name="Compound Path"
          d="M27.667,39a8.21,8.21,0,0,0-7.912,5.971H10.5V49.45h9.255a8.21,8.21,0,0,0,15.824,0H69.466V44.971H35.579A8.21,8.21,0,0,0,27.667,39Zm0,11.942A3.732,3.732,0,1,1,31.4,47.21,3.732,3.732,0,0,1,27.667,50.942Z"
          transform="translate(-10.5 -18.474)"
          fill="#fff"
          stroke="#003466"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <path
          id="Compound_Path-2"
          data-name="Compound Path"
          d="M52.3,11.5a8.21,8.21,0,0,0-7.912,5.971H10.5V21.95H44.387a8.21,8.21,0,0,0,15.824,0h9.255V17.471H60.211A8.21,8.21,0,0,0,52.3,11.5Zm0,11.942a3.732,3.732,0,1,1,3.732-3.732A3.732,3.732,0,0,1,52.3,23.442Z"
          transform="translate(-10.5 -11.5)"
          fill="#fff"
          stroke="#003466"
          strokeLinecap="round"
          strokeWidth="1"
        />
        <path
          id="Compound_Path-3"
          data-name="Compound Path"
          d="M52.3,66.5a8.21,8.21,0,0,0-7.912,5.971H10.5V76.95H44.387a8.21,8.21,0,0,0,15.824,0h9.255V72.471H60.211A8.21,8.21,0,0,0,52.3,66.5Zm0,11.942a3.732,3.732,0,1,1,3.732-3.732A3.732,3.732,0,0,1,52.3,78.442Z"
          transform="translate(-10.5 -25.448)"
          fill="#fff"
          stroke="#003466"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export default SvgSubventionParameterIcon;

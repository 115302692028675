import { get, post } from "./axios-service";

export const getDatabasesDocuments = () => {
  return get(`partners/documents/databases`);
};

export const uploadDocument = (queryString, payload, headers) => {
  return post(`partner/upload-bdd-doc?filter=${queryString}`, payload, headers);
};

export const getCategoriesList = () => {
  return get("category/top-most");
};

export const createPartnerFundings = (payload) => {
  return post(`partner-fundings`, payload);
};

export const getPartners = () => {
  return get("partners");
};

export const readDocumentContent = (payload) => {
  return post("partner-gift-tokens/file", payload);
};

import React from "react";

function SvgMan(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.315"
      height="40.62"
      viewBox="0 0 39.315 40.62"
    >
      <g
        id="Group_2154"
        data-name="Group 2154"
        transform="translate(-7344.801 2212.65)"
      >
        <g
          id="Path_528"
          data-name="Path 528"
          transform="translate(6878.007 -2478.85)"
          fill="none"
        >
          <path
            d="M493.383,302.484c0-.275-.011-.549-.006-.823a.207.207,0,0,1,.229-.219c.27-.006.54,0,.81,0,.148,0,.21.091.255.22a2.167,2.167,0,0,0,1.344,1.422c.4.141.375.1.372.539,0,.227,0,.454-.007.682a.6.6,0,0,1-.411.087c-.136,0-.273-.006-.409,0a.464.464,0,0,1-.4-.114l-.063-.165a.255.255,0,0,0-.026-.151,2.466,2.466,0,0,0-1.491-1.3A.281.281,0,0,1,493.383,302.484Z"
            stroke="none"
          />
          <path
            d="M 493.9601135253906 301.4390563964844 C 493.84228515625 301.4390563964844 493.7244262695312 301.4397277832031 493.606689453125 301.4421691894531 C 493.46240234375 301.4451599121094 493.3798217773438 301.52099609375 493.37744140625 301.6611022949219 C 493.3726806640625 301.9353942871094 493.3806762695312 302.2099914550781 493.3834838867188 302.4844970703125 C 493.41650390625 302.5820007324219 493.4863891601562 302.621337890625 493.5845336914062 302.6578369140625 C 494.24365234375 302.9030456542969 494.7783813476562 303.2966918945312 495.0755615234375 303.9596862792969 C 495.0975341796875 304.0087890625 495.1102905273438 304.0571899414062 495.1012573242188 304.1103820800781 C 495.1221923828125 304.1653442382812 495.1431274414062 304.220458984375 495.1641845703125 304.2754211425781 C 495.2739562988281 304.3936767578125 495.4151000976562 304.3962707519531 495.5624389648438 304.3898620605469 C 495.6984252929688 304.3838500976562 495.8351745605469 304.385009765625 495.97119140625 304.3895874023438 C 496.1163940429688 304.3943786621094 496.2593688964844 304.3976745605469 496.382568359375 304.3026123046875 C 496.3848266601562 304.0753784179688 496.3880615234375 303.84814453125 496.3890991210938 303.6210021972656 C 496.39111328125 303.1861267089844 496.4133911132812 303.2232971191406 496.0166015625 303.0824890136719 C 495.3306884765625 302.8391723632812 494.907958984375 302.3337097167969 494.6724243164062 301.6602783203125 C 494.6265869140625 301.5293273925781 494.562255859375 301.4407043457031 494.4103088378906 301.4401550292969 C 494.2602844238281 301.4401550292969 494.1101989746094 301.4390563964844 493.9601135253906 301.4390563964844"
            stroke="none"
            fill="#fff"
          />
        </g>
        <g
          id="Path_532"
          data-name="Path 532"
          transform="translate(6878.698 -2623.303)"
          fill="none"
        >
          <path
            d="M492.674,448.385a.666.666,0,0,0,.47.431,1.887,1.887,0,0,0,.731,0c0,.063,0,.14-.079.139a1.692,1.692,0,0,1-.768-.06A.574.574,0,0,1,492.674,448.385Z"
            stroke="none"
          />
          <path
            d="M 492.674072265625 448.3850402832031 C 492.6845703125 448.6285705566406 492.8211669921875 448.7892761230469 493.0271606445312 448.8921813964844 C 493.2640991210938 449.0105285644531 493.520263671875 448.9520874023438 493.7692260742188 448.9520874023438 C 493.7786560058594 448.9520874023438 493.7884216308594 448.9524230957031 493.7974548339844 448.9523315429688 C 493.8784484863281 448.9523315429688 493.8766784667969 448.8759765625 493.8746948242188 448.8134460449219 C 493.63134765625 448.8606567382812 493.385009765625 448.8641357421875 493.1439819335938 448.8161926269531 C 492.9083862304688 448.7692565917969 492.7686157226562 448.5965881347656 492.674072265625 448.3850402832031"
            stroke="none"
            fill="#fff"
          />
        </g>
        <path
          id="Path_1232"
          data-name="Path 1232"
          d="M34.32,31.354V30.272a11.06,11.06,0,0,0,5.349-7.426A3.872,3.872,0,0,0,41.8,19.525a2.177,2.177,0,0,0-.875-1.913,2.318,2.318,0,0,0-.989-.379c.442-1.319,1.623-5.579-.33-8.744A7.33,7.33,0,0,0,34.692,5.3,13.993,13.993,0,0,0,31.648,5H27.573a14,14,0,0,0-3.044.3,7.33,7.33,0,0,0-4.91,3.191c-1.953,3.164-.772,7.424-.33,8.744a2.312,2.312,0,0,0-.989.379,2.177,2.177,0,0,0-.875,1.913,3.877,3.877,0,0,0,2.137,3.327A11.373,11.373,0,0,0,24.9,30.268v1.1a16.413,16.413,0,0,0-10.344,3.92,12.611,12.611,0,0,0-4.5,9.523.66.66,0,0,0,.7.612H48.468a.66.66,0,0,0,.7-.612C49.168,37.541,42.556,31.6,34.32,31.354ZM40.4,19.525a2.33,2.33,0,0,1-.457,1.39c.034-.485.05-.987.036-1.517-.008-.308-.031-.607-.064-.9a.684.684,0,0,1,.138.072A1.16,1.16,0,0,1,40.4,19.525ZM20.853,9.068a6,6,0,0,1,4.021-2.582,12.428,12.428,0,0,1,2.7-.261h4.074a12.423,12.423,0,0,1,2.7.261,6,6,0,0,1,4.021,2.582c1.244,2.013.982,4.671.6,6.4a7.407,7.407,0,0,0-1.789-2.161c-3.041-2.511-7.408-2.407-7.548-2.4-.178-.006-4.549-.109-7.591,2.4a7.415,7.415,0,0,0-1.793,2.166C19.871,13.743,19.608,11.082,20.853,9.068ZM18.825,19.525a1.168,1.168,0,0,1,.341-.952.674.674,0,0,1,.144-.075c-.033.292-.056.591-.064.9a15.128,15.128,0,0,0,.039,1.522A2.33,2.33,0,0,1,18.825,19.525Zm1.82-.1A6.686,6.686,0,0,1,23,14.205c2.408-1.993,5.952-2.075,6.552-2.075h.086c.038,0,3.987-.085,6.6,2.076a6.679,6.679,0,0,1,2.345,5.219h0a10.711,10.711,0,0,1-3.4,8.658,8.814,8.814,0,0,1-5.583,2.365,8.57,8.57,0,0,1-5.349-2.236A10.735,10.735,0,0,1,20.646,19.427Zm8.8,12.25c.056,0,.112,0,.167,0a8.916,8.916,0,0,0,3.309-.706v.822a1.255,1.255,0,0,1-.006.154c-.066,3.791-2.689,4.645-3.3,4.793-.617-.15-3.2-1-3.3-4.782h0v0c0-.023,0-.043,0-.067a.8.8,0,0,1,0-.1v-.808A8.3,8.3,0,0,0,29.444,31.676ZM42.361,44.2V39.511a.706.706,0,0,0-1.4,0V44.2h-22.7V39.511a.706.706,0,0,0-1.4,0V44.2H11.47a11.535,11.535,0,0,1,4.077-8.045,14.923,14.923,0,0,1,9.406-3.563,5.764,5.764,0,0,0,4.536,5.382.791.791,0,0,0,.245,0,5.743,5.743,0,0,0,4.535-5.382c7.253.22,13.125,5.283,13.482,11.607Z"
          transform="translate(7334.848 -2217.55)"
          fill="#74d2e7"
          stroke="#fff"
          strokeWidth="0.2"
        />
        <path
          id="Path_528-2"
          data-name="Path 528"
          d="M493.383,302.484c0-.275-.011-.549-.006-.823a.207.207,0,0,1,.229-.219c.27-.006.54,0,.81,0,.148,0,.21.091.255.22a2.167,2.167,0,0,0,1.344,1.422c.4.141.375.1.372.539,0,.227,0,.454-.007.682a.6.6,0,0,1-.411.087c-.136,0-.273-.006-.409,0a.464.464,0,0,1-.4-.114l-.063-.165a.255.255,0,0,0-.026-.151,2.466,2.466,0,0,0-1.491-1.3A.281.281,0,0,1,493.383,302.484Z"
          transform="translate(6878.007 -2478.85)"
          fill="#74d2e7"
        />
        <path
          id="Path_529"
          data-name="Path 529"
          d="M491.029,405.578a2.1,2.1,0,0,1-.011-.619.12.12,0,0,1,.177-.107,1.73,1.73,0,0,1,1.029.962.259.259,0,0,1,.025.166l-.021.028h0a1.888,1.888,0,0,1-.731,0A.666.666,0,0,1,491.029,405.578Z"
          transform="translate(6880.343 -2580.496)"
          fill="#74d2e7"
        />
        <path
          id="Path_530"
          data-name="Path 530"
          d="M607.595,302.464c0,.085,0,.171,0,.256a.133.133,0,0,1-.18.138,1.4,1.4,0,0,1-.948-1.041c-.012-.113.025-.174.144-.173s.228,0,.341,0a.611.611,0,0,1,.637.649c0,.057,0,.114,0,.171Z"
          transform="translate(6766.837 -2479.051)"
          fill="#74d2e7"
        />
        <path
          id="Path_531"
          data-name="Path 531"
          d="M493.539,363.053a.282.282,0,0,0,.2.173,2.466,2.466,0,0,1,1.491,1.3.256.256,0,0,1,.026.151,2.546,2.546,0,0,0-1.553-1.352A.224.224,0,0,1,493.539,363.053Z"
          transform="translate(6877.852 -2539.418)"
          fill="#74d2e7"
        />
        <path
          id="Path_532-2"
          data-name="Path 532"
          d="M492.674,448.385a.666.666,0,0,0,.47.431,1.887,1.887,0,0,0,.731,0c0,.063,0,.14-.079.139a1.692,1.692,0,0,1-.768-.06A.574.574,0,0,1,492.674,448.385Z"
          transform="translate(6878.698 -2623.303)"
          fill="#74d2e7"
        />
        <path
          id="Path_533"
          data-name="Path 533"
          d="M608.647,320.719a1.14,1.14,0,0,0,.4.538,1.889,1.889,0,0,0,.671.289c-.023.059-.052.13-.128.1a1.69,1.69,0,0,1-.681-.361A.851.851,0,0,1,608.647,320.719Z"
          transform="translate(6764.692 -2497.803)"
          fill="#74d2e7"
        />
        <path
          id="Path_534"
          data-name="Path 534"
          d="M598.77,468.606a.463.463,0,0,0,.4.115c.136-.006.273,0,.409,0a.6.6,0,0,0,.411-.087.2.2,0,0,1-.221.2q-.382.007-.764,0C598.858,468.834,598.789,468.743,598.77,468.606Z"
          transform="translate(6774.402 -2643.181)"
          fill="#74d2e7"
        />
        <path
          id="Path_535"
          data-name="Path 535"
          d="M563.375,472.067l.021-.028C563.4,472.054,563.4,472.069,563.375,472.067Z"
          transform="translate(6809.197 -2646.555)"
          fill="#74d2e7"
        />
      </g>
    </svg>
  );
}

export default SvgMan;

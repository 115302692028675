import * as React from "react";

function SvgGreenTickIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Group_2278"
      data-name="Group 2278"
      xmlns="http://www.w3.org/2000/svg"
      width="16.847"
      height="16.847"
      viewBox="0 0 16.847 16.847"
    >
      <rect
        id="Rectangle_1905"
        data-name="Rectangle 1905"
        width="16.847"
        height="16.847"
        rx="8"
        fill="#4a8949"
      />
      <g
        id="Group_2195"
        data-name="Group 2195"
        transform="translate(14.758 12.316) rotate(172)"
        opacity="0.95"
      >
        <line
          id="Line_308"
          data-name="Line 308"
          y1="7.297"
          x2="7.297"
          transform="translate(0 0.543)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1313"
          data-name="Path 1313"
          d="M1.054,5.368,0,0"
          transform="translate(7.369 0.543) rotate(-31)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}

export default SvgGreenTickIcon;

/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import styled from "styled-components";
import { padding, width } from "styled-system";

export type ITextIconButton = ButtonProps;

const retrieveHoverBrightness = (variant, defaultValue) => {
  if (defaultValue) return defaultValue;
  if (variant === "yellow") return 1.1;
  if (variant === "green") return 1.2;
  if (variant === "blue") return 2.0;
  if (variant === "salmon") return 1.1;
  if (variant === "pink") return 0.95;
  if (variant === "white") return 1.1;
};

export const TextIconButton = styled(
  ({
    padding,
    rounded,
    uppercase,
    width,
    type,
    variant,
    hoverBrightness,
    children,
    ...rest
  }) => {
    return (
      <Button {...rest} data-testid="btn">
        {children}
      </Button>
    );
  }
)`
  --btn-height: 2.125rem;

  font-family: "Glacial Indifference";
  font-size: 1.125rem;
  font-weight: bold;
  line-height: 1;
  border-radius: ${({ rounded }) =>
    rounded ? "calc(var(--btn-height) / 2)" : "0"};
  position: relative;
  box-shadow: var(--box-shadow);
  border: ${({ variant = "yellow", type, theme }) =>
    type === "outlined"
      ? `0.2em solid ${
          variant === "white" ? theme.colors.transparent : theme.colors[variant]
        }`
      : "none"};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  color: ${({ variant = "yellow", type, theme }) => {
    return variant === "white"
      ? theme.colors.shades.grey[18]
      : type === "outlined"
      ? theme.colors[variant]
      : theme.colors.white;
  }};
  background: ${({ variant = "yellow", type, theme }) =>
    theme.colors[type === "outlined" ? "transparent" : variant]};
  height: var(--btn-height);
  max-width: 100%;
  ${width}
  ${padding}

  &:hover,
  &:focus {
    filter: ${({ variant = "yellow", hoverBrightness }) =>
      `brightness(${retrieveHoverBrightness(variant, hoverBrightness)})}`};
    color: ${({ variant = "yellow", type, theme }) =>
      `${theme.colors[type === "outlined" ? variant : "white"]} !important`};
    background: ${({ variant = "yellow", type, theme }) =>
      theme.colors[type === "outlined" ? "transparent" : variant]};
    border: ${({ variant = "yellow", type, theme }) =>
      type === "outlined" || variant === "white"
        ? `0.2em solid ${theme.colors[variant]} !important`
        : "none !important"};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.shades.grey[43]};
    background: ${({ theme }) => theme.colors.shades.grey[43]};
  }

  span {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
  }

  svg {
    position: absolute;
    top: -0.23rem;
    left: -0.23rem;

    path {
      fill: ${({ variant = "yellow", theme }) =>
        theme.colors[variant === "white" ? "grey" : variant]};
    }
  }
`;

import { createGlobalStyle } from "styled-components";

const KpiStyle = createGlobalStyle`

.kpi-main-container {
  .main-content {
    padding: 0.5rem 0 0 5rem;

    .kpi-action-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 1rem 0;

      .ant-select {
        flex: 0.5;
      }
    }

    .kpi-slider {
      --arrow-size: 2rem;
      --color: ${({ theme }) => theme.colors.darkBlue};

      width: 100%;

      .swiper-button-prev {
        color: var(--color);

        &::after {
          font-size: var(--arrow-size);
        }
      }

      .swiper-button-next {
        color: var(--color);

        &::after {
          font-size: var(--arrow-size);
        }
      }

      .kpi-percentage-card {
        box-shadow: var(--box-shadow);

        .ant-card-body {
          padding: 0;

          .kpi-green-title {
            font-family: var(--font-glacial);
            font-weight: 600;
            font-size: 15px;
            text-align: right;
            letter-spacing: 0.03em;
            line-height: 17px;
            padding: 1rem;

            &.lessThan {
              color: ${({ theme }) => theme.colors.shades.red[10]};
            }

            &.moreThan {
              color: ${({ theme }) => theme.colors.shades.green[1]};
            }
          }

          .kpi-title {
            font-family: var(--font-gilroy);
            font-weight: 600;
            font-size: 65px;
            letter-spacing: 0.04em;
            text-align: center;
            color: ${({ theme }) => theme.colors.darkBlue};
          }

          .kpi-footer {
            width: 100%;
            height: 51px;
            background: ${({ theme }) => theme.colors.darkBlue};
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              font-family: var(--font-glacial);
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              text-align: left;
              color: ${({ theme }) => theme.colors.white};
            }
          }
        }
      }
    }

    .kpi-card {
      box-shadow: var(--box-shadow);
    }

    .category-kpi-card {
      .category-kpi-graph {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0.2rem;
        width: 100%;
        flex: 2;
        margin: 1rem 0 0 0;

        canvas {
          --width: 20rem;

          max-width: var(--width);
          max-height: var(--width);
        }

        .categories-progress-bar-container {
          display: flex;
          flex-direction: column;
          flex: 1;
          gap: 1rem;
        }
      }
    }

    .kpi-members-container {
      gap: 0.5rem;

      .adherants-gender {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        justify-content: center;
        align-items: center;

        .members-gender-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 0.5rem;

          label {
            font-family: var(--font-glacial);
            font-weight: 600;
            font-size: 1.5625rem;
            text-align: right;

            &.man {
              color: ${({ theme }) => theme.colors.turquoise};
            }

            &.woman {
              color: ${({ theme }) => theme.colors.darkBlue};
            }
          }

          p {
            &.lessThan {
              color: ${({ theme }) => theme.colors.shades.red[10]};
            }

            &.moreThan {
              color: ${({ theme }) => theme.colors.shades.green[3]};
            }
          }
        }
      }

      .members-average-age-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: var(--font-glacial);

        label {
          font-weight: 600;
          font-size: 3.125rem;
          color: ${({ theme }) => theme.colors.darkBlue};
        }

        p {
          font-weight: 400;
          font-size: 1rem;
          color: ${({ theme }) => theme.colors.shades.grey[32]};
        }
      }
    }

    .members-kpi-swiper {
      --arrow-size: 2rem;
      --color: ${({ theme }) => theme.colors.darkBlue};

      width: 100%;
      max-width: 20rem;
      min-height: 4rem;
      padding: 0 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .swiper-button-prev {
        color: var(--color);

        &::after {
          font-size: var(--arrow-size);
        }
      }

      .swiper-button-next {
        color: var(--color);

        &::after {
          font-size: var(--arrow-size);
        }
      }

      .swiper-slide {
        width: 100% !important;
      }
    }

    .evolution-kpi-percentage {
      font-family: var(--font-glacial);
      font-weight: 600;
      font-size: 1.25rem;

      &.lessThan {
        color: ${({ theme }) => theme.colors.shades.red[10]};
      }

      &.moreThan {
        color: ${({ theme }) => theme.colors.shades.green[3]};
      }
    }
  }
}

.ant-select-dropdown {
  button {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.shades.grey[25]};
    color: ${({ theme }) => theme.colors.white};
  }
}`;

export default KpiStyle;

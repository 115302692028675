import React from "react";
import { Doughnut } from "react-chartjs-2";

export const DoughnutChart = ({
  labels = [],
  dataValues = [],
  colors = [],
}) => {
  const data = {
    labels: labels,
    datasets: [
      {
        label: "# of Votes",
        data: dataValues,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 0,
        spacing: 1,
      },
    ],
  };

  const options = {
    cutout: 55,
    radius: 65,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: false,
      },
    },
    // scales: {
    //   y: {
    //     beginAtZero: true
    //   }
    // }
  };

  return (
    <>
      <Doughnut data={data} options={options} />
    </>
  );
};

export default DoughnutChart;

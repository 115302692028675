import * as React from "react";

function SvgFolderIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.156"
      height="13.881"
      viewBox="0 0 17.156 13.881"
    >
      <g
        id="noun_Search_Folder_775907"
        data-name="noun_Search Folder_775907"
        transform="translate(0.25 0.25)"
      >
        <g id="Group_2435" data-name="Group 2435">
          <path
            id="Path_1432"
            data-name="Path 1432"
            d="M36.934,28.531H28.215c-.443,0-.649-.3-.848-.6a1.119,1.119,0,0,0-.988-.649H22.638A1.24,1.24,0,0,0,21.6,28.654v1.684a1.172,1.172,0,0,1,1.036-.649h14.3a1.172,1.172,0,0,1,1.036.649V29.9A1.24,1.24,0,0,0,36.934,28.531Z"
            transform="translate(-21.458 -27.141)"
            fill="none"
            stroke="#003466"
            strokeWidth="0.5"
          />
          <path
            id="Path_1433"
            data-name="Path 1433"
            d="M36.578,28.031H27.859c-.367,0-.534-.247-.729-.534a1.251,1.251,0,0,0-1.107-.712H22.282A1.38,1.38,0,0,0,21.1,28.3V38.944a1.2,1.2,0,0,0,1.18,1.221h14.3a1.2,1.2,0,0,0,1.18-1.221v-9.4A1.38,1.38,0,0,0,36.578,28.031Zm1.036,2.756v8.157a1.058,1.058,0,0,1-1.036,1.077h-14.3a1.059,1.059,0,0,1-1.036-1.077v-8.39a1.058,1.058,0,0,1,1.036-1.077h14.3a1.059,1.059,0,0,1,1.036,1.077Zm0-.8a1.172,1.172,0,0,0-1.036-.649h-14.3a1.172,1.172,0,0,0-1.036.649V28.3a1.239,1.239,0,0,1,1.036-1.369h3.741a1.119,1.119,0,0,1,.988.649c.2.294.405.6.848.6h8.719a1.24,1.24,0,0,1,1.036,1.369Z"
            transform="translate(-21.102 -26.785)"
            fill="#003466"
            stroke="#003466"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgFolderIcon;

import React, { Fragment, useEffect, useState } from "react";
import MainLayout from "layouts/main";
import { useTranslation } from "react-i18next";
import DocumentStyle from "styles/documentStyles";
import {
  getContractsDocuments,
  getDatabasesDocuments,
  getInvoicesDocuments,
  getOtherDocuments,
} from "services/document";
import { Avatar, Card } from "antd";
import { LargerExcelIcon, PdfIcon } from "components/icons";
import { FILE_TYPES } from "../../constants";
import { format } from "date-fns";
import { UserOutlined } from "@ant-design/icons";
import { fullImageUrl } from "components/utils";

const Documents = () => {
  const { t } = useTranslation(["common", "document"]);
  const [contracts, setContracts] = useState(null);
  const [databases, setDatabases] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [otherDocuments, setOtherDocuments] = useState(null);

  useEffect(() => {
    Promise.allSettled([
      getDatabasesDocuments()
        .then((resp) => {
          console.log("setDatabases resp :>> ", resp);
          setDatabases(resp);
        })
        .catch((e) => console.log(e)),
      getContractsDocuments()
        .then((resp) => {
          console.log("CONTRACTS resp :>> ", resp);
          setContracts(resp);
        })
        .catch((e) => console.log(e)),
      getInvoicesDocuments()
        .then((resp) => {
          console.log("INVOICES resp :>> ", resp);
          setInvoices(resp);
        })
        .catch((e) => console.log(e)),
      getOtherDocuments()
        .then((resp) => {
          console.log("OTHERS resp :>> ", resp);
          setOtherDocuments(resp);
        })
        .catch((e) => console.log(e)),
    ]);
  }, []);

  const retrieveFileIcon = (fileType) => {
    if (fileType === FILE_TYPES.PDF) {
      return <PdfIcon />;
    }
    if (fileType === FILE_TYPES.EXCEL) {
      return <LargerExcelIcon />;
    }
  };

  const retriveFileName = (filename) =>
    filename && filename.split(".")?.length > 0 && filename.split(".")[0];

  const handleDownloadDocument = (url) => () => {
    window.open(`${process.env.REACT_APP_S3_CDN_URL}${url}`);
  };

  const retrieveDate = (date) => format(new Date(date), "yyyy");

  const renderDocument = (item) => (
    <Card
      key={item?.id}
      className="document-card"
      hoverable
      onClick={handleDownloadDocument(item?.url)}
    >
      {retrieveFileIcon(item?.fileType)}
      <div className="document-info-container">
        <label>{retriveFileName(item?.filename)}</label>
        <p>{retrieveDate(item?.createdAt)}</p>
      </div>
    </Card>
  );

  return (
    <Fragment>
      <DocumentStyle />
      <MainLayout className="documents-main-container">
        <section className="contracts-documents-container">
          <h3>{t`document:contracts`}</h3>
          <div className="documents-container">
            {contracts?.map((item) => (
              <Card
                key={item?.id}
                className="contract-document-card"
                hoverable
                onClick={handleDownloadDocument(item?.url)}
              >
                {retrieveFileIcon(item.fileType)}
                <div className="document-info-container">
                  <label>{retriveFileName(item.filename)}</label>
                  <p>{retrieveDate(item?.createdAt)}</p>
                  <div className="contract-imported-by-info">
                    <label>
                      {t("document:importedBy", {
                        userName: item?.uploadedByUser,
                      })}
                    </label>
                    <Avatar
                      icon={<UserOutlined />}
                      src={fullImageUrl(item?.uploadedByUserImageUrls, "sm")}
                    />
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </section>
        <section className="invoices-documents-container">
          <h3>{t`document:invoices`}</h3>
          <div className="documents-container">
            {invoices?.map(renderDocument)}
          </div>
        </section>
        <section className="databases-documents-container">
          <h3>{t`document:databases`}</h3>
          <div className="documents-container">
            {databases?.map(renderDocument)}
          </div>
        </section>
        <section className="other-documents-container">
          <h3>{t`document:other`}</h3>
          <div className="documents-container">
            {otherDocuments?.map(renderDocument)}
          </div>
        </section>
      </MainLayout>
    </Fragment>
  );
};

export default Documents;

import { createGlobalStyle } from "styled-components";

const DocumentStyle = createGlobalStyle`
  .documents-main-container {
    .main-content {
      background: ${({ theme }) => theme.colors.shades.grey[70]};
      display: flex;
      flex-direction: column;
      gap: 2rem;

      h3 {
        color: ${({ theme }) => theme.colors.shades.grey[27]};
      }

      .contracts-documents-container {
        .contract-document-card {
          box-shadow: var(--box-shadow);
          width: fit-content;
          max-width: 25rem;

          .ant-card-body {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
            padding: 1rem 0;
            font-family: var(--font-glacial);
            font-size: 0.75rem;
            color: ${({ theme }) => theme.colors.shades.grey[32]};

            svg {
              width: 100%;
            }

            .document-info-container {
              width: 100%;

              label {
                font-weight: 600;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }

              p {
                font-weight: 400;
              }
            } 

            .contract-imported-by-info {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: center;
              gap: 0.5rem;
              width: 100%;

              label {
                font-weight: 400;
                font-size: 0.625rem;
              }
            }
          }
        }
      }
      
      .documents-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }


      .document-card {
        box-shadow: var(--box-shadow);
        width: fit-content;
        max-width: 8rem;
        min-width: 6rem;

        .ant-card-body {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 0.5rem;
          padding: 0.5rem 1rem;
          font-family: var(--font-glacial);
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors.shades.grey[32]};

          .document-info-container {
            width: 100%;

            label {
              font-weight: 600;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            p {
              font-weight: 400;
            }
          } 
        }
      }
    }
  }
`;

export default DocumentStyle;

import * as React from "react";

function SvgPlusIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="41"
      viewBox="0 0 18 41"
    >
      <text
        id="_"
        data-name="+"
        transform="translate(9 32)"
        fill="#74d2e7"
        stroke="#74d2e7"
        strokeLinecap="round"
        strokeWidth="1"
        fontSize="33"
        fontFamily="GlacialIndifference-Bold, Glacial Indifference"
        fontWeight="700"
      >
        <tspan x="-7.26" y="0">
          +
        </tspan>
      </text>
    </svg>
  );
}

export default SvgPlusIcon;

import React, { useContext } from "react";
import { ByNeosilverIcon } from "components/icons";
import { Affix, Avatar, Col, Row } from "antd";
import { GlobalContext } from "../../contexts/global";
import { UserOutlined } from "@ant-design/icons";
import { fullImageUrl } from "../utils";
import styled from "styled-components";
import { Logo } from "components";

export const Header = styled(({ className, ...rest }) => {
  const { currentUser } = useContext(GlobalContext);
  return (
    <Affix
      {...rest}
      offsetTop={0}
      data-testid="affix"
      className={`${className} main-custom-header`}
    >
      <Row justify="space-between">
        <Col className="partner-logo-header-container">
          <div className="partner-logo-icon">
            {currentUser?.partnerLogo ? (
              <img
                src={fullImageUrl(currentUser?.partnerLogo, "md")}
                alt="partner's logo"
              />
            ) : (
              <Logo />
            )}
          </div>
          {/* <ByNeosilverIcon /> */}
        </Col>
        <Col>
          {currentUser?.id && (
            <Avatar
              src={fullImageUrl(currentUser?.imageUrls, "sm")}
              size={{
                xs: 48,
                sm: 48,
                md: 48,
                lg: 48,
                xl: 48,
                xxl: 48,
              }}
              className={currentUser?.id ? "" : "header-avatar-logged-out"}
              icon={currentUser?.id ? <UserOutlined /> : null}
            />
          )}
        </Col>
      </Row>
    </Affix>
  );
})`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 1px -1px 6px #000000b8;
  padding: 1rem;
  z-index: 99;
  position: sticky;
  top: 0;

  .partner-logo-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .partner-logo-icon {
      max-width: 15rem;
      min-width: 10rem;
      height: 3rem;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    svg {
      display: flex;
      align-self: flex-end;
      position: absolute;
      bottom: 0;
    }
  }
`;

import { createGlobalStyle } from "styled-components";

const SignInStyle = createGlobalStyle`
.page-header {
  background: ${({ theme }) => theme.colors.white};
  padding: 2rem 3rem;
  box-shadow: var(--box-shadow);
}

.signin-content-container {
  width: 100%;
  max-width: 45rem;
  margin: 4rem auto;
  border: none;
}

.neosilver-logo-container {
  width: 15rem;
  max-width: 100%;
  margin: 0 auto;
}

.signin-fields-container {
  width: 100%;
  max-width: 100%;
}

.sub-heading {
  margin: 0.5rem 0 2rem 0;
}

.form-footer-text, .sub-heading {
  font-family: var(--font-gilroy);
  font-size: 1rem;
  font-weight: 200;
  color: ${({ theme }) => theme.colors.shades.grey[17]};
}

.forgot-pwd {
  text-decoration: underline;
}

.signin-btn-container {
  margin: 1rem 0 0 0;
}

.connect {
  font-family: var(--font-gilroy);
  font-size: rem;
  font-weight: 200;
}
`;

export default SignInStyle;

import React from "react";
import { Input } from "antd";
import { InputProps } from "antd/lib/input";
import styled from "styled-components";
import { color, borderColor, height } from "styled-system";

const { Password } = Input;

export type ITextField = InputProps;

export const TextField = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ placeholderColor, placeholder, ...rest }) => (
    <Input {...rest} data-testid="text-field" placeholder={placeholder} />
  )
)`
  border-radius: ${(props) => props.radius};
  border: ${({ theme, ...props }) =>
    props.border || `0.0625rem solid ${theme.colors.shades.grey[25]}7D`};
  font-family: ${(props) => props.fontFamily || "Glacial Indifference"};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight || "normal"};

  &::placeholder {
    color: ${(props) => props.placeholderColor};
  }

  ${height}
  ${borderColor}
  ${color}
`;

import * as React from "react";

function SvgUsersIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.2"
      height="20.2"
      viewBox="0 0 30.2 20.2"
    >
      <g id="noun_team_3784301" transform="translate(0.1 0.1)">
        <path
          id="Path_451"
          data-name="Path 451"
          d="M11.913,9.25A3.834,3.834,0,0,1,15.826,5.5a3.834,3.834,0,0,1,3.913,3.75A3.834,3.834,0,0,1,15.826,13,3.834,3.834,0,0,1,11.913,9.25Zm6.805,4.162A4.945,4.945,0,0,0,21.043,9.25a5.112,5.112,0,0,0-5.217-5,5.112,5.112,0,0,0-5.217,5,4.945,4.945,0,0,0,2.325,4.162A10.609,10.609,0,0,0,5.391,23.625a.653.653,0,0,0,1.3,0,9.256,9.256,0,0,1,9.13-9.375,9.256,9.256,0,0,1,9.13,9.375.653.653,0,0,0,1.3,0A10.609,10.609,0,0,0,18.718,13.412Zm3.3-.725a.639.639,0,0,1,.652-.625,2.556,2.556,0,0,0,2.609-2.5,2.556,2.556,0,0,0-2.609-2.5.626.626,0,1,1,0-1.25,3.834,3.834,0,0,1,3.913,3.75,3.685,3.685,0,0,1-1.445,2.91A8.115,8.115,0,0,1,30.5,20.188a.653.653,0,0,1-1.3,0,6.706,6.706,0,0,0-6.522-6.875A.639.639,0,0,1,22.022,12.687Zm-13.043,0a.639.639,0,0,0-.652-.625,2.556,2.556,0,0,1-2.609-2.5,2.556,2.556,0,0,1,2.609-2.5.626.626,0,1,0,0-1.25,3.834,3.834,0,0,0-3.913,3.75,3.685,3.685,0,0,0,1.445,2.91A8.115,8.115,0,0,0,.5,20.188a.639.639,0,0,0,.652.625.639.639,0,0,0,.652-.625,6.706,6.706,0,0,1,6.522-6.875A.639.639,0,0,0,8.978,12.687Z"
          transform="translate(-0.5 -4.25)"
          fill={props.fill ?? "#b0b0b0"}
          stroke={props.stroke ?? "#b0b0b0"}
          strokeWidth="0.2"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
}

export default SvgUsersIcon;

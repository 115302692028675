import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MainLayout from "layouts/main";
import { useTranslation } from "react-i18next";
import { Row, Col, Card, Select, Button } from "antd";
import LineChart from "components/users/chart";
import {
  CityKpiProgress,
  DoughnutChart,
  KpiProgressBar,
  KpiToggle,
  RadarChart,
  Spacer,
} from "components";
import { ManIcon, WomanIcon } from "components/icons";
import KpiStyle from "styles/kpiStyles";
import {
  getActivityCategories,
  getCityDistribution,
  getEvolutionKpi,
  getGenderBreakdown,
  getNumberOfOrders,
  getNumberOfSubscriptions,
  getNumberOfUsers,
  getTokensUsed,
} from "services/kpi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import { useTheme } from "definitions/styled-components";
import { exportToCSV } from "components/utils";

SwiperCore.use([Navigation, Pagination]);

const Kpis = () => {
  const { t } = useTranslation(["common", "kpi"]);
  const [currentMainFilter, setCurrentMainFilter] = useState("today");
  const [kpiCardValues, setKpiCardValues] = useState(null);
  const [categories, setCategories] = useState(null);
  const [members, setMembers] = useState(null);
  const [membersLocation, setMembersLocation] = useState(null);
  const [evolutions, setEvolutions] = useState(null);
  const { theme } = useTheme();
  const selectedExportValues = useRef(null);
  const orders = useRef(null);
  const users = useRef(null);
  const tokens = useRef(null);
  const subscriptions = useRef(null);

  const handleChange = (e) => setCurrentMainFilter(e.target.value);

  const PROGRESS_BAR_COLORS = [
    theme.colors.shades.blue[4],
    theme.colors.shades.yellow[1],
    theme.colors.shades.green[2],
    theme.colors.shades.blue[5],
    theme.colors.shades.red[9],
    theme.colors.shades.grey[25],
    theme.colors.turquoise,
    theme.colors.shades.blue[6],
    theme.colors.shades.salmon[2],
    theme.colors.shades.salmon[7],
  ];

  useEffect(() => {
    Promise.allSettled([
      getNumberOfOrders()
        .then((resp) => {
          orders.current = resp;
          return { [t`kpi:kpiCard.order`]: resp };
        })
        .catch((e) => {
          console.log("e :>> ", e);
        }),
      getNumberOfUsers()
        .then((resp) => {
          users.current = resp;
          return { [t`kpi:kpiCard.user`]: resp };
        })
        .catch((e) => {
          console.log(e);
        }),
      getTokensUsed()
        .then((resp) => {
          tokens.current = resp;
          return { [t`kpi:kpiCard.token`]: resp };
        })
        .catch((e) => {
          console.log("e :>> ", e);
        }),
      getNumberOfSubscriptions()
        .then((resp) => {
          subscriptions.current = resp;
          return { [t`kpi:kpiCard.subscription`]: resp };
        })
        .catch((e) => {
          console.log("e :>> ", e);
        }),
      getActivityCategories()
        .then((resp) => {
          setCategories(resp);
        })
        .catch((e) => {
          console.log("e :>> ", e);
        }),
      getGenderBreakdown()
        .then((resp) => {
          setMembers(resp);
        })
        .catch((e) => console.log("e :>> ", e)),
      getCityDistribution()
        .then((resp) => {
          setMembersLocation(resp);
        })
        .catch((e) => console.log("e :>> ", e)),
      getEvolutionKpi()
        .then((resp) => {
          setEvolutions(resp);
        })
        .catch((e) => console.log("e :>> ", e)),
    ]).then((kpisResponse) => {
      const KPIS = Object.assign(
        {},
        ...kpisResponse.map((item) => ({ ...item["value"] }))
      );
      setKpiCardValues(KPIS);
    });
  }, []);

  const radialLabels = useCallback(
    () =>
      categories && categories[currentMainFilter].map((item) => item.category),
    [categories, currentMainFilter]
  );

  const radialValues = useCallback(
    () =>
      categories &&
      categories[currentMainFilter].map((item) => item.activitiesCount),
    [categories, currentMainFilter]
  );

  const membersInfo = useCallback(
    () =>
      members &&
      members[currentMainFilter]?.userGenderInfo?.reduce(
        (acc, item) => ({
          ...acc,
          [item.gender]: {
            ...item,
          },
        }),
        {}
      ),
    [members, currentMainFilter]
  );

  const membersDoughnutLabels = useMemo(
    () =>
      membersInfo() && [
        membersInfo()["female"]?.gender,
        membersInfo()["male"]?.gender,
      ],
    [membersInfo()]
  );

  const membersDoughnutValues = useMemo(
    () =>
      membersInfo() && [
        membersInfo()["female"]?.percentage,
        membersInfo()["male"]?.percentage,
      ],
    [membersInfo()]
  );

  const memberFemalePercentage = useMemo(
    () => members && Math.round(members[currentMainFilter]?.diffFemale),
    [members, currentMainFilter]
  );

  const memberMalePercentage = useMemo(
    () => members && Math.round(members[currentMainFilter]?.diffMale),
    [members, currentMainFilter]
  );

  const renderKpiCard = (kpi, title) => (
    <Card className="kpi-percentage-card">
      <p
        className={`${
          kpi[currentMainFilter]?.diff < 0 ? "lessThan " : "moreThan "
        }kpi-green-title`}
      >
        {kpi[currentMainFilter]?.diff <= 0
          ? `${Math.round(kpi[currentMainFilter]?.diff)}`
          : `+${Math.round(kpi[currentMainFilter]?.diff)}`}
        %
      </p>
      <p className="kpi-title">{kpi[currentMainFilter]?.count || 0}</p>
      <div className="kpi-footer">
        <p>{title}</p>
      </div>
    </Card>
  );

  const handleExportData = () => {
    const exportOptions = selectedExportValues?.current;
    exportOptions?.map((item) => {
      if (item === "order") {
        exportToCSV(
          [orders.current[currentMainFilter]],
          "export_nombre_de_commandes"
        );
      }
      if (item === "user") {
        exportToCSV(
          [users.current[currentMainFilter]],
          "export_nombre_de_utilisateurs"
        );
      }
      if (item === "token") {
        exportToCSV(
          [tokens.current[currentMainFilter]],
          "export_nombre_de_tokens"
        );
      }
      if (item === "subscription") {
        exportToCSV(
          [subscriptions.current[currentMainFilter]],
          "export_nombre_de_inscriptions"
        );
      }
      if (item === "category") {
        exportToCSV(
          categories[currentMainFilter],
          "export_nombre_de_catégories_prisées"
        );
      }
      if (item === "member") {
        exportToCSV([members[currentMainFilter]], "export_nombre_d_adhérents");
      }
      if (item === "evolution") {
        exportToCSV(
          evolutions[currentMainFilter]?.export,
          "export_nombre_de_evolutions"
        );
      }
    });
  };

  const handleExportOptionChange = (values) => {
    selectedExportValues.current = values;
  };

  return (
    <Fragment>
      <KpiStyle />
      <MainLayout className="kpi-main-container">
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
            <div className="kpi-action-container">
              <KpiToggle
                opts={["today", "week", "month", "year"]}
                value={currentMainFilter}
                handleChange={handleChange}
                color="darkBlue"
                hasToday={true}
              />
              <Select
                mode="multiple"
                showArrow
                maxTagCount={1}
                placeholder={t`kpi:export.placeholder`}
                options={[
                  { label: t`kpi:kpiCard.order`, value: "order" },
                  { label: t`kpi:kpiCard.user`, value: "user" },
                  { label: t`kpi:kpiCard.token`, value: "token" },
                  { label: t`kpi:kpiCard.subscription`, value: "subscription" },
                  { label: t`kpi:category.title`, value: "category" },
                  {
                    label: t`kpi:export.members`,
                    value: "member",
                  },
                  {
                    label: t`kpi:evolution.title`,
                    value: "evolution",
                  },
                ]}
                onChange={handleExportOptionChange}
                dropdownRender={(menu) => (
                  <div>
                    {menu}
                    <div>
                      <Button
                        onClick={handleExportData}
                      >{t`kpi:export.title`}</Button>
                    </div>
                  </div>
                )}
              />
            </div>
            <Row gutter={[8, 8]}>
              <Swiper
                slidesPerView={4}
                spaceBetween={10}
                navigation={true}
                className="kpi-slider"
              >
                {kpiCardValues &&
                  Object.entries(kpiCardValues)?.map(([key, kpi]) => (
                    <SwiperSlide key={`kpi-slide-${key}`}>
                      {renderKpiCard(kpi, key)}
                    </SwiperSlide>
                  ))}
              </Swiper>
              <Col xs={24}>
                <Row gutter={[8, 8]}>
                  <Col xs={24} lg={16}>
                    <Card className="kpi-card category-kpi-card">
                      <h4>{t`kpi:category.title`}</h4>
                      <div className="category-kpi-graph">
                        <RadarChart
                          labels={radialLabels()}
                          dataValues={radialValues()}
                        />
                        <div className="categories-progress-bar-container">
                          {categories &&
                            categories[currentMainFilter]?.map(
                              (item, index) => (
                                <KpiProgressBar
                                  key={index}
                                  label={item.category}
                                  percentage={item.percentage}
                                  description={item.activitiesCount}
                                  showParticipation={true}
                                  showBadge={true}
                                  color={
                                    PROGRESS_BAR_COLORS[
                                      index % PROGRESS_BAR_COLORS.length
                                    ]
                                  }
                                />
                              )
                            )}
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col xs={24} lg={8}>
                    <Card className="kpi-card">
                      <section className="kpi-members-container">
                        <h4>{t`kpi:members.title`}</h4>
                        <div className="adherants-doughnut-chart">
                          <DoughnutChart
                            labels={membersDoughnutLabels}
                            dataValues={membersDoughnutValues}
                            colors={[
                              theme.colors.darkBlue,
                              theme.colors.turquoise,
                            ]}
                          />
                        </div>
                        <div className="adherants-gender">
                          <div className="members-gender-content">
                            <WomanIcon />
                            <label className="woman">
                              {(membersInfo() &&
                                membersInfo()["female"]?.count) ||
                                0}
                            </label>
                            <p
                              className={
                                memberFemalePercentage < 0
                                  ? `lessThan`
                                  : `moreThan`
                              }
                            >
                              {memberFemalePercentage <= 0
                                ? `${memberFemalePercentage}`
                                : `+${memberFemalePercentage}`}
                              %
                            </p>
                          </div>
                          <div className="members-gender-content">
                            <ManIcon />
                            <label className="man">
                              {(membersInfo() &&
                                membersInfo()["male"]?.count) ||
                                0}
                            </label>
                            <p
                              className={
                                memberMalePercentage < 0
                                  ? `lessThan`
                                  : `moreThan`
                              }
                            >
                              {memberMalePercentage <= 0
                                ? `${memberMalePercentage}`
                                : `+${memberMalePercentage}`}
                              %
                            </p>
                          </div>
                        </div>
                        {members && members[currentMainFilter]?.averageAge && (
                          <div className="members-average-age-container">
                            <label>
                              {members &&
                                members[currentMainFilter]?.averageAge}
                              <small>{t`common:kpi.age`}</small>
                            </label>
                            <p>{t`kpi:members.age`}</p>
                          </div>
                        )}
                        {membersLocation && (
                          <Swiper
                            slidesPerView={1}
                            navigation
                            pagination
                            className="members-kpi-swiper"
                          >
                            <SwiperSlide>
                              {membersLocation[currentMainFilter]?.map(
                                (item, index) => (
                                  <KpiProgressBar
                                    key={index}
                                    label={item.city}
                                    percentage={item.percent}
                                    showInfo={false}
                                    count={item.personCount}
                                    color={
                                      PROGRESS_BAR_COLORS[
                                        index % PROGRESS_BAR_COLORS.length
                                      ]
                                    }
                                  />
                                )
                              )}
                            </SwiperSlide>
                          </Swiper>
                        )}
                      </section>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <Card className="kpi-card">
              <h4>{t`kpi:evolution.title`}</h4>
              {evolutions && (
                <Fragment>
                  <label
                    className={`${
                      evolutions[currentMainFilter]?.diff < 0
                        ? `lessThan `
                        : `moreThan `
                    }evolution-kpi-percentage`}
                  >
                    {evolutions[currentMainFilter]?.diff <= 0
                      ? `${Math.round(evolutions[currentMainFilter]?.diff)}`
                      : `+${Math.round(evolutions[currentMainFilter]?.diff)}`}
                    %
                  </label>
                  <LineChart
                    xSeries={evolutions[currentMainFilter]?.graphSeries?.arrX}
                    ySeries={evolutions[currentMainFilter]?.graphSeries?.arrY}
                    title={t`kpi:evolution.description`}
                  />
                </Fragment>
              )}
              <Spacer space={20} />
              <CityKpiProgress displayExport={false} />
            </Card>
          </Col>
        </Row>
      </MainLayout>
    </Fragment>
  );
};

export default Kpis;

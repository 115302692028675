/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";
import {
  Select,
  Radio,
  Card,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Table,
  Form,
  Input,
  Button,
} from "antd";
import { TokenIcon, DecorationLine, FolderIcon } from "components/icons";
import { TextButton } from "components/text-button";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { Spacer, TextIconButton } from "components";
import {
  CaretDownOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  createTokens,
  readUploadExcelFile,
  retrieveExcelFiles,
  uploadPartnerDocument,
  userEmailList,
} from "services/token";
import { IconButton } from "@material-ui/core";
import SvgPlusIcon from "components/icons/PlusIcon";
import FormItem from "antd/lib/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import SvgExcelIcon from "components/icons/ExcelIcon";
import { FileUploader } from "components/file-uploader";
import { getPartnerInfo } from "services/parameter";

const CreateToken = (props) => {
  const MAX_CHARS = 150;
  const MAX_FILE_SIZE = 5 * 1024 * 1024;
  const { Option } = Select;
  // const [tokenType, setTokenType] = useState('mail');
  // const plainOptions = ['Nouveau groupe', 'Groupe existant', 'Individuel'];
  const { t } = useTranslation(["common", "token"]);
  // const [radioValue, setRadioValue] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showAmountModal, setShowAmountModal] = useState(false);
  const [groupName, setGroupName] = useState("");
  // const [date, setDate] = useState(null);
  const [group, setGroup] = useState("");
  const [userEmails, setUserEmails] = useState([]);
  const [selectedInvidualList, setSelectedInvidualList] = useState([]);
  const [selectedInvidual, setSelectedIndividual] = useState();
  const [mailMessage, setMailMessage] = useState("");
  const [mailMessageError, setMailMessageError] = useState("");
  const [amount, setAmount] = useState(null);
  const [excelFileList, setExcelFileList] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [emailsList, setEmailsList] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [fileId, setFileId] = useState(0);
  const docBlob = useRef(null);
  const docFile = useRef(null);
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const [customAmountMessage, setCustomAmountMessage] = useState("");
  const [customAmount, setCustomAmount] = useState(null);
  const [groupNameError, setGroupNameError] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [displayGroupName, setDisplayGroupName] = useState("");
  const [availableCredits, setAvailbleCredits] = useState(0);

  //getPartnerInfo

  useEffect(() => {
    async function fetchUsers() {
      const response = await userEmailList();
      const users = response;
      const usersWithKey = users.map((e, i) => ({ ...e, key: i }));
      setUserEmails(usersWithKey);

      const fileResponse = await retrieveExcelFiles();
      const fileRespWithKey = fileResponse.map((e, i) => ({ ...e, key: i }));
      setExcelFileList(fileRespWithKey);

      const partnerInfo = await getPartnerInfo();
      setAvailbleCredits(partnerInfo.creditBalance);
    }
    fetchUsers();
  }, []);

  useEffect(() => {
    if (customAmount) {
      if (customAmount < 5) {
        setCustomAmountMessage(
          t("token:message.error.customAmountMessage.incorrectAmount")
        );
      } else setCustomAmountMessage("");
    }
  }, [customAmount, t]);

  useEffect(() => {
    if (groupName) {
      setGroupNameError("");
    } else {
      setGroupNameError(t("token:message.error.fileImport.required"));
    }
  }, [groupName, t]);

  useEffect(() => {
    if (mailMessage) {
      if (mailMessage.length > MAX_CHARS) {
        setMailMessageError(
          t("token:message.error.mailMessage.lengthExceeded")
        );
      } else setMailMessageError("");
    } else {
      setMailMessageError(t("token:message.error.mailMessage.required"));
    }
  }, [mailMessage, t]);

  // const onDateChange = (date, dateString) => {
  //   setDate(date);
  // };

  const GROUPS = [
    {
      label: t`token:groups.newGroup`,
      value: "NEW",
    },
    {
      label: t`token:groups.existingGroup`,
      value: "EXISTING",
    },
    {
      label: t`token:groups.individual`,
      value: "INDIVIDUAL",
    },
  ];

  const columns = [
    {
      key: "email",
      dataIndex: "email",
    },
    {
      key: "delete",
      dataIndex: "id",
      align: "center" as const,
      render: (checked, record) => {
        return (
          <IconButton
            aria-label="delete"
            style={{ width: "15px", height: "15px", color: "red" }}
            onClick={() => removeSelectedUser(record)}
          >
            <CloseCircleOutlined />
          </IconButton>
        );
      },
    },
  ];

  const columnsExcelFiles = [
    {
      key: "icon",
      dataIndex: "id",
      render: () => {
        return <SvgExcelIcon />;
      },
    },
    {
      key: "filename",
      dataIndex: "groupName",
      render: (checked, record) => {
        return (
          <div>
            {record.groupName || record.filename}{" "}
            {record.id === fileId ? (
              <CheckCircleFilled style={{ color: "green" }} />
            ) : null}
          </div>
        );
      },
    },
    {
      key: "delete",
      dataIndex: "id",
      align: "center" as const,
      render: (checked, record) => {
        return (
          <TextButton
            variant="darkBlue"
            type="outlined"
            width="5rem"
            rounded
            style={{ width: "unset", height: "auto" }}
            onClick={() => selectExcelFile(checked, record)}
          >
            {t`token:btnChoose`}
          </TextButton>
        );
      },
    },
  ];

  const AMOUNTS = [
    {
      key: 20,
      label: "20 €",
    },
    {
      key: 40,
      label: "40 €",
    },
    {
      key: 60,
      label: "60 €",
    },
    {
      key: 80,
      label: "80 €",
    },
    {
      key: 100,
      label: "100 €",
    },
  ];

  const handleLevelChange = (event) => {
    setGroup(event.target.value);
  };

  const addSelectedIndividuals = () => {
    if (selectedInvidual) {
      const user = userEmails.find((x) => x.id === selectedInvidual);
      const found = selectedInvidualList.find((x) => x.id === user.id);
      if (found) message.error(t`token:message.error.userExist`, 5);
      else {
        const tmpArr = [...selectedInvidualList, user];
        setSelectedInvidualList(tmpArr);
        setUserCount(tmpArr.length);

        calculateTotalAmount(tmpArr.length, amount);
      }
    }
  };

  const removeSelectedUser = (record) => {
    const index = selectedInvidualList.indexOf(record);
    const removeArr = selectedInvidualList;
    if (index > -1) {
      removeArr.splice(index, 1);
      const tmpArr = [...removeArr];
      setSelectedInvidualList(tmpArr);
      setUserCount(tmpArr.length);

      calculateTotalAmount(tmpArr.length, amount);
    }
  };

  const handleSelectUserNeo = (event) => {
    setSelectedIndividual(event);
  };

  const handleAmountChange = (event) => {
    if (event === "other") {
      setShowAmountModal(true);
    } else {
      setAmount(event);
      calculateTotalAmount(userCount, event);
    }
  };

  const handleModalAmountChange = (event) => {
    setAmount(event.target.value);
    setCustomAmount(event.target.value);
    calculateTotalAmount(userCount, event.target.value);
  };

  const handleModalAmountCancel = () => {
    setShowAmountModal(false);
    setAmount(null);
    setCustomAmount(null);
    calculateTotalAmount(userCount, 0);
  };

  const handleModalAmountSave = () => {
    setShowAmountModal(false);
    calculateTotalAmount(userCount, amount);
  };

  const handleModalImportGroupNameChange = (event) => {
    setGroupName(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMailMessage(event.target.value);
  };

  const selectExcelFile = async (key, record) => {
    const payload = {
      type: "EXCEL",
      path: record.url,
    };
    setSelectedFile(record.filename);
    setFileId(record.id);
    const response = await readUploadExcelFile(payload);
    if (response) {
      setEmailsList(response.emails);
      setUserCount(response.count);
      calculateTotalAmount(response.count, amount);
    }
  };

  const generateTokens = async () => {
    //Validation
    let payload = null;
    if (Number(availableCredits) < Number(totalAmount)) {
      message.error(t("token:message.error.notEnoughCredit"), 5);
    } else {
      if (group === "INDIVIDUAL") {
        if (
          selectedInvidualList.length > 0 &&
          mailMessage.length > 0 &&
          amount > 0
        ) {
          const emailList: any[] = [];
          selectedInvidualList.forEach((x) => {
            emailList.push(x.email);
          });

          payload = {
            emails: emailList,
            amount: amount,
            filename: "INDIVIDUAL",
            message: mailMessage,
          };
        } else {
          message.error(t("token:message.error.validation"), 5);
        }
      } else if (group === "EXISTING" || group === "NEW") {
        if (
          emailsList &&
          emailsList.length > 0 &&
          mailMessage.length > 0 &&
          amount > 0
        ) {
          payload = {
            emails: emailsList,
            amount: amount,
            filename: selectedFile,
            message: mailMessage,
          };
        } else {
          message.error(t("token:message.error.validation"), 5);
        }
      }

      if (payload) {
        const response = await createTokens(payload);
        if (response?.result === "success") {
          setAvailbleCredits(response?.newCreditBalance);
          message.success(t("token:message.success.tokenGenerated"), 5);
          resetFormData();
        }
      }
    }
  };

  const resetFormData = () => {
    setEmailsList([]);
    setMailMessage("");
    setSelectedFile(null);
    setUserCount(0);
    setAmount(null);
    setCustomAmount(null);
    setTotalAmount(0);
    setDisplayGroupName(null);
    setSelectedInvidualList([]);
  };

  const calculateTotalAmount = (totalUser, amount) => {
    const totalAmt = Number(totalUser) * Number(amount);
    setTotalAmount(totalAmt);
  };

  const readUploadedExcelFile = async (filePath, filename) => {
    const payload = {
      type: "EXCEL",
      path: filePath,
    };
    setSelectedFile(filename);
    const response = await readUploadExcelFile(payload);
    if (response) {
      setEmailsList(response.emails);
      setUserCount(response.count);
      calculateTotalAmount(response.count, amount);
    }
  };

  const handleDocumentUpload =
    (name) =>
    ({ blob, base64, file }) => {
      docBlob.current = blob;
      docFile.current = file;
      setUploadedFileName(docFile?.current?.name);
    };

  const handleUploadDocument = async () => {
    if (docFile?.current && docBlob?.current) {
      setIsDocumentsLoading(true);
      setDisplayGroupName(groupName);
      const payload = new FormData();

      const fileName = docFile?.current?.name;
      payload.append("doc", docBlob?.current, fileName);
      const queryString = {
        where: {
          groupName: groupName,
        },
      };

      uploadPartnerDocument(queryString, payload)
        .then(async (resp) => {
          const fileResponse = await retrieveExcelFiles();
          const fileRespWithKey = fileResponse.map((e, i) => ({
            ...e,
            key: i,
          }));
          setExcelFileList(fileRespWithKey);
          await readUploadedExcelFile(
            resp?.partnerDoc?.url,
            resp?.partnerDoc?.filename
          );
          // setSuccessModalVisible(true);
          message.success(t`token:message.success.fileUploaded`, 5);
          setShowModal(false);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.log("e :>> ", e);
          message.error(t`token:message.error.uploadFile`, 5);
        })
        .finally(() => {
          setIsDocumentsLoading(false);
          setUploadedFileName(null);
          setGroupName(null);
        });
    }
  };

  const cancelUploadDoc = () => {
    setShowModal(false);
    setUploadedFileName(null);
    setGroupName(null);
  };

  const deleteSelectedFile = () => {
    setEmailsList([]);
    setMailMessage("");
    setSelectedFile(null);
    setUserCount(0);
    setTotalAmount(0);
    setDisplayGroupName(null);
    setSelectedInvidualList([]);
  };

  return (
    <Card className="dashboard-my-notifications">
      <section className="dashboard-my-notifications-header">
        <h3>{t`token:title`}</h3>
        {props && props.showCalendar && (
          <div className="dashboard-notification-calendar-container">
            <div className="calendar-vertical-bar vertical-bar-left"></div>
            <div className="calendar-vertical-bar vertical-bar-right"></div>
            <div className="dashboard-notification-calendar-date">
              <label className="calendar-date">
                {format(new Date(), "dd")}
              </label>
              <label className="calendar-month">
                {format(new Date(), "MMM")}
              </label>
            </div>
          </div>
        )}
      </section>
      <section className="create-token-section">
        <div className="dashboard-token">
          <TokenIcon className={"dashboard-token-icon"} />
          <DecorationLine />
        </div>
        <div>
          <label className="text-label-header-style">
            {/* {t("token:labels.amount")} */}
            {`${"Soldes Restant"} ${Number(availableCredits).toFixed(0)}€`}
          </label>
        </div>
        <div className="form-space">
          <label className="text-label-header-style">
            {t("token:labels.amount")}
          </label>
          <Select
            style={{ width: "100%" }}
            value={amount}
            onChange={handleAmountChange}
            placeholder={t("token:amountPlaceholder")}
          >
            {AMOUNTS?.map((item) => (
              <Option key={item.key} value={item.key}>
                {item.label}
              </Option>
            ))}
            <Option value="other">{t`token:amountOther`}</Option>
          </Select>
        </div>

        <div className="form-space">
          <label className="text-label-header-style">
            {t("token:labels.forWhom")}
          </label>

          {/* <div className={"d-flex flex-row justify-content-around"}>
          <TextButton
            rounded={true}
            variant="darkBlue"
            style={{ width: 'unset', height: 'auto' }}
            onClick={() => setTokenType('mail')}
          >
            {"Par adresses e-mails"}
          </TextButton>
          <TextButton
          rounded={true}
          style={{ width: 'unset', height: 'auto' }}
          variant="darkBlue"
          onClick={() => setTokenType('link')}
        >
          {"Lien"}
        </TextButton> 
        </div> */}

          <div className="checkbox-group text-label-med-style">
            <Radio.Group
              name="groups"
              options={GROUPS}
              value={group}
              onChange={handleLevelChange}
            ></Radio.Group>
          </div>
        </div>

        {/* {tokenType === 'link' &&
          <div>
            <p>Nombre de bénéficiaires max par ordre d'arrivée</p>
            <input />
            <br />
            <p>URL d'arrivée</p>
            <input />
          </div>} */}

        {/* {radioValue === 1 &&
          <TextButton
            rounded={true}
            variant="darkBlue"
            style={{ width: 'unset', height: 'auto' }}
            onClick={() => setShowModal(true)}
          >
            {"Importer une base de données"}
          </TextButton>
        } */}

        {group === "INDIVIDUAL" && (
          <div className="form-space">
            {selectedInvidualList && selectedInvidualList.length > 0 && (
              <div className="scroll-table-view">
                <Table
                  showHeader={false}
                  columns={columns}
                  dataSource={selectedInvidualList}
                  pagination={false}
                />
              </div>
            )}
            <div className="horizontal-select-bar form-space">
              <Select
                style={{ width: "100%" }}
                placeholder={t`token:selectNeoPlaceHolder`}
                onChange={(value) => handleSelectUserNeo(value)}
                value={selectedInvidual}
                onClick={(e) => e.stopPropagation()}
              >
                {userEmails?.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.email}
                  </Option>
                ))}
              </Select>
              <IconButton
                aria-label="add"
                style={{ width: "40px", height: "40px" }}
                onClick={() => addSelectedIndividuals()}
              >
                <SvgPlusIcon />
              </IconButton>
            </div>
          </div>
        )}
        {group === "EXISTING" && (
          <div>
            <label className="text-label-header-style">{t`token:chooseAGroupLabel`}</label>
            {excelFileList && excelFileList.length > 0 && (
              <div className="scroll-table-view">
                <Table
                  showHeader={false}
                  columns={columnsExcelFiles}
                  dataSource={excelFileList}
                  pagination={false}
                />
              </div>
            )}
          </div>
        )}
        {group === "NEW" && (
          <div>
            <div className="import-button-spacing">
              <TextIconButton
                rounded={true}
                type="outlined"
                htmlType="submit"
                width="22rem"
                variant="turquoise"
                onClick={() => setShowModal(true)}
                className="book-activity-btn"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="34.202"
                  height="34.201"
                  viewBox="0 0 34.202 34.201"
                >
                  <path
                    id="Icon_awesome-plus-circle"
                    data-name="Icon awesome-plus-circle"
                    d="M17.663.563a17.1,17.1,0,1,1-17.1,17.1A17.1,17.1,0,0,1,17.663.563ZM7.734,19.594a.83.83,0,0,0,.827.827h6.344v6.344c0,.455.827.827.827.827h3.861a.83.83,0,0,0,.827-.827V20.421h6.344a.83.83,0,0,0,.827-.827V15.732a.83.83,0,0,0-.827-.827H20.422V8.561a.83.83,0,0,0-.827-.827H15.733a.83.83,0,0,0-.827.827V14.9H8.561a.83.83,0,0,0-.827.827Z"
                    transform="translate(-0.563 -0.563)"
                    fill="#74d2e7"
                  />
                </svg>

                {t("token:btnImportDb")}
              </TextIconButton>
            </div>
            <div className="excel-file-tag">
              {selectedFile && (
                <div className="excel-file-tag">
                  <SvgExcelIcon /> {displayGroupName}
                  <IconButton
                    aria-label="delete"
                    style={{ color: "green" }}
                    onClick={deleteSelectedFile}
                  >
                    <CloseOutlined style={{ fontSize: "12px" }} />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        )}
        <Form layout="vertical" className="riz">
          <FormItem
            label={t`token:messageText`}
            className="text-label-med-style"
            help={mailMessageError}
            validateStatus={mailMessageError && "error"}
            style={{
              width: "100%",
              paddingBottom: 0,
              marginBottom: 0,
            }}
          >
            <TextArea
              name="message"
              value={mailMessage}
              onChange={handleMessageChange}
              autoSize={{ minRows: 3, maxRows: 8 }}
            />
            {mailMessage.length > MAX_CHARS && (
              <label className="text-area-count-label">
                {t("", {
                  count: MAX_CHARS - mailMessage.length,
                })}
              </label>
            )}
          </FormItem>
        </Form>
        <div className="align-total-amount">
          <label>
            {t("token:totalAmount")} : {totalAmount.toFixed(2)}
          </label>
        </div>

        <Spacer space={20} />
        <TextButton
          rounded={true}
          variant="darkBlue"
          width="100%"
          onClick={generateTokens}
          disabled={!amount || userCount === 0 || mailMessageError.length > 0}
        >
          {t`token:btnGenerate`}
        </TextButton>
      </section>

      <Modal
        title={t`token:importModal.title`}
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(!showModal)}
        centered
        maskClosable
        width={"60%"}
        bodyStyle={{
          maxHeight: "calc(100vh - 10rem)",
          height: "auto",
          overflowY: "scroll",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
          padding: "1.5rem 1.5rem 1.5rem",
        }}
        style={{
          width: "80%",
          maxWidth: "100%",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <h3>{t`token:importModal.selectFile`}</h3>
            <div>
              <FileUploader
                acceptedFileExts={[".xlsx", ".xls"]}
                maxFileSize={MAX_FILE_SIZE * 1024 * 1024}
                uploadFile={handleDocumentUpload("document")}
                style={{ width: "100%", backgroundColor: "red" }}
                maxCount={1}
                listType="text"
                showUploadList={false}
              >
                {/* <div>{t`token:importModal.load`}</div> */}
                <Button
                  icon={<FolderIcon />}
                  className="create-token-upload-button"
                >{t`token:importModal.load`}</Button>
              </FileUploader>
              <p style={{ paddingTop: "10px" }}>{uploadedFileName}</p>
            </div>

            <Divider />
            <h3>{t`token:importModal.groupName`}</h3>
            <FormItem
              help={groupNameError}
              validateStatus={groupNameError && "error"}
              style={{
                width: "100%",
                paddingBottom: 0,
                marginBottom: 0,
              }}
            >
              <Input
                placeholder={t`token:importModal.groupName`}
                onChange={handleModalImportGroupNameChange}
                value={groupName}
              />
            </FormItem>
          </Col>
          <Col xs={24} lg={12}>
            <div className="modal-import-text">
              <p className="modal-format-header">{t`token:importModal.format`}</p>
              <p className="modal-format-body">{t`token:importModal.text1`}</p>
              <p className="modal-format-body">{t`token:importModal.text2`}</p>
            </div>

            <div className="modal-button-format">
              <TextButton
                rounded={true}
                variant="darkBlue"
                width="10rem"
                onClick={handleUploadDocument}
                disabled={
                  !groupName ||
                  groupNameError ||
                  !uploadedFileName ||
                  isDocumentsLoading
                }
              >
                {t`token:importModal.importBtn`}
              </TextButton>
              <TextButton
                rounded={true}
                variant="darkBlue"
                type="outlined"
                width="10rem"
                onClick={cancelUploadDoc}
              >
                {t`token:importModal.cancelBtn`}
              </TextButton>
            </div>
          </Col>
        </Row>
      </Modal>
      <Modal
        visible={showAmountModal}
        title={t`token:tokenAmountModal.title`}
        footer={null}
        closable={true}
        onCancel={() => setShowAmountModal(!showAmountModal)}
        className="customise-amount-modal"
      >
        <section>
          <div style={{ paddingBottom: "10px" }}>
            <label>{t`token:tokenAmountModal.label`}</label>
            <Select
              placeholder={t`token:tokenAmountModal.other`}
              suffixIcon={<CaretDownOutlined />}
              disabled
              style={{ width: "100%" }}
            ></Select>
          </div>
          <FormItem
            help={customAmountMessage}
            validateStatus={customAmountMessage && "error"}
            style={{
              width: "100%",
              paddingBottom: 0,
              marginBottom: 0,
            }}
          >
            <Input
              name="customAmount"
              min={5}
              onChange={handleModalAmountChange}
              value={customAmount}
              type="number"
              suffix="€"
            />
          </FormItem>
        </section>
        <div className="amount-modal-footer">
          <TextButton
            rounded={true}
            variant="darkBlue"
            type="outlined"
            width="11rem"
            onClick={handleModalAmountCancel}
          >{t`common:buttons.cancel`}</TextButton>
          <TextButton
            rounded={true}
            variant="darkBlue"
            disabled={!amount || customAmountMessage}
            width="11rem"
            onClick={handleModalAmountSave}
          >{t`common:buttons.save`}</TextButton>
        </div>
      </Modal>
    </Card>
  );
};

export default CreateToken;

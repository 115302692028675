import React, { Fragment, useEffect, useState } from "react";
import MainLayout from "layouts/main";
import { Avatar, Button, Col, message, Row, Select, Table, Card } from "antd";
import { format } from "date-fns";
import { DownloadOutlined, UserOutlined } from "@ant-design/icons";
import { TextField } from "components/text-field";
import { useTranslation } from "react-i18next";
import { exportToCSV, fullImageUrl } from "components/utils/";
import { getAdminActivities, getExportAdminActivities } from "services/admin";
import AdminStyle from "styles/adminStyle";

const Administrators = () => {
  const { t } = useTranslation(["common", "administrator"]);
  const [limit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [admins, setAdmins] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const columns = [
    {
      key: "image",
      title: "",
      dataIndex: "imageUrls",
      render: (imageUrls) => {
        return (
          <Avatar src={fullImageUrl(imageUrls, "sm")} icon={<UserOutlined />} />
        );
      },
    },
    {
      key: "Id",
      title: t`administrator:columns.id`,
      dataIndex: "id",
      align: "center" as const,
    },
    {
      key: "Nom",
      title: t`administrator:columns.name`,
      dataIndex: "lastname",
      sorter: (a, b) => (a.lastname > b.lastname ? 1 : -1),
    },
    {
      key: "Email",
      title: t`administrator:columns.email`,
      dataIndex: "email",
      sorter: (a, b) => (a.email > b.email ? 1 : -1),
    },
    {
      key: "Télephone",
      title: t`administrator:columns.phone`,
      dataIndex: "phoneNumber",
    },
    {
      key: "Activités",
      title: t`administrator:columns.activity`,
      dataIndex: "unitAmount",
      render: (text, record) =>
        t("administrator:description", {
          tokenCount: record?.tokenCount,
          tokenAmount: record?.unitAmount,
          globalAmount: record?.totalAmount,
          partnerName: record?.partnerName,
        }),
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (params = {}) => {
    getAdminActivities(JSON.stringify(params))
      .then((resp) => {
        console.log("getAdminActivities >>> resp :>> ", resp);
        setAdmins(resp.adminList);
        setTotalRecords(resp.totalRecords);
      })
      .catch((e) => {
        console.log("e :>> ", e);
      });
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    const searchParams = {
      where: {
        searchBy: searchTerm,
      },
    };

    fetchData(JSON.stringify(searchParams));
  };

  const handleDownload = () => {
    getExportAdminActivities()
      .then((resp) => {
        if (resp) {
          exportToCSV(resp, "export_historiques_administrateurs");
          message.success(t`administrator:success.export`, 5);
        } else {
          message.error(t`administrator:error.noData`, 5);
        }
      })
      .catch(() => {
        message.error(t`administrator:error.export`, 5);
      });
  };

  return (
    <MainLayout>
      <AdminStyle />
      <Row gutter={[8, 8]} className="administrators-main-container">
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card className="administrators-card">
            <h1>{t`administrator:title`}</h1>
            <Row justify="end" className="administrators-actions-container">
              <div className="search-field-container">
                <p>{t("common:search")}:</p>
                <TextField radius="0.8125rem" onPressEnter={handleSearch} />
              </div>
              <Button
                icon={<DownloadOutlined />}
                size="large"
                onClick={handleDownload}
              >
                {t("administrator:export")}
              </Button>
            </Row>
            <Table
              columns={columns}
              rowKey={(record) => record?.id}
              dataSource={admins}
              locale={{ emptyText: t`common:table.emptyText` }}
              // onChange={onTableChange}
              pagination={{
                pageSize: limit,
                defaultPageSize: limit,
                total: totalRecords,
                showSizeChanger: false,
              }}
            />
          </Card>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default Administrators;

import * as React from "react";

function SvgExcelIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.667"
      height="30.646"
      viewBox="0 0 31.667 30.646"
    >
      <path
        id="Path_1434"
        data-name="Path 1434"
        d="M23.108,20v7.151H18V45.538h5.108v5.108h26.56V20Zm0,12.258h2.707L27.194,34.3l1.379-2.043H31.28l-2.707,4.086,2.707,4.086H28.573l-1.379-2.043-1.379,2.043H23.108l2.707-4.086ZM47.624,48.6H25.151V45.538H36.388V39.409H44.56V37.366H36.388V35.323H44.56V33.28H36.388V31.237H44.56V29.194H36.388V27.151H25.151V22.043H47.624Z"
        transform="translate(-18 -20)"
        fill="#0d8542"
      />
    </svg>
  );
}

export default SvgExcelIcon;

import React from "react";
import { Button } from "antd";
import { DownloadIcon } from "components/icons";
import styled from "styled-components";

export const DownloadIconButton = styled(({ onClick, ...rest }) => {
  return <Button {...rest} onClick={onClick} icon={<DownloadIcon />} />;
})`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3125rem;
  border-color: ${({ theme }) => theme.colors.shades.grey[25]};
`;

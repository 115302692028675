import * as React from "react";

function SvgDownloadIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.803"
      height="16.803"
      viewBox="0 0 16.803 16.803"
    >
      <g
        id="Icon_feather-download"
        data-name="Icon feather-download"
        transform="translate(1 1)"
      >
        <path
          id="Path_1268"
          data-name="Path 1268"
          d="M19.3,22.5v3.29a1.645,1.645,0,0,1-1.645,1.645H6.145A1.645,1.645,0,0,1,4.5,25.79V22.5"
          transform="translate(-4.5 -12.631)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_1269"
          data-name="Path 1269"
          d="M10.5,15l4.112,4.112L18.724,15"
          transform="translate(-7.21 -9.243)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="Path_1270"
          data-name="Path 1270"
          d="M18,14.369V4.5"
          transform="translate(-10.599 -4.5)"
          fill="none"
          stroke="#707070"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}

export default SvgDownloadIcon;

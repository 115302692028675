/* eslint-disable @typescript-eslint/prefer-as-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { Fragment, useEffect, useState } from "react";
import MainLayout from "layouts/main";
import {
  Avatar,
  Button,
  Col,
  Menu,
  message,
  Radio,
  Row,
  Table,
  Card,
  Progress,
} from "antd";
import {
  exportPartnerMembers,
  getPartnerMembers,
  userConnectionByCityKpi,
} from "services/user";
import { format } from "date-fns";
import { DownloadOutlined, UserOutlined } from "@ant-design/icons";
import { TextField } from "components/text-field";
import UserStyle from "styles/userStyles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IconButton, MenuItem } from "@material-ui/core";
import { useTheme } from "styled-components";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { fullImageUrl } from "components/utils/";
import LineChart from "components/users/chart";
import {
  CollapseScreenIcon,
  FranceMap,
  FullScreenIcon,
} from "components/icons";
import { KpiProgress, KpiToggle } from "components";
import { kpiTokenUse } from "services/token";

const UserList = () => {
  const { t } = useTranslation(["common", "users"]);
  const { colors } = useTheme();
  const history = useHistory();
  const [pages, setPages] = useState(1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [filterChoice, setFilterChoice] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentFilter, setCurrentFilter] = useState("week");
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(true);
  const [tokenKpis, setTokenKpis] = useState(null);
  const [tokenKpisXAxis, setTokenKpisXAxis] = useState(null);
  const [tokenKpisYAxis, setTokenKpisYAxis] = useState(null);
  const [tokenKpisExport, setTokenKpisExport] = useState(null);
  const [filterActiveUsers, setFilterActiveUsers] = useState("today");
  const [activeUsersKpis, setActiveUsersKpis] = useState(null);
  const [currentActiveUsersKpis, setCurrentActiveUsersKpis] = useState(null);

  const PROGRESS_BAR_COLORS = [
    "#74D2E7",
    "#FFC764",
    "#64A882",
    "#335C84",
    "#282eff",
    "#707070",
    "#ffc764",
    "#f8b7a2",
    "#64a882",
    "#26a6b8",
    "#335c84",
    "#ff0268",
    "#d6532c",
    "#02dcff",
  ];

  const columns = [
    {
      key: "image",
      title: "",
      dataIndex: "imageUrls",
      render: (imageUrls) => {
        return (
          <Avatar src={fullImageUrl(imageUrls, "sm")} icon={<UserOutlined />} />
        );
      },
    },
    {
      key: "Id",
      title: t`users:columns.id`,
      dataIndex: "id",
      align: "center" as "center",
    },
    {
      key: "Nom",
      title: t`users:columns.name`,
      dataIndex: "lastname",
      sorter: (a, b) => (a.lastname > b.lastname ? 1 : -1),
    },
    {
      key: "Email",
      title: t`users:columns.email`,
      dataIndex: "email",
      sorter: (a, b) => (a.email > b.email ? 1 : -1),
    },
    {
      key: "Code Postal",
      title: t`users:columns.postalCode`,
      dataIndex: "postcode",
      align: "center" as "center",
    },
    {
      key: "Télephone",
      title: t`users:columns.phone`,
      dataIndex: "phoneNumber",
    },
    {
      key: "Date d'inscription",
      title: t`users:columns.createdAt`,
      dataIndex: "createdAt",
      render: (date: string) => getFullDate(date),
      sorter: (a, b) => (a.createdAt > b.createdAt ? 1 : -1),
      align: "center" as "center",
    },
    {
      key: "Activités",
      title: t`users:columns.totalActivities`,
      dataIndex: "activitiesCount",
      align: "center" as "center",
    },
    {
      key: "Crédits",
      title: t`users:columns.creditsUsed`,
      dataIndex: "creditsUsed",
      align: "center" as "center",
    },
  ];

  useEffect(() => {
    fetchData();
    retrieveKpiTokenUse();
    rertieveUserConnectionByCityKpis();
  }, []);

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    let searchParams = null;
    searchParams = {
      where: {
        search: searchTerm,
      },
    };
    if (filterChoice) {
      searchParams.where["rolename"] = filterChoice;
    }

    fetchData(JSON.stringify(searchParams));
  };

  const handleDownload = () => {
    exportPartnerMembers()
      .then((resp) => {
        if (resp) {
          exportToCSV(resp, "export_utilisateurs");
          message.success(t`users:success.export`, 5);
        } else {
          message.error(t`users:error.noData`, 5);
        }
      })
      .catch(() => {
        message.error(t`users:error.export`, 5);
      });
  };

  const handleChange = (e) => {
    setCurrentFilter(e.target.value);
    tokenGraphKpi(e.target.value);
  };

  const tokenGraphKpi = (toggleValue) => {
    if (toggleValue === "month") {
      setTokenKpisXAxis(tokenKpis?.month?.arrX);
      setTokenKpisYAxis(tokenKpis?.month?.arrY);
      setTokenKpisExport(tokenKpis?.month?.arr);
    } else if (toggleValue === "year") {
      setTokenKpisXAxis(tokenKpis?.year?.arrX);
      setTokenKpisYAxis(tokenKpis?.year?.arrY);
      setTokenKpisExport(tokenKpis?.year?.arr);
    } else {
      setTokenKpisXAxis(tokenKpis?.week?.arrX);
      setTokenKpisYAxis(tokenKpis?.week?.arrY);
      setTokenKpisExport(tokenKpis?.week?.arr);
    }
  };

  const handleTokenKpiDownload = () => {
    if (tokenKpisExport.length > 0) {
      exportToCSV(tokenKpisExport, "export_kpi_token");
      message.success(t`users:success.export`, 5);
    } else message.error(t`users:error.noData`, 5);
  };

  const handleActiveUsersKpiChange = (e) => {
    setFilterActiveUsers(e.target.value);
    activeUserConnectionKpi(e.target.value);
  };

  const activeUserConnectionKpi = (toggleValue) => {
    if (toggleValue === "month") {
      setCurrentActiveUsersKpis(activeUsersKpis?.month);
    } else if (toggleValue === "year") {
      setCurrentActiveUsersKpis(activeUsersKpis?.year);
    } else if (toggleValue === "week") {
      setCurrentActiveUsersKpis(activeUsersKpis?.week);
    } else {
      setCurrentActiveUsersKpis(activeUsersKpis?.today);
    }
  };

  const handleActiveUsersKpiDownload = () => {
    if (currentActiveUsersKpis.length > 0) {
      exportToCSV(currentActiveUsersKpis, "export_adherents_actif");
      message.success(t`users:success.export`, 5);
    } else message.error(t`users:error.noData`, 5);
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const radioItems = [
    {
      label: t("users:filters.pro"),
      value: "pro",
    },
    {
      label: t("users:filters.neo"),
      value: "neo",
    },
    {
      label: t("users:filters.notFilled"),
      value: "unknown",
    },
  ];

  const onChangeFilter = (event) => {
    setFilterChoice(event.target.value);
    const filterParams = {
      offset: 0,
      limit: limit,
      where: {
        rolename: event.target.value,
      },
    };
    // fetchData(filterParams);
  };

  const showMenu = (
    <Menu className="filter-container">
      <p className="filter-menu-title">{t("users:filters.title")}</p>
      <Radio.Group value={filterChoice} onChange={onChangeFilter}>
        {radioItems.map((item, index) => (
          <MenuItem key={index} className="filter-menu-item">
            <Radio value={item.value}>{item.label}</Radio>
          </MenuItem>
        ))}
      </Radio.Group>
    </Menu>
  );

  const onTableChange = (pagination, b, sort) => {
    if (sort && sort.field && sort.order) {
      sortColumn(sort.field, sort.order, pagination.current - 1);
    } else {
      setOffset(pagination.current - 1);
      let params = null;

      if (searchTerm && searchTerm.length > 0) {
        params = {
          offset: pagination.current - 1,
          limit: limit,
          where: {
            search: searchTerm,
            rolename: filterChoice,
          },
        };
      } else {
        params = {
          offset: pagination.current - 1,
          limit: limit,
          where: {
            rolename: filterChoice,
          },
        };
      }

      fetchData(params);
    }
  };

  const sortColumn = (field, order, currentPage) => {
    setSortField(field);
    setSortOrder(order);

    order = order === "ascend" ? "asc" : "desc";
    let params = null;
    let sortOrder = "";

    if (field === "roleName") {
      sortOrder = `rolename ${order}`;
    } else if (field === "createdAt") {
      sortOrder = `createdat ${order}`;
    } else if (field === "adminUserId") {
      sortOrder = `adminfirstname ${order}`;
    }

    if (searchTerm && searchTerm.length > 0) {
      params = {
        offset: currentPage,
        limit: limit,
        order: sortOrder,
        where: {
          search: searchTerm,
          rolename: filterChoice,
        },
      };
    } else {
      params = {
        offset: currentPage,
        limit: limit,
        order: sortOrder,
        where: {
          rolename: filterChoice,
        },
      };
    }

    fetchData(params);
  };

  const fetchData = (params = "{}") => {
    getPartnerMembers(params)
      .then((resp) => {
        setUsers(resp.users);
        setTotalRecords(resp.totalRecords);
        const pages = Math.ceil(resp.totalRecords / limit);
        setPages(pages);
      })
      .catch((e) => {
        console.log("e :>> ", e);
      });
  };

  const retrieveKpiTokenUse = () => {
    kpiTokenUse()
      .then((response) => {
        setTokenKpis(response);
        if (currentFilter === "week") {
          setTokenKpisXAxis(response.week.arrX);
          setTokenKpisYAxis(response.week.arrY);
          setTokenKpisExport(response?.week?.arr);
        }
      })
      .catch((e) => {
        console.log("e :>> ", e);
      });
  };

  const rertieveUserConnectionByCityKpis = () => {
    userConnectionByCityKpi()
      .then((response) => {
        setActiveUsersKpis(response);
        if (filterActiveUsers === "today") {
          setCurrentActiveUsersKpis(response?.today);
        }
      })
      .catch((e) => {
        console.log("e :>> ", e);
      });
  };

  const renderActiveUsersKpiProgressBar = (ad, index) => {
    const progressColor =
      PROGRESS_BAR_COLORS[index % PROGRESS_BAR_COLORS.length];

    return (
      <Col
        key={`cat-ad-${index}`}
        xs={24}
        sm={24}
        md={24}
        lg={24}
        xl={24}
        xxl={24}
      >
        <KpiProgress
          ad={ad}
          progressColor={progressColor}
          city={ad.city}
          recordCount={ad.personCount}
          textLabel={t`users:stats.person`}
        />
      </Col>
    );
  };

  const renderActiveUsersByCity = () => (
    <Row gutter={[16, 16]}>
      {currentActiveUsersKpis.map(renderActiveUsersKpiProgressBar)}
    </Row>
  );

  return (
    <MainLayout>
      <UserStyle />
      <Row gutter={[8, 8]}>
        <Col xs={24}>
          <Card className="user-card">
            <h1 className="user-title">{t`users:title`}</h1>
            <Row
              justify="end"
              gutter={[16, 8]}
              style={{
                marginBottom: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* <Col>
                <Dropdown overlay={showMenu} placement="bottomLeft" arrow>
                  <a className="filter-dropdown">
                    {t("users:filters.title")}{"   "}<DownOutlined />
                  </a>
                </Dropdown>
              </Col> */}
              <Col className="search-text-container">
                {isFullScreen && (
                  <>
                    <div className="search-field-container">
                      <p className="search-text">{t("users:search")}:</p>
                      <TextField
                        radius="0.8125rem"
                        onPressEnter={handleSearch}
                      />
                    </div>
                    <Button
                      icon={<DownloadOutlined />}
                      size="large"
                      onClick={handleDownload}
                      style={{
                        color: colors.shades.grey[25],
                        border: "1px solid #707070",
                        fontSize: "12pt",
                      }}
                    >
                      {t("users:exportUsers")}
                    </Button>
                  </>
                )}
                <IconButton onClick={() => setIsFullScreen(!isFullScreen)}>
                  {isFullScreen ? <CollapseScreenIcon /> : <FullScreenIcon />}
                </IconButton>
              </Col>
            </Row>
            <Table
              className={
                isFullScreen ? "users-table-full" : "users-table-half-screen"
              }
              columns={columns}
              rowKey={(record) => record?.id}
              dataSource={users}
              locale={{ emptyText: t`common:table.emptyText` }}
              onChange={onTableChange}
              pagination={{
                pageSize: limit,
                defaultPageSize: limit,
                total: totalRecords,
                showSizeChanger: false,
              }}
            />
          </Card>
        </Col>
        {!isFullScreen && (
          <Fragment>
            <Col sm={24} lg={12}>
              <Card className="user-card">
                <Row gutter={[8, 8]}>
                  <Col xs={24}>
                    <div
                      className={
                        "d-flex flex-row justify-content-between align-items-center"
                      }
                    >
                      <Button
                        icon={<DownloadOutlined />}
                        size="large"
                        onClick={handleActiveUsersKpiDownload}
                        style={{
                          color: colors.shades.grey[25],
                          border: "1px solid #707070",
                          fontSize: "12pt",
                        }}
                      >
                        {t`users:stats.export`}
                      </Button>
                      <h4>{t`users:stats.members`}</h4>
                    </div>
                  </Col>
                  <Col xs={24}>
                    <div>
                      <KpiToggle
                        opts={["today", "week", "month", "year"]}
                        value={filterActiveUsers}
                        handleChange={handleActiveUsersKpiChange}
                        color="turquoise"
                        hasToday={true}
                      />
                    </div>
                  </Col>
                  <Col xs={24}>{renderActiveUsersByCity()}</Col>
                </Row>
              </Card>
            </Col>
            <Col sm={24} lg={12}>
              <Card className="user-card">
                <Row gutter={[8, 8]}>
                  <Col xs={24}>
                    <div
                      className={
                        "d-flex flex-row justify-content-between align-items-center"
                      }
                    >
                      <h4>{t`users:stats.evolution`}</h4>
                      <Button
                        icon={<DownloadOutlined />}
                        size="small"
                        onClick={handleTokenKpiDownload}
                        style={{
                          color: colors.shades.grey[25],
                          border: "1px solid #707070",
                          fontSize: "12pt",
                        }}
                      ></Button>
                      <div>
                        <KpiToggle
                          opts={["week", "month", "year"]}
                          value={currentFilter}
                          handleChange={handleChange}
                          color="turquoise"
                          hasToday={true}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={24}>
                    <LineChart
                      xSeries={tokenKpisXAxis}
                      ySeries={tokenKpisYAxis}
                      zSeries={null}
                      seriesOneLabel={""}
                      seriesTwoLabel={""}
                      title={t`users:stats.tokenGraphTitle`}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Fragment>
        )}
      </Row>
    </MainLayout>
  );
};

export default UserList;

function getFullDate(date: string) {
  // const dateAndTime = date.split("T");
  const dateAndTime = format(new Date(date), "dd/MM/yyyy HH:mm");
  const datePart = dateAndTime.split(" ");
  const timePart = datePart[1].replace(":", "h");
  const dateTimeFormat = datePart[0] + " à " + timePart;
  // return dateAndTime[0].split("-").reverse().join("-");
  return dateTimeFormat;
}

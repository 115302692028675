import { createGlobalStyle } from "styled-components";

const PasswordStyle = createGlobalStyle`
.main-content {
  padding: 0 1rem;
  background: ${({ theme }) => theme.colors.white};

  h2 {
    max-width: 50rem;
    width: 100%;
    margin: 2rem auto;
  }

  h1 {
    max-width: 50rem;
    width: 100%;
    margin: 2rem auto 0 auto;
    font-family: var(--font-glacial);
    font-size: 1.75rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.shades.grey[8]};
    display: block;
  }

  .page-description-subtitle {
    max-width: 50rem;
    width: 100%;
    margin: 0 auto 2rem auto;
    font-family: var(--font-gilroy);
    font-size: 1.0625rem;
    font-weight: 200;
    line-height: 1.2352941176470589em;
    color: ${({ theme }) => theme.colors.shades.grey[17]};
  }

  .ant-card {
    max-width: 50rem;
    width: 100%;
    margin: auto;
    padding: 2rem;
    /* background: ${({ theme }) => theme.colors.shades.grey[69]}; */
    box-shadow: var(--box-shadow);

    .forgot-password-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .ant-form {
        width: 100%;
      }

      .content-paragraph {
        text-align: center;
        color: ${({ theme }) => theme.colors.shades.grey[17]};
        font-size: 1.0625rem;
        font-family: var(--font-gilroy);
        font-weight: 200;
      }
    }
  }
}
`;

export default PasswordStyle;

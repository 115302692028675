import * as React from "react";

function SvgCollapseScreenIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.802"
      height="17.802"
      viewBox="0 0 17.802 17.802"
    >
      <g
        id="noun_Full_Screen_3889789"
        data-name="noun_Full Screen_3889789"
        transform="translate(-7.496 -7.695)"
      >
        <g id="Group_1778" data-name="Group 1778" transform="translate(7 7)">
          <g id="noun_Resize_1413720" transform="translate(-1.504 -1.305)">
            <g
              id="Group_1787"
              data-name="Group 1787"
              transform="translate(2 2)"
            >
              <g
                id="Group_1779"
                data-name="Group 1779"
                transform="translate(10.013 10.013)"
              >
                <path
                  id="Path_766"
                  data-name="Path 766"
                  d="M29.371,33.08A.371.371,0,0,1,29,32.709V29h3.709a.371.371,0,0,1,0,.742H29.742v2.967A.371.371,0,0,1,29.371,33.08Z"
                  transform="translate(-29 -29)"
                  fill="#bfbfbf"
                />
              </g>
              <g
                id="Group_1780"
                data-name="Group 1780"
                transform="translate(3.709 10.013)"
              >
                <path
                  id="Path_767"
                  data-name="Path 767"
                  d="M15.709,33.08a.371.371,0,0,1-.371-.371V29.742H12.371a.371.371,0,1,1,0-.742H16.08v3.709A.371.371,0,0,1,15.709,33.08Z"
                  transform="translate(-12 -29)"
                  fill="#bfbfbf"
                />
              </g>
              <g
                id="Group_1781"
                data-name="Group 1781"
                transform="translate(3.709 3.709)"
              >
                <path
                  id="Path_768"
                  data-name="Path 768"
                  d="M16.08,16.08H12.371a.371.371,0,0,1,0-.742h2.967V12.371a.371.371,0,0,1,.742,0Z"
                  transform="translate(-12 -12)"
                  fill="#bfbfbf"
                />
              </g>
              <g
                id="Group_1782"
                data-name="Group 1782"
                transform="translate(10.013 3.709)"
              >
                <path
                  id="Path_769"
                  data-name="Path 769"
                  d="M32.709,16.08H29V12.371a.371.371,0,0,1,.742,0v2.967h2.967a.371.371,0,0,1,0,.742Z"
                  transform="translate(-29 -12)"
                  fill="#bfbfbf"
                />
              </g>
              <g
                id="Group_1783"
                data-name="Group 1783"
                transform="translate(0 10.013)"
              >
                <path
                  id="Path_770"
                  data-name="Path 770"
                  d="M2.371,36.788a.371.371,0,0,1-.262-.633l7.047-7.047a.371.371,0,0,1,.524.524L2.633,36.68A.37.37,0,0,1,2.371,36.788Z"
                  transform="translate(-2 -29)"
                  fill="#bfbfbf"
                />
              </g>
              <g
                id="Group_1784"
                data-name="Group 1784"
                transform="translate(10.013)"
              >
                <path
                  id="Path_771"
                  data-name="Path 771"
                  d="M29.371,9.788a.371.371,0,0,1-.262-.633l7.047-7.047a.371.371,0,1,1,.524.524L29.633,9.68A.37.37,0,0,1,29.371,9.788Z"
                  transform="translate(-29 -2)"
                  fill="#bfbfbf"
                />
              </g>
              <g
                id="Group_1785"
                data-name="Group 1785"
                transform="translate(10.013 10.013)"
              >
                <path
                  id="Path_772"
                  data-name="Path 772"
                  d="M36.417,36.788a.37.37,0,0,1-.262-.109l-7.047-7.047a.371.371,0,1,1,.524-.524l7.047,7.047a.371.371,0,0,1-.262.633Z"
                  transform="translate(-29 -29)"
                  fill="#bfbfbf"
                />
              </g>
              <g
                id="Group_1786"
                data-name="Group 1786"
                transform="translate(0 0)"
              >
                <path
                  id="Path_773"
                  data-name="Path 773"
                  d="M9.417,9.788a.37.37,0,0,1-.262-.109L2.109,2.633a.371.371,0,0,1,.524-.524L9.68,9.155a.371.371,0,0,1-.262.633Z"
                  transform="translate(-2 -2)"
                  fill="#bfbfbf"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgCollapseScreenIcon;

import { get } from "./axios-service";

export const newUserKpi = () => {
  return get(`partners/kpi/new-user-signup`);
};

export const newTransactionKpi = () => {
  return get(`partners/kpi/sales-transaction`);
};

export const userTokenUsedKpi = () => {
  return get(`partners/kpi/user-token-used`);
};

export const getNumberOfOrders = () => {
  return get(`partners/kpi/sales-transaction-all`);
};

export const getNumberOfUsers = () => {
  return get(`partners/kpi/user-connections`);
};

export const getTokensUsed = () => {
  return get(`partners/kpi/user-token-used-all`);
};

export const getNumberOfSubscriptions = () => {
  return get(`partners/kpi/new-user-signup-all`);
};

export const getGenderBreakdown = () => {
  return get(`partners/kpi/user-gender-breakdown`);
};

export const getCityDistribution = () => {
  return get(`partners/kpi/user-connection-by-city`);
};

export const getActivityCategories = () => {
  return get(`partners/kpi/activity-by-category`);
};

export const getEvolutionKpi = () => {
  return get(`partners/kpi/sales-evolution`);
};

import { Progress } from "antd";
import styled from "styled-components";

export const KpiProgress = styled(
  ({ ad, progressColor, city, recordCount, textLabel, ...rest }) => {
    const config = {
      height: 16,
      percent: +ad.percent,
      strokeColor: progressColor,
      showInfo: false,
      trailColor: "#fff",
    };

    return (
      <div {...rest}>
        <div className="user-kpi-text-align">
          <div className="user-kpi-city-label">{city}</div>
          <div className="user-kpi-count-label">{`${recordCount} ${textLabel}`}</div>
        </div>
        <Progress {...config} />
      </div>
    );
  }
)`
  .user-kpi-text-align {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .user-kpi-city-label {
    font-family: var(--font-glacial);
    font-size: 15pt;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.shades.grey[27]};
  }

  .user-kpi-count-label {
    font-family: var(--font-glacial);
    font-size: 11pt;
    font-weight: 100;
    color: ${({ theme }) => theme.colors.shades.grey[25]};
  }

  .ant-progress-inner {
    border: ${({ progressColor }) => `1px solid ${progressColor}`};
  }
`;

import React from "react";

function SvgWoman(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
    >
      <g
        id="Group_2153"
        data-name="Group 2153"
        transform="translate(-7443.341 2213.517)"
      >
        <path
          id="Path_1231"
          data-name="Path 1231"
          d="M12.915,49.482a.575.575,0,0,0,.386.146H52.858a.575.575,0,0,0,.386-.146.474.474,0,0,0,.159-.351,23.58,23.58,0,0,0-.353-4.088,9.343,9.343,0,0,0-.773-2.063,9.132,9.132,0,0,0,.52-3.026,9.629,9.629,0,0,0-3.672-7.45,2.489,2.489,0,0,1-.784-2.86,7.271,7.271,0,0,0,.45-2.521,7.8,7.8,0,0,0-3.7-6.516C44.916,14.735,39.585,10,33.08,10S21.247,14.733,21.064,20.6a7.8,7.8,0,0,0-3.7,6.52,7.269,7.269,0,0,0,.45,2.521,2.488,2.488,0,0,1-.785,2.86,9.631,9.631,0,0,0-3.671,7.45,9.133,9.133,0,0,0,.52,3.026,9.334,9.334,0,0,0-.773,2.062,23.585,23.585,0,0,0-.353,4.089A.474.474,0,0,0,12.915,49.482Zm1.537-9.528a8.676,8.676,0,0,1,3.308-6.712,3.449,3.449,0,0,0,1.092-3.912,6.376,6.376,0,0,1-.394-2.207,6.871,6.871,0,0,1,3.449-5.846.486.486,0,0,0,.242-.41c.028-5.446,4.931-9.877,10.929-9.877s10.9,4.433,10.929,9.881a.485.485,0,0,0,.243.41A6.865,6.865,0,0,1,47.7,27.123a6.378,6.378,0,0,1-.394,2.207A3.449,3.449,0,0,0,48.4,33.242a8.676,8.676,0,0,1,3.308,6.712,8.238,8.238,0,0,1-.209,1.812,6.627,6.627,0,0,0-4.048-2.428L39.474,37.7a.851.851,0,0,1-.715-.813V34.54a10.964,10.964,0,0,0,5.37-9.409.44.44,0,0,0-.012-.1c-.031-.135-.815-3.316-5.133-4-4.7-.742-5.348-2.879-5.371-2.964a.535.535,0,0,0-.527-.383.528.528,0,0,0-.537.378c-.025.091-.669,2.228-5.373,2.97-4.318.681-5.1,3.862-5.133,4a.44.44,0,0,0-.012.1,10.96,10.96,0,0,0,5.254,9.339v2.42a.851.851,0,0,1-.712.812l-7.858,1.636a6.636,6.636,0,0,0-4.053,2.43A8.228,8.228,0,0,1,14.453,39.954Zm8.67-14.765c.1-.366.888-2.652,4.24-3.181,3.446-.543,5.018-1.829,5.717-2.738.7.909,2.271,2.194,5.717,2.738,3.356.529,4.137,2.821,4.24,3.18a9.957,9.957,0,0,1-19.914,0Zm-8.95,20.07A6.23,6.23,0,0,1,18.952,40.3l7.865-1.637a1.862,1.862,0,0,0,1.56-1.778V35.058a11.083,11.083,0,0,0,9.29.052v1.776a1.862,1.862,0,0,0,1.566,1.779l7.981,1.64a6.227,6.227,0,0,1,4.772,4.953,20.961,20.961,0,0,1,.318,3.378H13.855A20.968,20.968,0,0,1,14.173,45.258Z"
          transform="translate(7428.585 -2220.517)"
          fill="#013466"
          stroke="#fff"
          strokeWidth="16"
        />
        <path
          id="Path_1234"
          data-name="Path 1234"
          d="M131.967,59.294a6.329,6.329,0,0,0,1.382,3.356,7.7,7.7,0,0,0,3.443,1.756,10.861,10.861,0,0,0,3.22-1.756,5.643,5.643,0,0,0,1.333-3.356"
          transform="translate(7324.958 -2242.917)"
          fill="none"
          stroke="#fff"
          strokeWidth="16"
        />
        <path
          id="Path_528"
          data-name="Path 528"
          d="M493.383,302.464c0-.269-.011-.538-.006-.807a.2.2,0,0,1,.225-.215c.265-.005.53,0,.794,0,.146,0,.206.089.25.216a2.124,2.124,0,0,0,1.318,1.394c.389.138.367.1.365.528,0,.223,0,.445-.006.668a.584.584,0,0,1-.4.085c-.133,0-.267-.006-.4,0a.455.455,0,0,1-.39-.112l-.062-.162a.25.25,0,0,0-.025-.148,2.418,2.418,0,0,0-1.462-1.276A.276.276,0,0,1,493.383,302.464Z"
          transform="translate(6975.574 -2477.507)"
          fill="#013466"
          stroke="#fff"
          strokeWidth="16"
        />
        <path
          id="Path_529"
          data-name="Path 529"
          d="M491.028,405.563a2.061,2.061,0,0,1-.01-.607.118.118,0,0,1,.173-.105,1.7,1.7,0,0,1,1.009.943.255.255,0,0,1,.024.163l-.02.028h0a1.851,1.851,0,0,1-.716,0A.654.654,0,0,1,491.028,405.563Z"
          transform="translate(6977.911 -2579.187)"
          fill="#013466"
          stroke="#fff"
          strokeWidth="16"
        />
        <path
          id="Path_530"
          data-name="Path 530"
          d="M607.573,302.448c0,.084,0,.167,0,.251a.131.131,0,0,1-.176.135,1.373,1.373,0,0,1-.93-1.02c-.012-.111.024-.171.141-.17s.223,0,.335,0a.6.6,0,0,1,.625.636c0,.056,0,.112,0,.167Z"
          transform="translate(6864.367 -2477.708)"
          fill="#013466"
          stroke="#fff"
          strokeWidth="16"
        />
        <path
          id="Path_531"
          data-name="Path 531"
          d="M493.539,363.053a.276.276,0,0,0,.2.17A2.419,2.419,0,0,1,495.2,364.5a.251.251,0,0,1,.025.148,2.5,2.5,0,0,0-1.522-1.325A.22.22,0,0,1,493.539,363.053Z"
          transform="translate(6975.418 -2538.096)"
          fill="#013466"
          stroke="#fff"
          strokeWidth="16"
        />
        <path
          id="Path_532"
          data-name="Path 532"
          d="M492.674,448.385a.653.653,0,0,0,.461.423,1.849,1.849,0,0,0,.716,0c0,.062,0,.138-.078.136a1.658,1.658,0,0,1-.753-.059A.562.562,0,0,1,492.674,448.385Z"
          transform="translate(6976.265 -2622.009)"
          fill="#013466"
          stroke="#fff"
          strokeWidth="16"
        />
        <path
          id="Path_533"
          data-name="Path 533"
          d="M608.647,320.719a1.119,1.119,0,0,0,.388.527,1.852,1.852,0,0,0,.658.283c-.023.058-.051.128-.126.094a1.659,1.659,0,0,1-.667-.354A.834.834,0,0,1,608.647,320.719Z"
          transform="translate(6862.221 -2496.466)"
          fill="#013466"
          stroke="#fff"
          strokeWidth="16"
        />
        <path
          id="Path_534"
          data-name="Path 534"
          d="M598.77,468.606a.454.454,0,0,0,.391.112c.133-.006.267,0,.4,0a.584.584,0,0,0,.4-.085.2.2,0,0,1-.217.2q-.374.007-.749,0C598.857,468.829,598.789,468.74,598.77,468.606Z"
          transform="translate(6871.933 -2641.893)"
          fill="#013466"
          stroke="#fff"
          strokeWidth="16"
        />
        <path
          id="Path_535"
          data-name="Path 535"
          d="M563.375,472.067l.02-.028C563.4,472.054,563.4,472.068,563.375,472.067Z"
          transform="translate(6906.739 -2645.269)"
          fill="#013466"
          stroke="#fff"
          strokeWidth="16"
        />
        <path
          id="Path_1231-2"
          data-name="Path 1231"
          d="M12.9,49.482a.5.5,0,0,0,.351.146H49.158a.5.5,0,0,0,.5-.5,25.917,25.917,0,0,0-.32-4.088,9.961,9.961,0,0,0-.7-2.063A9.976,9.976,0,0,0,45.771,32.5a2.65,2.65,0,0,1-.712-2.86,8,8,0,0,0-2.947-9.037,10.912,10.912,0,0,0-21.814,0,8,8,0,0,0-2.947,9.041,2.649,2.649,0,0,1-.713,2.86,9.979,9.979,0,0,0-2.86,10.476,9.951,9.951,0,0,0-.7,2.062,25.922,25.922,0,0,0-.32,4.089A.5.5,0,0,0,12.9,49.482Zm1.4-9.528a9.02,9.02,0,0,1,3-6.712,3.672,3.672,0,0,0,.991-3.912,7.015,7.015,0,0,1,2.774-8.053.5.5,0,0,0,.22-.41,9.921,9.921,0,0,1,19.842,0,.5.5,0,0,0,.221.41,7.009,7.009,0,0,1,2.773,8.048,3.672,3.672,0,0,0,.991,3.912,8.953,8.953,0,0,1,2.813,8.524,5.96,5.96,0,0,0-3.675-2.428L37.008,37.7a.83.83,0,0,1-.649-.813V34.54a11.22,11.22,0,0,0,4.874-9.409.482.482,0,0,0-.011-.1,5.5,5.5,0,0,0-4.659-4c-4.27-.742-4.854-2.879-4.876-2.964a.493.493,0,0,0-.478-.383.486.486,0,0,0-.487.378c-.023.091-.607,2.228-4.877,2.97a5.5,5.5,0,0,0-4.659,4,.482.482,0,0,0-.011.1,11.233,11.233,0,0,0,4.769,9.339v2.42a.829.829,0,0,1-.647.812l-7.133,1.636a5.968,5.968,0,0,0-3.679,2.43A9.027,9.027,0,0,1,14.3,39.954Zm7.87-14.765a4.555,4.555,0,0,1,3.849-3.181c3.128-.543,4.555-1.829,5.189-2.738.635.909,2.062,2.194,5.19,2.738a4.554,4.554,0,0,1,3.849,3.18c-.028,5.479-4.072,9.926-9.038,9.926S22.194,30.667,22.166,25.189Zm-8.124,20.07c.353-1.576,1.384-4.315,4.338-4.954l7.139-1.637a1.815,1.815,0,0,0,1.416-1.778V35.058a9.222,9.222,0,0,0,8.433.052v1.776a1.814,1.814,0,0,0,1.421,1.779l7.245,1.64c2.948.637,3.979,3.376,4.332,4.953a23.028,23.028,0,0,1,.289,3.378h-34.9A23.036,23.036,0,0,1,14.043,45.258Z"
          transform="translate(7430.46 -2222.37)"
          fill="#013466"
        />
        <path
          id="Path_1234-2"
          data-name="Path 1234"
          d="M131.967,59.294a6.329,6.329,0,0,0,1.382,3.356,7.7,7.7,0,0,0,3.443,1.756,10.861,10.861,0,0,0,3.22-1.756,5.643,5.643,0,0,0,1.333-3.356"
          transform="translate(7324.965 -2244.77)"
          fill="none"
          stroke="#013466"
          strokeWidth="0.5"
        />
        <path
          id="Path_528-2"
          data-name="Path 528"
          d="M493.383,302.464c0-.269-.011-.538-.006-.807a.2.2,0,0,1,.225-.215c.265-.005.53,0,.794,0,.146,0,.206.089.25.216a2.124,2.124,0,0,0,1.318,1.394c.389.138.367.1.365.528,0,.223,0,.445-.006.668a.584.584,0,0,1-.4.085c-.133,0-.267-.006-.4,0a.455.455,0,0,1-.39-.112l-.062-.162a.25.25,0,0,0-.025-.148,2.418,2.418,0,0,0-1.462-1.276A.276.276,0,0,1,493.383,302.464Z"
          transform="translate(6974.701 -2479.36)"
          fill="#013466"
        />
        <path
          id="Path_529-2"
          data-name="Path 529"
          d="M491.028,405.563a2.061,2.061,0,0,1-.01-.607.118.118,0,0,1,.173-.105,1.7,1.7,0,0,1,1.009.943.255.255,0,0,1,.024.163l-.02.028h0a1.851,1.851,0,0,1-.716,0A.654.654,0,0,1,491.028,405.563Z"
          transform="translate(6977.037 -2581.04)"
          fill="#013466"
        />
        <path
          id="Path_530-2"
          data-name="Path 530"
          d="M607.573,302.448c0,.084,0,.167,0,.251a.131.131,0,0,1-.176.135,1.373,1.373,0,0,1-.93-1.02c-.012-.111.024-.171.141-.17s.223,0,.335,0a.6.6,0,0,1,.625.636c0,.056,0,.112,0,.167Z"
          transform="translate(6863.494 -2479.561)"
          fill="#013466"
        />
        <path
          id="Path_531-2"
          data-name="Path 531"
          d="M493.539,363.053a.276.276,0,0,0,.2.17A2.419,2.419,0,0,1,495.2,364.5a.251.251,0,0,1,.025.148,2.5,2.5,0,0,0-1.522-1.325A.22.22,0,0,1,493.539,363.053Z"
          transform="translate(6974.545 -2539.948)"
          fill="#013466"
        />
        <path
          id="Path_532-2"
          data-name="Path 532"
          d="M492.674,448.385a.653.653,0,0,0,.461.423,1.849,1.849,0,0,0,.716,0c0,.062,0,.138-.078.136a1.658,1.658,0,0,1-.753-.059A.562.562,0,0,1,492.674,448.385Z"
          transform="translate(6975.391 -2623.861)"
          fill="#013466"
        />
        <path
          id="Path_533-2"
          data-name="Path 533"
          d="M608.647,320.719a1.119,1.119,0,0,0,.388.527,1.852,1.852,0,0,0,.658.283c-.023.058-.051.128-.126.094a1.659,1.659,0,0,1-.667-.354A.834.834,0,0,1,608.647,320.719Z"
          transform="translate(6861.347 -2498.319)"
          fill="#013466"
        />
        <path
          id="Path_534-2"
          data-name="Path 534"
          d="M598.77,468.606a.454.454,0,0,0,.391.112c.133-.006.267,0,.4,0a.584.584,0,0,0,.4-.085.2.2,0,0,1-.217.2q-.374.007-.749,0C598.857,468.829,598.789,468.74,598.77,468.606Z"
          transform="translate(6871.061 -2643.746)"
          fill="#013466"
        />
        <path
          id="Path_535-2"
          data-name="Path 535"
          d="M563.375,472.067l.02-.028C563.4,472.054,563.4,472.068,563.375,472.067Z"
          transform="translate(6905.866 -2647.122)"
          fill="#013466"
        />
        <rect
          id="Rectangle_1489"
          data-name="Rectangle 1489"
          width="0.991"
          height="5.944"
          rx="0.495"
          transform="translate(7449.777 -2179.133)"
          fill="#013466"
        />
        <rect
          id="Rectangle_1490"
          data-name="Rectangle 1490"
          width="0.991"
          height="5.944"
          rx="0.495"
          transform="translate(7473.059 -2179.133)"
          fill="#013466"
        />
      </g>
    </svg>
  );
}

export default SvgWoman;

import * as React from "react";

function SvgDecorationLine(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="280.628"
      height="9"
      viewBox="0 0 280.628 9"
    >
      <g
        id="Group_2274"
        data-name="Group 2274"
        transform="translate(-1225 -256)"
      >
        <g
          id="Ellipse_453"
          data-name="Ellipse 453"
          transform="translate(1361 256)"
          fill="#fff"
          stroke="#003466"
          strokeWidth="1"
        >
          <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
          <circle cx="4.5" cy="4.5" r="4" fill="none" />
        </g>
        <line
          id="Line_331"
          data-name="Line 331"
          x2="136"
          transform="translate(1369.628 260.5)"
          fill="none"
          stroke="#003466"
          strokeWidth="1"
        />
        <line
          id="Line_332"
          data-name="Line 332"
          x2="136"
          transform="translate(1225 260.5)"
          fill="none"
          stroke="#003466"
          strokeWidth="1"
        />
      </g>
    </svg>
  );
}

export default SvgDecorationLine;

import React, { useRef } from "react";
import styled from "styled-components";
import { message, Upload } from "antd";
import { useTranslation } from "react-i18next";
import ImgCrop from "antd-img-crop";

export const FileUploader = styled(
  ({
    acceptMultipleFiles = false,
    acceptedFileExts = [".png", ".jpg", ".jpeg"],
    cropProps = {
      rotate: true,
    },
    maxFileSize = 2 * 1024 * 1024,
    onFileBrowsed,
    uploadFile,
    includeCropper = false,
    uploadRef = useRef(),
    children,
    ...rest
  }) => {
    const { t, i18n } = useTranslation(["common"]);
    const accept = acceptedFileExts.join(",");

    const handleBeforeUpload = (fileObj) => {
      const imageIsTooLarge = fileObj.size > maxFileSize;

      if (imageIsTooLarge) {
        message.error(
          t("common:fileTooLarge", {
            size: `${maxFileSize / 1024 / 1024}MB`,
          }),
          10
        );
      }

      return false;
    };

    const handleUploadFile = ({ file }) =>
      new Promise((resolve, reject) => {
        if (file.size <= maxFileSize) {
          let base64;
          const blob = new Blob([file]);
          const fileReader = new FileReader();

          // On file load, read its contents
          fileReader.onload = (fileLoadedEvent) => {
            base64 = fileLoadedEvent.target.result;
          };

          // display browsed image
          fileReader.onloadend = function (e) {
            resolve(
              uploadFile({
                blob,
                base64,
                file,
              })
            );
          };

          // Convert data to base64
          fileReader.readAsDataURL(blob);
        } else {
          resolve(
            uploadFile({
              blob: null,
              base64: null,
              file,
            })
          );
        }
      });

    const renderSimpleUploader = () => (
      <Upload
        accept={accept}
        multiple={acceptMultipleFiles}
        beforeUpload={onFileBrowsed || handleBeforeUpload}
        onChange={handleUploadFile}
        {...rest}
      >
        <button ref={uploadRef} className="browse-btn" />
        {children}
      </Upload>
    );

    const renderCropUploader = () => (
      <ImgCrop {...cropProps}>{renderSimpleUploader()}</ImgCrop>
    );

    return includeCropper ? renderCropUploader() : renderSimpleUploader();
  }
)`
  .browse-btn {
    display: none;
  }
`;

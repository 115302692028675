import { get, patch, post } from "./axios-service";

const apiUrl = process.env.REACT_APP_API_URL;

export async function adminLogin(email: string, password: string) {
  try {
    const response = await post("partner/auth", {
      email: email,
      password: password,
    });
    const users = response;

    localStorage.setItem("authKey", users.token);
    localStorage.setItem("currentUser", users.loggedUser.id);
    localStorage.setItem("userfirstname", users.loggedUser.firstname);
    localStorage.setItem("userlastname", users.loggedUser.name);
    sessionStorage.setItem("token", JSON.stringify(users.token));
    return response;
  } catch (error) {
    throw new Error("INVALID LOGIN");
  }
}

export const getPartnerMembers = (queryString) => {
  return get(`partner-members?filter=${queryString}`);
};

export const exportPartnerMembers = () => {
  return get(`partner-members/export`);
};

export async function getUser(id) {
  const result = await get(`user/${id}`);
  return result;
}

export async function getResponsableNeo() {
  const response = await get(`${apiUrl}admin/admin-users`);
  return response;
}

export async function getUserAttribute(userId) {
  try {
    const response = await get(`user/${userId}/user-attribute`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getRatings(userId) {
  try {
    const response = await get(`admin/ratings/user/${userId}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
}

export const updateUser = async (id, payload) => {
  const response = await patch(`user/${id}`, payload);
  return response;
};

export function getLatestSales(id) {
  return get(`admin/user-sales-list/${id}`);
}

export function getLatestOrders(id) {
  return get(`admin/user-transaction-list/${id}`);
}

export function getLatestAds(id) {
  return get(`admin/user/${id}/activity-list`);
}

export function getUserDocs(id) {
  return get(`user/${id}/user-docs`);
}

export function deleteUser(id) {
  return post(`admin/user/${id}/delete-account`, {});
}

export function generateUserLoginToken(id) {
  return post(`admin/user/${id}/access-token`, {});
}

export const activatePartneraccount = (activationCode) => {
  return post("partner/account-activation", { activationCode });
};

export function userConnectionByCityKpi() {
  return get("partners/kpi/user-connection-by-city");
}

import { createGlobalStyle } from "styled-components";

const SubventionStyle = createGlobalStyle`

.subvention-main-container {
  .main-content {
    padding: 0 0 0 4.5rem;
    background-color: unset;
  }

  .subvention-parameters-container {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 1rem;


    .subvention-parameters-form {
      flex: 1;

      .category-slider-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        width: 100%;

        label {
          font-family: var(--font-glacial);
          font-weight: 400;
          font-size: 0.75rem;
          color: ${({ theme }) => theme.colors.shades.grey[32]};
          flex: 1;
          text-transform: uppercase;
        }

        .ant-slider {
          --color: ${({ theme }) => theme.colors.shades.blue[4]};
          width: 100%;
          flex: 1;

          .ant-slider-track {
            background-color: var(--color);
          }

          .ant-slider-dot-active, .ant-slider-handle {
            border-color: var(--color);
          }
        }
      }
    }

    .ant-form-item {
      width: 50%;

      .ant-form-item-label > label {
        color: ${({ theme }) => theme.colors.darkBlue};
      }
    }

    .category-label {
      color: ${({ theme }) => theme.colors.darkBlue};
      font-weight: 400;
      font-family: var(--font-glacial);

    }

    .beneficiary-file-options {
      color: ${({ theme }) => theme.colors.shades.grey[32]};
      font-family: var(--font-glacial);
      font-weight: 400;
      font-size: 1rem;
      text-decoration: underline;
    }
  }
}

.beneficiary-file-modal {
  .ant-modal-content {
    border-radius: 0.5rem;

    .ant-modal-header {
      border-radius: 0.5rem 0.5rem 0 0;
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex-wrap: wrap;

      .file-upload-error-msg {
        color: ${({ theme }) => theme.colors.red};
        text-align: center;
        font-weight: 600;
        font-family: var(--font-gilroy);
        font-size: 1rem;
      }

      .ant-form-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;

        .ant-form-item-control {
          width: 50%;
        }
      }

      .ant-divider-horizontal {
        margin: 0;
      }

      .upload-file-button {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
        align-items: center;
      }

      .modal-file-upload-information {
        margin: 0.5rem 0 0 0;
        font-family: var(--font-glacial);
        background-color: ${({ theme }) => theme.colors.darkBlue};
        color: ${({ theme }) => theme.colors.white};
        padding: 1rem;
        border-radius: 0.5rem;

        label {
          font-size: 1.1rem;
        }
      }
    }

    .ant-modal-footer {
      .ant-btn {
        border-radius: 0.8rem;
      }

      .ant-btn-primary {
        background-color: ${({ theme }) => theme.colors.darkBlue};

        &[disabled] {
          background-color: ${({ theme }) => theme.colors.shades.grey[69]};
        }
      }
    }
  }
}

.customise-amount-modal {
  .ant-modal-content {
    border-radius: 1rem;

    .ant-modal-header {
      background-color: ${({ theme }) => theme.colors.darkBlue};
      border-radius: 1rem 1rem 0 0;
      
      .ant-modal-title {
        color: white;
        font-family: var(--font-gilroy);
        font-size: 1.75rem;
        font-weight: 400;
        text-align: center;
      }
    }

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      .customise-amount-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        width: 100%;

        div {
          display: flex;
          flex-direction: column;

          label {
            font-family: var(--font-gilroy);
            font-size: 0.8125rem;
            font-weight: 200;
            color: ${({ theme }) => theme.colors.shades.grey[24]};
          }

          .ant-select-single .ant-select-selector::after, .ant-select-single .ant-select-selector .ant-select-selection-item::after, .ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
            content: none;
          }
        }

        .ant-form-item-control-input-content {
          width: 100%;
        }
      }

      .customise-amount-footer {
        gap: 1rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}

.ant-modal-close {
  color: ${({ theme }) => theme.colors.white};
}

.ant-tooltip {
  z-index: 99;
}

.beneficiary-row {
  display: flex;
  flex-direction: row;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

`;

export default SubventionStyle;

import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["Accept"] = "application/json";

const token = localStorage.getItem("authKey");
const errorCodes = [401, 403];

const axiosCustom = axios.create();

// axiosCustom.defaults.headers.common = { 'Authorization': `Bearer ${token}` }

const authHeader = () => {
  return `Bearer ${localStorage.getItem("authKey")}`;
};
axiosCustom.interceptors.request.use(
  (req) => {
    console.log("REQ", req);
    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

axiosCustom.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (errorCodes.includes(err.response.status)) {
      // logOutUser();
      console.log("ERROR !!!!");
    }
    return Promise.reject(err);
  }
);

interface payload {
  [key: string]: unknown;
}

export const get = (path: string): Promise<any> =>
  new Promise((resolve, reject) => {
    axiosCustom
      .get(path, {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => resolve(res?.data))
      .catch((e) => reject(e));
  });

export const post = (
  path: string,
  payload: payload | FormData,
  headers = {}
): Promise<any> =>
  new Promise((resolve, reject) => {
    axios
      .post(path, payload, {
        headers: {
          ...headers,
          Authorization: authHeader(),
        },
      })
      .then((res) => resolve(res?.data))
      .catch((e) => reject(e));
  });

export const postImages = (
  path: string,
  payload: payload | FormData,
  headers = {}
): Promise<any> =>
  new Promise((resolve, reject) => {
    axios
      .post(path, payload, {
        headers: {
          ...headers,
          "Content-Type": "multipart/form-data",
          Authorization: authHeader(),
        },
      })
      .then((res) => resolve(res?.data))
      .catch((e) => reject(e));
  });

export const patch = (path: string, payload: payload): Promise<any> =>
  new Promise((resolve, reject) => {
    axios
      .patch(path, payload, {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => resolve(res?.data))
      .catch((e) => reject(e));
  });

export const put = (path: string, payload: payload): Promise<any> =>
  new Promise((resolve, reject) => {
    axios
      .put(path, payload, {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => resolve(res?.data))
      .catch((e) => reject(e));
  });

export const del = (path: string, payload: payload = {}): Promise<any> =>
  new Promise((resolve, reject) => {
    axios
      .delete(path, {
        headers: {
          Authorization: authHeader(),
        },
      })
      .then((res) => resolve(res?.data))
      .catch((e) => reject(e));
  });
